export interface Job {
  jobType?: 'FULL_TIME' | 'PART_TIME' | 'FLEXIBLE';
  companyName?: string;
  jobOffer?: string;
  location?: string;
  name?: string;
  lat?: any;
  lng?: any;
  position?: string;
  salary?: number;
  promoted?: boolean;
  promotedUntil?: string;
  phoneNumber?: string;
  email?: string;
  address?: any;
  description?: string;
  photos?: any;
  dateAdded?: Date;
  id?: string;
  owner?: any;
  time?: string;
  postCode?: string;
  distance?: number;
  issued?: boolean;
  existedChat?: number;
  issuedPromotedUntil?: string;
  mapPromotedUntil?: string;
  countFavourite?: string;
  visitors?: string;
}

export const JobType = [
  {label: 'Vollzeit', value: 0},
  {label: 'Teilzeit', value: 1},
  {label: 'Gleitzeit', value: 2},
];

export const jobTypeTranslated = (idx: number) => {
  switch (idx) {
    case 0:
      return 'FULL_TIME';
    case 1:
      return 'PART_TIME';
    case 2:
      return 'FLEXIBLE';
      return undefined;
  }
};

export const JobTypeEnumDict = {
  FULL_TIME: 'Vollzeit',
  PART_TIME: 'Teilzeit',
  FLEXIBLE: 'Gleitzeit',
};

export interface FavoriteJob {
  createdAt: string;
  job: Job;
}

export const JobsDefaultDataValues = {
  companyName: '',
  name: '',
  jobOffer: '',
  location: '',
  lat: '',
  lng: '',
  position: '',
  salary: '',
  phoneNumber: '',
  email: '',
  address: '',
  description: '',
  images: undefined,
  id: undefined,
  owner: undefined,
};
