import React, {useEffect, useState} from 'react';
import {authService} from 'api/account/auth.service';
import UnauthorizedRoutes from './UnauthorizedRoutes';
import UserRoutes from './UserRoutes';
import {useHistory} from 'react-router';
import {useResetRecoilState} from 'recoil';
import {accountState} from 'states/Account/accountState';
import {IonPage, IonSplitPane, useIonRouter} from '@ionic/react';
import AppBurgerMenu from 'components/AppBurgerMenu/AppBurgerMenu';
import {
  clearNotificationRedirect,
  getNotificationRedirect,
  notificationRedirect,
} from './oneSignalInit';

const AppRouter: React.FC = () => {
  const [token, setToken] = useState(authService.tokenValue);
  const resetAccountData = useResetRecoilState(accountState);
  const [redirect, setRedirect] = useState<string>();
  const history = useHistory();
  const router = useIonRouter();

  useEffect(() => {
    authService.setAccessTokenFromStorage();
    authService.currentToken.subscribe(setToken);
    notificationRedirect.subscribe(setRedirect);
  }, []);

  useEffect(() => {
    const tryRedirectToItem = async () => {
      const url = await getNotificationRedirect();

      if (url) {
        router.push(url);
        await clearNotificationRedirect();
      }
    };

    tryRedirectToItem();
  }, [redirect]);

  const isLoggedIn = token !== '';

  if (history.location.pathname == '/') {
    history.replace('/home');
  }

  if (!isLoggedIn) {
    resetAccountData();
  }

  return (
    <>
      {isLoggedIn && (
        <IonSplitPane contentId='main'>
          <AppBurgerMenu isUnathorized={false} />
          <IonPage id='main'>
            <UserRoutes />
          </IonPage>
        </IonSplitPane>
      )}
      {!isLoggedIn && (
        <IonSplitPane contentId='main'>
          <AppBurgerMenu isUnathorized={true} />
          <IonPage id='main'>
            <UnauthorizedRoutes />
          </IonPage>
        </IonSplitPane>
      )}
    </>
  );
};

export default AppRouter;
