import React, {useEffect, useState} from 'react';
import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar, useIonRouter} from '@ionic/react';
import {useParams} from 'react-router';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {accountState} from 'states/Account/accountState';
import LoadingIndicator from 'components/UILoading/UILoadingIndicator';
import {Service} from 'interfaces/Services/service.interface';
import {servicesService} from 'api/services/services.service';
import {accountService} from 'api/account/account.service';
import {DetailsOffer} from 'pages/Services/ServiceDetail/ServiceDetails';
import UIButtonBack from 'components/UIButtons/UIButtonBack';
import UITopTextBar from 'components/UILabels/UITopTextBar';
import UIYellowButton from 'components/UIButtons/UIYellowButton';
import UILogo from 'components/UILogo/UILogo';
import ServiceEditForm from 'forms/Services/EditFormService';
import {refreshCounterState} from 'states/Common/CommonState';
import {UIDeleteButton} from 'components/UIButtons/UIDeleteButton';
import UIPopUpPhoto from 'components/UIImages/UIPopUpPhoto';
import {User} from 'interfaces/Users/user.interface';
import {datesTypeState, selectedDatesState} from 'states/Messages/messageState';

export const ServiceDetailPage = () => {
  const [isOwn, setIsOwn] = useState(false);
  const setDates = useSetRecoilState(selectedDatesState);
  const [date, setDate] = useState();
  const [isFavorite, setIsFavorite] = useState(false);
  const [service, setService] = useState<Service>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPopUpVisible, setIsPopUpVisible] = useState<boolean>(false);

  const [refreshCounter, setRefreshCounter] = useRecoilState(refreshCounterState);
  const accountData = useRecoilValue<User | undefined>(accountState);
  const setDatesType = useSetRecoilState(datesTypeState);

  const router = useIonRouter();

  const {offerId: offerId} = useParams<{offerId: string}>();

  useEffect(() => {
    async function getOfferDetails() {
      try {
        setIsLoading(true);
        const response = await servicesService.getDetails(+offerId);
        if (accountData) {
          setIsOwn(accountData.id == response.data.owner.id);
          const userResponse = await accountService.getCurrentUser();
          const isFavourite = userResponse.data.favourites.services.some(
            (dto: any) => dto.service.id == offerId
          );
          setIsFavorite(isFavourite);
        }
        setService(response.data);
        setIsPopUpVisible(false);
      } catch {
        console.log('error');
      } finally {
        setIsLoading(false);
      }
    }
    getOfferDetails();
  }, [refreshCounter]);

  const handleDelete = () => {
    servicesService.deleteService(service!.id).then(() => {
      setRefreshCounter(refreshCounter + 1);
      router.goBack();
    });
  };

  return (
    <IonPage>
      {isPopUpVisible && (
        <UIPopUpPhoto
        photoLink={service?.photos}
          onClosePopUp={() => {
            setIsPopUpVisible(false);
          }}
        />
      )}
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
          {isOwn && (
            <IonButtons slot='end'>
              <UIDeleteButton onDelete={handleDelete} />
            </IonButtons>
          )}
        </IonToolbar>
        <UITopTextBar>Dienstleistungen</UITopTextBar>
      </IonHeader>
      <IonContent>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          service && (
            <div className='w-full h-full bg-white dark:bg-[#474747]'>
              <div className='pt-2 px-2 pb-5 space-y-5 '>
                {!isOwn ? (
                  <DetailsOffer
                    fields={service}
                    defaultFavorite={isFavorite}
                    setDate={setDate}
                    setIsPopUpVisible={setIsPopUpVisible}
                  />
                ) : (
                  <ServiceEditForm offerId={offerId} />
                )}
                <div>
                  {!isOwn && (
                    <UIYellowButton
                      text='Dienstleister kontaktieren'
                      onClick={() => {
                        setDates(date);
                        setDatesType('services');
                        router.push(
                          `/message/services/${service.id}/${
                            service.name ? service.name : service.industry
                          }/${service.existedChat ? service.existedChat : 0}`,
                        );
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          )
        )}
      </IonContent>
    </IonPage>
  );
};
