import React from 'react';

interface Props {
  name: string;
}

export const OrangeLine: React.FC<Props> = ({name}) => {
  return (
    <div className='flex items-center space-x-0.5 text-sm m-'>
      <div className='text-orange-main text-sm ml-2 dark:text-[#F0A249]'>{name}</div>
      <div className='bg-orange-main w-screen h-px dark:bg-[#F0A249]'></div>
    </div>
  );
};
