import React from 'react';

type Props = {
  children: JSX.Element;
};

const AppSignInBackground = ({children}: Props) => {
  return (
    <div>
      <div className='w-full h-full flex justify-center dark:bg-[#474747]'>
        <div className='bg-yellow-accent w-3/4 h-54 rounded-lg drop-shadow-xl dark:bg-[#F0A249]'>
          <div
            className='h-full text-black  bg-white border-2 border-yellow-accent mt-2
          rounded-b-lg'
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppSignInBackground;
