import React from 'react';

interface Props {
  src: string;
  alt: string;
  name: string;
  onClick?: any;
  isEnable?: boolean;
  numberOfItems?: number;
}

const HomeMenuButton: React.FC<Props> = ({
  src,
  alt,
  name,
  onClick,
  isEnable = true,
  numberOfItems,
}) => {
  if (!onClick) isEnable = false;

  return (
    <div
    className={`p-2 ${!isEnable && `opacity-40`} relative`}
    onClick={isEnable ? onClick : undefined}
  >
      <div
        className='border border-orange-main rounded-xl shadow-lg
       dark:border-[#F0A249] dark:shadow-[#F0A249] w-full xl:w-180'
      >
        <div className='flex flex-row'>
          <div className='w-1/2 flex items-center justify-start px-10 xl:text-lg font-medium'>
            {name}
          </div>
          <div className='w-1/2'>
          <div className='flex justify-end w-full border-white rounded-r-xl'>
        <img
          src={src}
          alt={alt}
          style={{padding: 2}}
          className='h-28 w-68 rounded-r-xl opacity-60'
        />
        { (!numberOfItems) ? (<p></p>)
        : (<div className='text-white bg-orange-main py-1 rounded-3xl absolute pl-12 pr-3 mr-8
        mt-16'>{numberOfItems} Angebote
        </div>)}
        </div>
        </div>
        </div>
        </div>
      </div>
  );
};

export default HomeMenuButton;
