import React, {useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination} from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import 'swiper/css/bundle';
import '@ionic/react/css/ionic-swiper.css';
import {instrumentsService} from 'api/instruments/instruments.service';

interface OwnerData {
       email: string;
       firstName: string;
       id: number;
       lastName?: string;
}

interface CommentData {
    dateAdd: string;
    owner: OwnerData;
    text: string;
}

type commentHelperProps = {
    comments?: Array<CommentData> | any;
    id?: any;
}

const UICommentSpace: React.FC<commentHelperProps> = ({comments, id}) => {
    const [text, setText] = useState(String);

    const comment = {
        text: text,
      };

    const addCommentClicked = () => {
        instrumentsService.commentInstrument(comment, id);
        setText('');
    };
  return (
    <div
      className='
       mx-4 py-2 rounded-xl text-sm dark:bg-[#F0A249] dark:border-[#F0A249]'
    >
        <div className='w-full mb-6'>
        <Swiper
              modules={[Navigation, Pagination]}
              slidesPerView={1}
              navigation
              spaceBetween={50}
              className='mySwiper'
            >
                <div className='w-full text-sm mx-2'>
                {comments.map((item:any, index:number) => {
                return (
                    <SwiperSlide key={index}>
                <div className='w-5/6'>
                  <div className='text-sm mx-2 text-gray-400 whitespace-normal'>
                        {item.text}
                </div>
                <div className='flex justify-end pr-8 pt-2 text-xs text-orange-main'>
                    #{item.owner.firstName}
                </div>
                </div>
                    </SwiperSlide>
                );
            }) }
                </div>
            </Swiper>
        </div>
        <div className='flex flex-row space-x-3'>
        <input placeholder='Hinterlasse einen Kommentar...'
        className='w-full border-2 border-gray-300 rounded-lg p-1 pl-2'
        onChange={(e) => setText(e.target.value)}
        value={text}
        >
        </input>
        <button className='text-2xl text-white bg-orange-main rounded-xl py-1 px-3'
         onClick={addCommentClicked}>
            {'>'}
        </button>
        </div>
    </div>
  );
};

export default UICommentSpace;
