import React, {useState} from 'react';
import {IonIcon, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs} from '@ionic/react';
import {map, star, person, chatbubbles, home} from 'ionicons/icons';
import AccountLoginPage from 'pages/Account/AccountLogin/AccountLoginPage';
import AccountRecoveryPage from 'pages/Account/AccountPassword/AccountRecoveryPage';
import AccountRegisterPage from 'pages/Account/AccountRegister/AccountRegisterPage';
import AccountRegister2Page from 'pages/Account/AccountRegister/AccountRegisterPage2';
import AccountRegisterConfirmationPage from
'pages/Account/AccountRegister/AccountRegisterConfirmationPage';
import HomePage from 'pages/Common/Home/HomePage';
import ShopPage from 'pages/Common/Shop/ShopPage';
import MapPage from 'pages/Common/Map/MapPage';
import AccountPleaseSignInPage from 'pages/Account/AccountPleaseSignIn/AccountPleaseSignInPage';
import {Route, useHistory} from 'react-router';
import {InstrumentDetailPage} from 'pages/Instruments/InstrumentDetail/InstrumentDetailPage';
import InstrumentsSubcategoriesMachinesListPage from
'pages/Instruments/InstrumentsSubcategoriesList/InstrumentsSubcategoriesMachinesListPage';
import InstrumentsSubcategoriesToolsListPage from
'pages/Instruments/InstrumentsSubcategoriesList/InstrumentsSubcategoriesToolsListPage copy';
import InstrumentsListPage from 'pages/Instruments/InstrumentsList/InstrumentsListPage';
import SearchPage from 'pages/Common/Search/SearchPage';
import InstrumentAddPage from 'pages/Instruments/InstrumentAdd/InstrumentAddPage';
import ProductsAddPage from 'pages/Products/addProducts/ProductsAddPage';
import {DetailProductsPage} from 'pages/Products/detailProducts/DetailProductsPage';
import ProductsListPage from 'pages/Products/ProductsList/ProductsListPage';
import ReportPage from 'pages/Common/Report/ReportPage';
import SearchMapPage from 'pages/Common/Search/SearchMapPage';
import {ServiceDetailPage} from 'pages/Services/ServiceDetail/ServiceDetailPage';
import JobDetail from 'pages/Jobs/JobDetail/JobDetailPage';
import PromotionsPaymentResultPage from
'pages/Promotions/PromotionPayment/PromotionsPaymentResultPage';
import PromotionPaymentPage from 'pages/Promotions/PromotionPayment/PromotionPaymentPage';

const UnauthorizedRoutes: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>('home');
  const history = useHistory();

  if (history.location.pathname == '/home' && selectedTab !== 'home') {
    setSelectedTab('home');
  }

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route path='/home'>
          <HomePage />
        </Route>
        <Route path='/login' exact={true}>
          <AccountLoginPage />
        </Route>
        <Route path='/register'>
          <AccountRegisterPage />
        </Route>
        <Route path='/registerSecondStage'>
          <AccountRegister2Page />
        </Route>
        <Route path='/registerConfirmation'>
          <AccountRegisterConfirmationPage />
        </Route>
        <Route path='/report/:reportedElement/:reportedElementId'>
          <ReportPage />
        </Route>
        <Route path='/passwordRecovery'>
          <AccountRecoveryPage />
        </Route>
        <Route path='/home'>
          <HomePage />
        </Route>
        <Route path='/loan/instrument/:instrumentId'>
          <InstrumentDetailPage />
        </Route>
        <Route path='/instrument/:catName/:subCatName/:instrumentId'>
          <InstrumentDetailPage />
        </Route>
        <Route path='/instruments/categories/1/:option'>
          <InstrumentsSubcategoriesToolsListPage />
        </Route>
        <Route path='/instruments/categories/2/:option'>
          <InstrumentsSubcategoriesMachinesListPage />
        </Route>
        <Route path='/instruments/subcategories/:subCatId/:catName/:subCatName'>
          <InstrumentsListPage />
        </Route>
        <Route path='/product-main'>
          <ShopPage />
        </Route>
        <Route path='/search/:itemsType/:category'>
          <SearchPage />
        </Route>
        <Route path='/search-map'>
          <SearchMapPage />
        </Route>
        <Route path='/map'>
          <MapPage />
        </Route>
        <Route path='/message/:id/:name'>
          <AccountPleaseSignInPage />
        </Route>
        <Route path='/sign-in'>
          <AccountPleaseSignInPage />
        </Route>
        <Route path='/instruments-add'>
          <InstrumentAddPage />
        </Route>
        <Route path='/products-add'>
          <ProductsAddPage />
        </Route>
        <Route path='/shop'>
          <ShopPage />
        </Route>
        <Route path='/instrument/:catName/:subCatName/:instrumentId'>
          <InstrumentDetailPage />
        </Route>
        <Route path='/products-add'>
          <ProductsAddPage />
        </Route>
        <Route path='/products/subcategories/:subCatId/:catName/:subCatName'>
          <ProductsListPage />
        </Route>
        <Route path='/product/:catName/:subCatName/:instrumentId'>
          <DetailProductsPage />
        </Route>
        <Route path='/service/:offerId'>
          <ServiceDetailPage />
        </Route>
        <Route path='/job/:jobId'>
          <JobDetail />
        </Route>
        <Route path='/payment/:paymentId/:promotionType/:type/:id'>
          <PromotionPaymentPage />
        </Route>
        <Route path='/payment-result/:paymentId/:id/:type/:promotionType/'>
          <PromotionsPaymentResultPage />
        </Route>
      </IonRouterOutlet>
      <IonTabBar
        onIonTabsWillChange={(e) => setSelectedTab(e.detail.tab)}
        className='bg-gray-500 lg:hidden'
        slot='bottom'>
        <IonTabButton className='bg-gray-500 text-white' tab='home' href='/home'>
          <IonIcon color={selectedTab === 'home' ? 'primary' : ''} icon={home} />
        </IonTabButton>
        <IonTabButton className='bg-gray-500 text-white' tab='community' href='/sign-in'>
          <IonIcon color={selectedTab === 'community' ? 'primary' : ''} icon={chatbubbles} />
        </IonTabButton>
        <IonTabButton className='bg-gray-500 text-white' tab='map' href='/map'>
          <IonIcon color={selectedTab === 'map' ? 'primary' : ''} icon={map} />
        </IonTabButton>
        <IonTabButton className='bg-gray-500 text-white' tab='favorites' href='/sign-in'>
          <IonIcon color={selectedTab === 'favorites' ? 'primary' : ''} icon={star} />
        </IonTabButton>
        <IonTabButton className='bg-gray-500 text-white' tab='profile' href='/sign-in'>
          <IonIcon color={selectedTab === 'profile' ? 'primary' : ''} icon={person} />
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default UnauthorizedRoutes;
