import React, {useEffect} from 'react';
import {IonIcon} from '@ionic/react';
import {useSetRecoilState} from 'recoil';
import {star, starOutline} from 'ionicons/icons';
import {Instrument} from 'interfaces/Instruments/Instrument.interface';
import {accountState} from 'states/Account/accountState';
import {accountService} from 'api/account/account.service';
import {Service} from 'interfaces/Services/service.interface';

type Props = {
  setIsFavorite: Function;
  isFavorite?: boolean;
  fields: Instrument | Service;
  favHandler: Function;
};

const FavouritesButton: React.FC<Props> = ({favHandler, fields, isFavorite, setIsFavorite}) => {
  const setAccountData = useSetRecoilState(accountState);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await accountService.getCurrentUser();
        setAccountData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, [isFavorite]);

  return (
    <button
      onClick={async () => {
        fields.id && (await favHandler(fields.id));
        setIsFavorite(!isFavorite);
      }}
    >
      <IonIcon
        icon={isFavorite ? star : starOutline}
        className='text-yellow-400 h-6 w-6 dark:text-[#F0A249]'
      />
    </button>
  );
};

export default FavouritesButton;
