import {diysService} from 'api/diy/diy.service';
import LoadingIndicator from 'components/UILoading/UILoadingIndicator';
import LineColored from 'forms/Instruments/LineColored';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

const DIYList = () => {
  const [diys, setDiys] = useState<any[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    diysService.getAll().then((res) => {
      const alphaDiys = res.data;
      alphaDiys.sort((a: any, b: any) => a.title.localeCompare(b.title));
      setDiys(alphaDiys);
    }).finally(() => setIsLoading(false));
  }, []);

  return (
    <div className='text-black p-5 space-y-5'>
     {isLoading ? (
          <LoadingIndicator />
        ) : (
      diys &&
        diys.map((diy, idx, list) => {
          return (
            <div key={diy.id}>
              {(idx === 0 || diy.title[0] !== list[idx - 1].title[0]) && (
                <div className='text-orange-main'>
                  {diy.title[0].toUpperCase()}
                </div>
              )}
              <Link to={`diy/${diy.id}`}>
                <div className='text-black w-full dark:text-white'>
                  {diy.title}
                </div>
              </Link>
              <LineColored />
            </div>
          );
        }))}
    </div>
  );
};

export default DIYList;
