import React from 'react';
import greenCircle from 'assets/temp/greenCircle.png';
import redCircle from 'assets/temp/redCircle.png';
import IconLocalization from 'assets/IconLocalization.svg';
import {location} from 'ionicons/icons';
import {UIImage} from 'components/UIImages/UIImage';
import {FavoriteInstrument} from 'interfaces/Instruments/Instrument.interface';
import {distanceCalculator} from 'utils/distanceCalculator';
import {IonIcon} from '@ionic/react';
import {useRecoilValue} from 'recoil';
import {geolocationState} from 'states/Common/CommonState';
export interface InstrumentProps {
  fields: FavoriteInstrument[];
  onInstrumentClick: (instrument: any) => void;
}

const FavoritesInstrumentList: React.FC<InstrumentProps> = ({
  fields,
  onInstrumentClick,
}) => {
  const geolocation = useRecoilValue(geolocationState);
  return (
    <div>
      {fields.map((field) => (
        <div key={field.instrument.id + field.instrument.name}>
          <div
            className='rounded-xl border border-yellow-accent mx-6 my-3
          h-36 bg-white drop-shadow-md dark:bg-[#393939]'
            onClick={() => onInstrumentClick(field.instrument)}
          >
            <div className='h-8 bg-yellow-accent rounded-t-lg w-full flex justify-between
            dark:bg-[#F0A249] '>
              <div className='flex text-white'>
                <IonIcon icon={location} className='h-6 w-6 mt-1 ml-3' />
                <p className='text-sm mt-1.5'>
                  {distanceCalculator(
                    field.instrument.lat,
                    field.instrument.lng,
                    geolocation,
                  ).toFixed() + ' '}
                  km
                </p>
              </div>
              {field.instrument.availability && (
                <img alt='greencircle' src={greenCircle} className='h-4 mt-2 mr-3' />
              )}
              {!field.instrument.availability && (
                <img alt='redcircle' src={redCircle} className='h-4 mt-2 mr-3' />
              )}
            </div>
            <div className='flex ml-4 mb-3 font-sans w-full'>
              <div className='w-1/4'>
                <UIImage
                  photoLink={field.instrument.photos}
                   customClassName='mr-3 h-20 w-20 mt-3 mb-2'
                  type='instruments'
                />
              </div>
              <div className='w-3/4'>
                <p className='text-gray-600 pl-2 text-md font-bold mt-1 dark:text-white'>
                  {field.instrument.instrumentName}
                </p>
                <div className='text-gray-500 ml-2 mr-3 text-sm truncate dark:text-gray-400'>
                  {field.instrument.description}
                </div>
                <div className='flex'>
                  <IonIcon icon={IconLocalization} className='h-3 mt-2 ml-1' />
                  <p className='text-gray-400 text-xs mt-1 pl-1'>
                    {field.instrument.location}
                  </p>
                </div>
                <div className='flex'>
                  <p className='text-gray-400 pl-1 text-xs mt-1'>
                    {field.instrument.pricePerDay} euro / pro Tag
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FavoritesInstrumentList;
