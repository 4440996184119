import React, {useEffect, useState} from 'react';
import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar, useIonRouter} from '@ionic/react';
import {accountService} from 'api/account/account.service';
import {Service} from 'interfaces/Services/service.interface';
import {ServicesList} from 'pages/Services/ServicesList/ServiceList';
import UIButtonBack from 'components/UIButtons/UIButtonBack';
import UIButtonAdd from 'components/UIButtons/UIButtonAdd';
import UILogo from 'components/UILogo/UILogo';
import UITopTextBar from 'components/UILabels/UITopTextBar';
import {JobsList} from 'pages/Jobs/JobsList/JobsList';
import UITopBar from 'components/UITopBar/UITopBar';
import {Job} from 'interfaces/Jobs/Jobs.interface';
import {useRecoilValue} from 'recoil';
import {refreshCounterState} from 'states/Common/CommonState';
import {accountState} from 'states/Account/accountState';

export const AccountServicesListPage = () => {
  const service = 'Dienstleistungen';
  const job = 'Jobinserat';

  const currentUser = useRecoilValue(accountState);
  const refresh = useRecoilValue(refreshCounterState);

  const [activeTab, setActiveTab] = useState(service);
  const [jobs, setJobs] = useState<Job[]>();
  const [services, setServices] = useState<Service[]>();

  const router = useIonRouter();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userRes = await accountService.getCurrentUser();
        setServices(userRes.data.mine.services);
        setJobs(userRes.data.mine.jobs);
      } catch {}
    };
    fetchData();
  }, [refresh]);

  const handleAddClicked = () => {
    if (activeTab === service) {
      router.push('/offers-add');
    }
    if (activeTab === job) {
      router.push('/jobs-add');
    }
  };

  const onContactClick = (job: Job, jobId: string) => {
    if (job.email != currentUser?.email) {
      router.push(`/message/${jobId}/${job.owner.firstName}`);
      return;
    }
    if (job.email === currentUser?.email) {
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
          <IonButtons slot='end'>
            <UIButtonAdd onClick={handleAddClicked} />
          </IonButtons>
        </IonToolbar>
        <UITopTextBar>Meine Angebote</UITopTextBar>
      </IonHeader>
      <IonContent>
        <UITopBar
          firstTab={service}
          secondTab={job}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {activeTab === service && services && (
          <ServicesList
            services={services}
            isOwnService={true}
            onServiceClick={(offerId: any) => router.push(`/service/${offerId}`)}
          />
        )}
        {activeTab === job && jobs && (
          <JobsList
            jobs={jobs}
            isOwnJob={true}
            onJobClick={(jobId: any) => router.push(`/job/${jobId}`)}
            onContackClick={() => onContactClick}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default AccountServicesListPage;
