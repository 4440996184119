import React, {useEffect, useState} from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import UILogo from 'components/UILogo/UILogo';
import UITopTextBar from 'components/UILabels/UITopTextBar';
import {servicesService} from 'api/services/services.service';
import {ServicesList} from 'pages/Services/ServicesList/ServiceList';
import LoadingIndicator from 'components/UILoading/UILoadingIndicator';
import {Service} from 'interfaces/Services/service.interface';
import UIButtonBack from 'components/UIButtons/UIButtonBack';
import UIButtonSearch from 'components/UIButtons/UIButtonSearch';
import {funnel} from 'ionicons/icons';
import sorting from 'assets/sorting.svg';
import {distanceCalculator} from 'utils/distanceCalculator';
import UIFilter from 'components/UIFilter/UIFilter';
import {geolocationState, refreshCounterState} from 'states/Common/CommonState';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {
  defaultDistanceMax,
  defaultDistanceMin,
  defaultPriceMax,
  defaultPriceMin,
} from 'utils/constants';
import UIResponsivePadding from 'components/UIResponsive/UIResponsivePadding';

export const ServicesListPage = () => {
  const [originalServices, setOriginalServices] = useState<Service[]>();
  const [services, setServices] = useState<Service[]>();
  const [isFilterShown, setIsFilterShown] = useState(false);
  const [priceMin, setPriceMin] = useState(defaultPriceMin);
  const [priceMax, setPriceMax] = useState(defaultPriceMax);
  const [distanceMin, setDistanceMin] = useState(defaultDistanceMin);
  const [distanceMax, setDistanceMax] = useState(defaultDistanceMax);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const router = useIonRouter();

  const shouldRefreshData = useRecoilValue(refreshCounterState);
  const geolocation = useRecoilValue(geolocationState);
  const setRefreshCount = useSetRecoilState(refreshCounterState);

  useEffect(() => {
    setIsLoading(true);
    servicesService
      .getAll()
      .then((response) => {
        response.data.results &&
          response.data.results.forEach((service: Service) => {
            service.distance = distanceCalculator(service.lat, service.lng, geolocation);
          });
        setOriginalServices(response.data.results);
        setServices(
          response.data.results.sort((a: Service, b: Service) =>
          (a.distance! > b.distance! ? 1 : -1))
        );
      })
      .finally(() => setIsLoading(false));
  }, [shouldRefreshData]);

  const handleFilter = () => {
    let tempData = originalServices;
    tempData = tempData?.filter((item) => {
      return (
        item.pricePerHour! >= priceMin &&
        item.pricePerHour! <= priceMax &&
        item.distance! >= distanceMin &&
        item.distance! <= distanceMax
      );
    });

    setServices(tempData);
  };
  const handleSort = (sortValue: string) => {
    getOrderBy(sortValue);
    // instrumentsService.getSortedInstruments(sortedBy!).then((response) => {
    // });
  };

  // will be handled by API
  const getOrderBy = (sortValue: string) => {
    switch (sortValue) {
      case 'Datum aufsteigend':
        const sorted = [
          ...services!.sort((a, b) =>
            new Date(a.dateAdded!).getTime() < new Date(b.dateAdded!).getTime()
              ? 1
              : -1,
          ),
        ];
        setServices(sorted);
        return 'dateAdded';
      case 'Datum absteigend':
        const sorted2 = [
          ...services!.sort((a, b) =>
            new Date(a.dateAdded!).getTime() > new Date(b.dateAdded!).getTime()
              ? 1
              : -1,
          ),
        ];
        setServices(sorted2);
        return '-dateAdded';
      case 'Preis aufsteigend':
        const sorted3 = [
          ...services!.sort((a, b) =>
            a.pricePerHour! > b.pricePerHour! ? 1 : -1,
          ),
        ];
        setServices(sorted3);
        return 'salary';
      case 'Preis absteigend':
        const sorted4 = [
          ...services!.sort((a, b) =>
            a.pricePerHour! < b.pricePerHour! ? 1 : -1,
          ),
        ];
        setServices(sorted4);
        return '-salary';
      case 'Entfernung aufsteigend':
        const sorted5 = [
          ...services!.sort((a, b) => (a.distance! > b.distance! ? 1 : -1)),
        ];
        setServices(sorted5);
        return 'distance';
      case 'Entfernung absteigend':
        const sorted6 = [
          ...services!.sort((a, b) => (a.distance! < b.distance! ? 1 : -1)),
        ];
        setServices(sorted6);
        return '-distance';
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
          <IonButtons slot='end'>
            <UIButtonSearch itemsType='services' />
          </IonButtons>
        </IonToolbar>
        <UITopTextBar
          leftComponent={
            <button
              title='button'
              onClick={() => setIsFilterShown(!isFilterShown)}
            >
              <IonIcon className='mt-1 ml-3 text-white' icon={funnel} />
            </button>
          }
          rightComponent={
            <div className='flex w-7/8 mr-2'>
              <IonSelect
                okText='Weiter'
                cancelText='Abbrechen'
                onIonChange={(e) => handleSort(e.detail.value)}
                interface='popover'
                placeholder='Sortieren'
                className='text-xs text-transparent w-8 -mr-8 z-10'
              >
                <IonSelectOption className='text-black'>
                  Datum aufsteigend
                </IonSelectOption>
                <IonSelectOption className='text-black'>
                  Datum absteigend
                </IonSelectOption>
                <IonSelectOption className='text-black'>
                  Preis aufsteigend
                </IonSelectOption>
                <IonSelectOption className='text-black'>
                  Preis absteigend
                </IonSelectOption>
                <IonSelectOption className='text-black'>
                  Entfernung aufsteigend
                </IonSelectOption>
                <IonSelectOption className='text-black'>
                  Entfernung absteigend
                </IonSelectOption>
              </IonSelect>
              <img alt='img' className='my-2 mr-3' src={sorting} />
            </div>
          }
        >
          Dienstleistungen
        </UITopTextBar>
      </IonHeader>
      {isFilterShown && (
        <UIFilter
          onFilter={handleFilter}
          setPriceMin={setPriceMin}
          setPriceMax={setPriceMax}
          setDistanceMin={setDistanceMin}
          setDistanceMax={setDistanceMax}
        />
      )}
      <IonContent>
        <UIResponsivePadding>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            services && (
              <ServicesList
                services={services}
                isOwnService={false}
                onServiceClick={(offerId: any) => {
                  router.push(`/service/${offerId}`);
                  setRefreshCount((v) => v + 1);
                }}
                type='services'
              />
            )
          )}
        </UIResponsivePadding>
      </IonContent>
    </IonPage>
  );
};

export default ServicesListPage;
