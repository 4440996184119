import {BFormFieldType} from 'forms/Common/BForm/BFormField';
import {isMinSixCharsRegex} from 'utils/regexCombinations';

export const formNewPassword: BFormFieldType[] = [
  {
    fieldName: 'password',
    fieldType: 'password',
    placeHolder: 'Neues Passwort',
    validation: {
      pattern: {
        value: isMinSixCharsRegex,
        message: 'Das Passwort muss mindestens 6 Zeichen lang sein',
      },
    },
  },
  {
    fieldName: 'confirmPassword',
    fieldType: 'password',
    placeHolder: 'Wiederhole Das Neues Passwort ',
    validation: {
      pattern: {
        value: isMinSixCharsRegex,
        message: 'Das Passwort muss mindestens 6 Zeichen lang sein',
      },
    },
  },
];
