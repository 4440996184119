import React, {useState} from 'react';

export interface dayInterface {
  name: Date;
  id: number;
}

type Props = {
  date: Date;
  onClick: Function;
  place: string;
  addedDays: Array<dayInterface>;
  id: number;
  setAddedDays: Function;
};

const CalendarDay: React.FC<Props> = ({date, onClick, place, addedDays, id, setAddedDays}) => {
  const [isClicked, setIsClicked] = useState(false);

  const addDateHandler = () => {
    onClick();
    setIsClicked(!isClicked);
    const tempAddedDays: dayInterface[] = [...addedDays];
    tempAddedDays.push({
      name: date,
      id: id,
    });
    setAddedDays(tempAddedDays);
  };

  const deleteDateHandler = () => {
    setIsClicked(!isClicked);
    setAddedDays(addedDays.filter((day) => day.id !== id));
  };

  return (
    <div className={`bg-yellow-accent w-10 text-xs text-center rounded-md mr-1 mb-2
    dark:bg-[#F0A249] ${place}`}>
      <p className='pt-1 font-sans w-10'>
        {date
          .toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'})
          // do usunięcia wszystko w nawiasie toLocaleDate jeśli nie działa na andku
          .slice(0, -5)}
      </p>
      <div
        className='w-full h-6 rounded-md border border-yellow-accent
    bg-white mt-0.5 flex justify-center dark:bg-[#393939]'
        onClick={isClicked ? deleteDateHandler : addDateHandler}>
        {isClicked && <div className='w-8 h-5 rounded-md bg-yellow-accent mt-[1px]'></div>}
      </div>
    </div>
  );
};

export default CalendarDay;
