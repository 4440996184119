import {IonCol, IonContent, IonGrid, IonHeader, IonPage, IonRow, IonToolbar} from '@ionic/react';
import UILogo from 'components/UILogo/UILogo';
import React from 'react';
import diy from 'assets/Community/diy.svg';
import forum from 'assets/Community/forum.svg';
import jobsServices from 'assets/Community/jobsServices.svg';
import contacts from 'assets/Community/contacts.svg';
import UITopTextBar from 'components/UILabels/UITopTextBar';
import UIOrangeSquareBox from 'components/UIContainer/UIOrangeSquareBox';
import UIResponsivePadding from 'components/UIResponsive/UIResponsivePadding';

const CommunityPage: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>Angebote</UITopTextBar>
      </IonHeader>
      <IonContent>
        <UIResponsivePadding>
          <IonGrid fixed>
            <IonRow>
              <IonCol size='6'>
                <UIOrangeSquareBox
                  name='DO IT YOURSELF'
                  src={diy}
                  alt='DIY'
                  linkTo='/diys'
                />
                <UIOrangeSquareBox
                  name='DIENSTLEISTUNGEN UND JOBS'
                  src={jobsServices}
                  alt='jobs and services'
                  linkTo='/jobs-and-services'
                />
              </IonCol>
              <IonCol size='6'>
                <UIOrangeSquareBox
                  name='FORUM'
                  src={forum}
                  alt='forum'
                  linkTo='/forum'
                />

                <UIOrangeSquareBox
                  name='NACHRICHTEN'
                  src={contacts}
                  alt='contacts'
                  linkTo='/chat'
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </UIResponsivePadding>
      </IonContent>
    </IonPage>
  );
};

export default CommunityPage;
