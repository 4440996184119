import React, {useState} from 'react';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from 'react-google-places-autocomplete';
import {Controller} from 'react-hook-form';
import BMiniMap from './BMiniMap';

type BFormAddressProps = {
  control: any;
  fieldName: any;
  placeholder?: string;
  latLng?: {lat: number; lng: number};
};

export const BFormAddressField: React.FC<BFormAddressProps> = ({
  control,
  fieldName,
  placeholder = 'Adresse eingeben',
  latLng,
}) => {
  let street: string;
  let zipCode: string;
  let houseNumber: string;
  let city: string;
  const [geolocation, setGeolocation] = useState(
    latLng
    ? {lat: latLng.lat, lng: latLng.lng}
    : {lat: 48.210033, lng: 16.363449},
  );

  const handleInputChange = (onChange: any, address: any) => {
    geocodeByPlaceId(address.value.place_id).then((res) => {
      res[0].address_components.map((field) => {
        if (field.types[0] === 'postal_code') {
          zipCode = field.long_name;
        }
        if (field.types[0] === 'route') {
          street = field.short_name;
        }
        if (field.types[0] === 'street_number') {
          houseNumber = field.long_name;
        }
        if (field.types[0] === 'locality') {
          city = field.long_name;
        }
      });
      getLatLng(res[0]).then((latLng) => {
        let label = '';
        address.value.terms.forEach((el: any, key: any, arr: any) => {
          if (!Object.is(arr.length - 1, key)) {
            label += el.value + ' ';
          }
        });
        label = label.substr(0, label.length - 1);
        const data = {
          label: label,
          lat: latLng.lat,
          lng: latLng.lng,
          postCode: zipCode,
          street: street,
          houseNumber: houseNumber,
          city: city,
        };
        setGeolocation({lat: latLng.lat, lng: latLng.lng}), onChange(data);
      });
    });
  };

  return (
    <div>
      <Controller
        control={control}
        name={fieldName}
        render={({field: {onChange}}) => (
          <GooglePlacesAutocomplete
            apiKey='AIzaSyDO-5cVZigsb3Qc_FTuQHMhCb7GaIzzmk8'
            selectProps={{
              className: 'z-50',
              placeholder: placeholder,
              onChange: (address: any) => handleInputChange(onChange, address),
            }}
          />
        )}
        rules={{required: false}}
      />
      <BMiniMap geolocation={geolocation} />
    </div>
  );
};
