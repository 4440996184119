import React, {useState} from 'react';
import {IonItem, IonLabel, IonRadio, IonRadioGroup} from '@ionic/react';

type Props = {
  setCheckedValue: Function;
};

const AccountTypeEdit: React.FC<Props> = ({setCheckedValue}) => {
  const [selected, setSelected] = useState<string>();

  return (
    <div>
      <IonRadioGroup
        value={selected}
        onIonChange={(e: any) => {
          setSelected(e.detail.value);
          if (e.detail.value === 'Privater Account') setCheckedValue('PRIVATE');
          if (e.detail.value === 'Geschäftskonto') setCheckedValue('COMPANY');
        }}
      >
        <IonItem
          style={{color: 'black'}}
          lines='none'
        >
          <IonLabel>
            <b className='font-bold text-black text-xs dark:text-white '>Privater Account</b>
          </IonLabel>
          <IonRadio
            slot='start'
            value='Privater Account'
          />
        </IonItem>
        <IonItem
          style={{
            color: 'black'
          }}
          lines='none'
        >
          <IonLabel>
            <b className='font-bold text-black text-xs dark:text-white '>Geschäftskonto</b>
          </IonLabel>
          <IonRadio
            slot='start'
            value='Geschäftskonto'
          />
        </IonItem>
      </IonRadioGroup>
    </div>
  );
};

export default AccountTypeEdit;
