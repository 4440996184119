import React, {useEffect, useState} from 'react';
import {IonContent, IonPage} from '@ionic/react';
import {useHistory, useParams} from 'react-router';
import UIOrangeButton from 'components/UIButtons/UIOrangeButton';
import {deleteCart, finalizePromotion} from 'api/promotions/promotions.service';

export const PromotionPaymentResultPage = () => {
  const {paymentId}: any = useParams();
  const [resultText, setResultText] = useState('Laden...');
  const history = useHistory();

  useEffect(() => {
    let isMounted = true;

    if (resultText == 'Laden...') {
      finalizePromotion(paymentId!)
        .then(() => {
          if (isMounted) {
            setResultText('Bezahlung erfolgreich!');
            deleteCart();
          }
        })
        .catch(() => {
          if (isMounted) {
            setResultText('Bezahlung fehlgeschlagen!');
            deleteCart();
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [resultText]);

  return (
    <IonPage>
      <IonContent>
        <div className='h-full flex justify-center items-center'>
          <div className='space-y-10 mx-5'>
            <p className='text-center'>
              Wenn Sie die Zahlung über die mobile Anwendung vorgenommen haben,
              können Sie den Browser schließen
            </p>
            <p className='text-2xl text-center underline'>{resultText}</p>
            <UIOrangeButton
              type={'button'}
              title='Zurück'
              onClick={() => history.replace('/')}
            />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PromotionPaymentResultPage;
