import {IonIcon} from '@ionic/react';
import {closeOutline} from 'ionicons/icons';
import React from 'react';
import placeholderImage from 'assets/placeholder-image.png';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination, Zoom} from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import 'swiper/css/bundle';
import '@ionic/react/css/ionic-swiper.css';

interface PhotoData {
  image: string;
}

type photoHelperProps = {
  photoLink?: Array<PhotoData> | any;
  customClassName?: string;
  type?: string;
  onClosePopUp: () => void;
};

export const UIPopUpPhoto: React.FC<photoHelperProps> = ({
  photoLink,
  type,
  onClosePopUp,
}) => {
  return (
    <div>
      <div
        className='absolute bg-zinc-900 w-full z-50
      bg-opacity-70 rounded-lg h-screen'
      >
        <div className='flex flex-col h-full'>
          <div className='flex justify-end'>
            <button onClick={onClosePopUp}>
              <IonIcon icon={closeOutline} className='text-white h-10 w-10' />
            </button>
          </div>
          <div className='flex justify-center h-3/4'>
            <Swiper
              modules={[Navigation, Pagination, Zoom]}
              slidesPerView={1}
              centeredSlides={true}
              pagination={{clickable: true}}
              navigation
              spaceBetween={50}
              zoom={{maxRatio: 3, minRatio: 1}}
              className='mySwiper'
            >
              {photoLink.map((image: {photo: string}, index: number) => (
                <SwiperSlide key={index}>
                  <div className='swiper-zoom-container'>
                    <img
                      src={image.photo ? image.photo : placeholderImage}
                      className='px-4'
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UIPopUpPhoto;
