import React, {useEffect, useState} from 'react';
import {instrumentsService} from 'api/instruments/instruments.service';
import {jobsService} from 'api/jobs/jobs.service';
import {servicesService} from 'api/services/services.service';
import {SearchResult} from './SearchResults';
import {productsService} from 'api/products/products.service';
import {distanceCalculator} from 'utils/distanceCalculator';
import {geolocationState} from 'states/Common/CommonState';
import {useRecoilValue} from 'recoil';

interface Props {
  itemsType: string;
  category: string;
}

const SearchComponent = ({itemsType, category}: Props) => {
  const [items, setItems] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const geolocation = useRecoilValue(geolocationState);
  const getItemsService = () => {
    switch (itemsType) {
      case 'instruments':
        return instrumentsService;
      case 'services':
        return servicesService;
      case 'jobs':
        return jobsService;
      case 'products':
        return productsService;
    }
  };

  const service = getItemsService();
  useEffect(() => {
    const search = async () => {
      try {
        await service!.search(searchTerm, category).then((res) => {
          res.forEach((item: any) => {
            item.distance = distanceCalculator(
              item.lat,
              item.lng,
              geolocation,
            );
          });
          setItems(res.sort((a:any, b:any) =>
          a.distance! > b.distance! ? 1 : -1,
        ));
        });
      } catch (error) {
        console.log(error);
      }
    };
    search();
  }, [searchTerm]);

  return (
    <div className='w-full -mt-1.5'>
      <div className='w-full bg-gray-500 flex p-1'>
        <input
          className='rounded-md w-full pl-1 bg-white'
          type='text'
          placeholder='Suche'
          onChange={(e) => setSearchTerm(e.currentTarget.value)}
        />
      </div>
      <div className='w-full'>
        {items && <SearchResult itemsType={itemsType} items={items} />}
      </div>
    </div>
  );
};
export default SearchComponent;
