import React from 'react';
import {BASE_URL} from 'utils/constants';
import placeholderImage from 'assets/placeholder-image.png';

type photoHelperProps = {
  photoLink: string;
  customClassName?: string;
  type?: string;
};

export const UIImage: React.FC<photoHelperProps> = ({
  customClassName,
  photoLink,
  type,
}) => {
  type && (photoLink = photoLink.substring(21, photoLink.length));
  const url = BASE_URL + photoLink;

  return (
    <div>
      <img
        alt='img'
        src={photoLink ? url : placeholderImage}
        className={`
          ${customClassName ? customClassName : 'h-44 w-36 my-12 mb-3 rounded-xl '} centered
        `}
      />
    </div>
  );
};
