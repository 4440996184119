import {LatLng} from 'react-google-places-autocomplete/build/GooglePlacesAutocomplete.types';
import {atom} from 'recoil';

export const refreshCounterState = atom({
  key: 'refreshCounterState',
  default: 0,
});

export const geolocationState = atom<LatLng>({
  key: 'geolocationState',
  default: {lat: 48.210033, lng: 16.363449},
});

export const defaultGeolocationState = atom<LatLng>({
  key: 'defaultGeolocationState',
  default: {lat: 48.210033, lng: 16.363449},
});

export const photoState = atom({
  key: 'photoState',
  default: '',
});
