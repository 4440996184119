import client from 'api/common/client';

export const addToCart = async (type: string, typeId: number, data: any) => {
  const response = await client.put(`/${type}/${typeId}/add-to-cart/`, data);
  return response.data;
};

export const getCart = async () => {
  const response = await client.get('/payments/cart/');
  return response;
};

export const deleteCart = async () => {
  const response = await client.delete('payments/cart/');
  return response;
};

export const promote = async (cartId: number) => {
  const response = await client.post(`/payments/check-out/`, {cart: cartId});
  return response.data;
};

export const applyPromoCode = async (promoCode: string) => {
  const response = await client.put(`/payments/cart/`, {code: promoCode});
  return response.data;
};

export const finalizePromotion = async (paymentId: string) => {
  const response = await client.get(
    `/payments/check-out/${paymentId}/confirmation/`,
  );
  return response.data;
};
