import React, {useState} from 'react';
import {TextFieldTypes} from '@ionic/core';
import formatError from 'utils/formatError';
import {eyeOffOutline, eyeOutline} from 'ionicons/icons';
import {IonIcon} from '@ionic/react';
import AccountTypeEdit from 'pages/Account/AccountEdit/AccountTypeEdit';

export type BFormFieldType = {
  fieldName: string;
  placeHolder: string;
  validation: any;
  isNumberPrefix?: boolean;
  fieldType: TextFieldTypes | 'checkbox';
};

const BFormField = ({
  field,
  errors,
  register,
  setCheckedValue,
}: {
  field: BFormFieldType;
  errors: any;
  register: Function;
  setCheckedValue: Function;
}) => {
  const [visible, setVisible] = useState<boolean>(false);

  const hasFieldErrors: boolean = errors && field.fieldName in errors;
  return (
    <div
      className='text-sm mt-3 dark:text-white'
      key={field.fieldName}
    >
      {field.placeHolder}
      <div className='flex'>
        {field.isNumberPrefix && (
          <div className='absolute border-r border-black h-9 w-11 text-center pt-2'>+43</div>
        )}
        <input
          className={
            'appearance-none border border-black rounded-lg w-full p-2 ' +
            'bg-transparent focus:outline-none focus:shadow-outline ' +
            (hasFieldErrors ? 'border-red-500 ' : 'border-black ') +
            (field.fieldType === 'checkbox' ? 'border-white ' : 'border-black ') +
            (field.isNumberPrefix && 'pl-12')
          }
          placeholder={null}
          type={visible ? 'text' : field.fieldType}
          {...register(field.fieldName, {...field.validation})}
        />
        {field.fieldType === 'password' && (
          <div className='absolute right-6 flex justify-end'>
            <div className='w-full'></div>
            <div>
              {!visible && (
                <IonIcon
                  icon={eyeOutline}
                  className='h-5 w-5 pt-2'
                  onClick={() => setVisible(true)}
                />
              )}
              {visible && (
                <IonIcon
                  icon={eyeOffOutline}
                  className='h-5 w-5 pt-2'
                  onClick={() => setVisible(false)}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {field.fieldType === 'checkbox' && <AccountTypeEdit setCheckedValue={setCheckedValue} />}
      {hasFieldErrors && formatError(errors[field.fieldName])}
    </div>
  );
};

export default BFormField;
