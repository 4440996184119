import React, {useState} from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonIcon,
  IonTabButton,
  IonTabBar,
} from '@ionic/react';
import UILogo from 'components/UILogo/UILogo';
import UITopTextBar from 'components/UILabels/UITopTextBar';
import UIButtonBack from 'components/UIButtons/UIButtonBack';
import locationoutline from '../../../assets/tab/locationoutline.svg';
import location from '../../../assets/tab/location.svg';
import notificationsoutline from '../../../assets/tab/notificationsoutline.svg';
import notifications from '../../../assets/tab/notifications.svg';
import star from '../../../assets/tab/star.svg';
import staroutline from '../../../assets/tab/staroutline.svg';
import {PromotionsCostsList} from './PromotionsCostsList';
import {useParams} from 'react-router';
import {cart, cartOutline} from 'ionicons/icons';
import PromotionsCart from '../PromotionsCart/PromotionsCart';

const firstTab = 'list';
const secondTab = 'map';
const thirdTab = 'push';
const fourthTab = 'cart';

export const PromotionsCostsListPage = () => {
  const [activeTab, setActiveTab] = useState(firstTab);
  const {type} = useParams<{type: string}>();

  const getBreadCrumb = () => {
    switch (type) {
      case 'instruments':
        return 'Mietgeräte';
      case 'products':
        return 'Verkaufen';
      case 'services':
        return 'Dienstleistungen';
      case 'jobs':
        return 'Jobinserat';
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar
          customClassName='h-10 bg-gray-600 text-center'
          customTextClassName='w-full pt-1.5'
        >
          {getBreadCrumb()} &gt; Meine Werbungen
        </UITopTextBar>
      </IonHeader>
      <IonContent>
        <div className='h-3/4'>
          <IonTabBar slot='bottom' className='h-[44px]'>
            <IonTabButton
              tab={firstTab}
              className={
                activeTab === firstTab
                  ? 'border-b-[1px] border-orange-300 text-orange-300 text-[17px]'
                  : 'text-black text-[17px] border-b-[1px] border-gray-500'
              }
            >
              {activeTab === firstTab ? (
                <IonIcon icon={star} />
              ) : (
                <IonIcon icon={staroutline} />
              )}
            </IonTabButton>
            {type !== 'products' && (
              <IonTabButton
                tab={secondTab}
                className={
                  activeTab === secondTab
                    ? 'border-b-[1px] border-orange-300 text-orange-300 text-[17px]'
                    : 'text-black text-[17px] border-b-[1px] border-gray-500'
                }
              >
                {activeTab === secondTab ? (
                  <IonIcon icon={location} />
                ) : (
                  <IonIcon icon={locationoutline} />
                )}
              </IonTabButton>
            )}
            {(type == 'services' || type == 'jobs') && (
              <IonTabButton
                tab={thirdTab}
                className={
                  activeTab === thirdTab
                    ? 'border-b-[1px] border-orange-300 text-orange-300 text-[17px]'
                    : 'text-black text-[17px] border-b-[1px] border-gray-500'
                }
              >
                {activeTab === thirdTab ? (
                  <IonIcon icon={notifications} />
                ) : (
                  <IonIcon icon={notificationsoutline} />
                )}
              </IonTabButton>
            )}
            <IonTabButton
              tab={fourthTab}
              className={
                activeTab === fourthTab
                  ? 'border-b-[1px] border-orange-300 text-orange-300 text-[17px]'
                  : 'text-black text-[17px] border-b-[1px] border-gray-500'
              }
            >
              <IonIcon icon={activeTab === fourthTab ? cart : cartOutline} />
            </IonTabButton>
          </IonTabBar>
          {activeTab === firstTab && (
            <PromotionsCostsList
              prefix='Anzeige hervorheben für'
              suffix='Tage'
              promotionType={'list'}
              nextTab={type === 'products' ? 'cart' : 'map'}
              setActiveTab={setActiveTab}
            />
          )}
          {activeTab === secondTab && (
            <PromotionsCostsList
              prefix='Logo einstellen bei Maps für'
              suffix='Tage'
              promotionType={'map'}
              setActiveTab={setActiveTab}
              nextTab={type === 'instruments' ? 'cart' : 'push'}
            />
          )}
          {activeTab === thirdTab && (
            <PromotionsCostsList
              prefix='1 Push Nachricht an'
              suffix='User'
              promotionType={'push'}
              setActiveTab={setActiveTab}
              nextTab={'cart'}
            />
          )}
          {activeTab === fourthTab && <PromotionsCart type={type} />}
        </div>
      </IonContent>
    </IonPage>
  );
};
