import React, {useEffect, useState} from 'react';
import {
  IonIcon,
  IonTextarea,
  IonSelect,
  IonSelectOption,
  useIonRouter,
} from '@ionic/react';
import {useForm} from 'react-hook-form';
import {instrumentsService} from 'api/instruments/instruments.service';
import {BFormAddressField} from 'forms/Common/places/BFormAddressField';
import UIInput from 'components/UIInputs/UIInput';
import LineColored from './LineColored';
import UIYellowButton from 'components/UIButtons/UIYellowButton';
import UIToggleButton from 'components/UIButtons/UIToggleButton';
import AddPhotos from 'components/UIAddPhoto/UIAddPhotos';
import IconEuro from 'assets/IconEuro.svg';
import {useRecoilValue} from 'recoil';
import {accountState} from 'states/Account/accountState';
import {useSetRecoilState} from 'recoil';
import {refreshCounterState} from 'states/Common/CommonState';
import {eyeOutline, star} from 'ionicons/icons';
import {
  InstrumentRequest,
  DefaultInstrumentRequestValues,
  Category,
  Subcategory,
} from 'interfaces/Instruments/Instrument.interface';

function FormEditInstrument({instrumentId}: any) {
  const setRefreshCount = useSetRecoilState(refreshCounterState);
  const accountData = useRecoilValue(accountState);

  const [categories, setCategories] = useState<Category[]>([]);
  const [subcategories, setSubcategories] = useState<Subcategory[]>([]);
  const [subCatId, setSubcatId] = useState<number>();
  const [subCatDefaultId, setSubCatDefaultId] = useState<number>();
  const [catId, setCatId] = useState<string>();
  const [catValue, setCatvalue] = useState<string>();
  const [subCatValue, setSubCatValue] = useState<string>();
  const [addressPlaceholder, setAddressPlaceholder] = useState<string>(
    'Wähle einen Adresse',
  );
  const [oldPhotos, setOldPhotos] = useState<{photo: string}[]>();
  const [availability, setAvailability] = useState(false);
  const [instrument, setInstrument] = useState<InstrumentRequest>(
    DefaultInstrumentRequestValues,
  );
  const [apiResultMessage, setApiResultMessage] = useState<string>('');

  const router = useIonRouter();

  const {
    handleSubmit,
    register,
    setValue,
    formState: {errors},
    control,
  } = useForm();

  useEffect(() => {
    instrumentsService.getDetails(instrumentId).then((response) => {
      setInstrument(response.data);
      setCatvalue(response.data.category.name);
      setSubCatValue(response.data.subcategory.name);
      setSubCatDefaultId(response.data.subcategory.id);
      setCatId(response.data.category.id);

      setValue('weekHours', response.data.weekHours);
      setValue('weekendHours', response.data.weekendHours);
      setValue('pricePerDay', response.data.pricePerDay);
      setValue('category', response.data.category.id);
      setValue('subcategory', response.data.subcategory.id);
      setValue('instrumentName', response.data.instrumentName);
      setValue('name', response.data.name);
      setValue('email', response.data.email);
      setValue('productionYear', response.data.productionYear);
      setValue('phoneNumber', accountData?.phoneNumber);
      setValue('description', response.data.description);
      setValue('lat', response.data.lat.toString());
      setValue('lng', response.data.lng.toString());
      setValue('postCode', response.data.postCode);
      setValue('Address', response.data.Address);
      setValue('location', response.data.location);
      setValue('availability', response.data.availability);
      setAvailability(response.data.availability);
      setOldPhotos(response.data.photos);
      setAddressPlaceholder(response.data.location);
    });

    instrumentsService.getCategories().then((response) => {
      setCategories(response.data.results);
    });
  }, []);

  useEffect(() => {
    instrumentsService.getSubcategories(catId!).then((response) => {
      setSubcategories(response.data.results);
    });
  }, [catId]);

  const onSubmit = (data: InstrumentRequest) => {
    data.category = catId;
    data.subcategory = subCatId ? subCatId : subCatDefaultId;
    data.pricePerDay = +data.pricePerDay;
    data.productionYear = +data!.productionYear!;
    data.availability = availability;

    if (data.Address) {
      data.location = data.Address.label;
      data.postCode = data.Address.postCode;
      data.lat = data.Address.lat;
      data.lng = data.Address.lng;
      setAddressPlaceholder(data.location!);
    }

    instrumentsService
      .updateInstrument(data, instrumentId)
      .then((res) => {
        setApiResultMessage('Erfolg!');
        setRefreshCount((v) => v + 1);
        if (!instrument.issued) {
          router.push(`/purchase/instruments/${instrumentId}`);
          setApiResultMessage('');
        }
      })
      .catch(() => {
        setApiResultMessage('Error!');
      });
  };

  return (
    <form
      className='h-full pb-3 '
      onSubmit={handleSubmit(onSubmit)}>
      <div className='border-2 border-orange-main text-black bg-white rounded-md dark:bg-[#393939]'>
        {addressPlaceholder && (
          <div>
            <div className='h-6 bg-yellow-accent dark:bg-[#F0A249]'></div>
            <div className='flex my-4 bg-red'>
              <div className='flex-col mx-2 w-2/5 space-y-2 text-sm'>
                <div className='flex-col w-2/5 space-y-2 text-sm mb-16'>
                  <AddPhotos
                    name='photos'
                    photosType='instruments'
                    photos={oldPhotos}
                    control={control}
                    setPhotos={setOldPhotos}
                  />
                </div>
                <div>
                  <p className='dark:text-white'>Preis pro Tag:</p>
                  <div className='flex'>
                    <UIInput
                      customClassName='bg-white w-full text-sm dark:bg-[#393939]  dark:text-white '
                      name='pricePerDay'
                      type='number'
                      placeholder='80'
                      register={register}
                      validation={{required: true}}
                      errors={errors}
                    />
                    <IonIcon
                      icon={IconEuro}
                      className='mt-0.5 text-gray-500 dark:text-white '
                    />
                  </div>
                  <LineColored error={errors.pricePerDay} />
                </div>
                <div className='dark:text-white'>
                  <UIToggleButton
                    name='availability'
                    title='Verfügbarkeit'
                    state={availability}
                    register={register}
                    setState={setAvailability}
                  />
                </div>
              </div>
              <div className='flex-col space-y-2 mr-[15px] w-3/5 text-sm'>
                <div className='space-y-2 mb-2'>
                  <div
                    className='w-44 h-6 p-4 flex items-center justify-between
              rounded-xl border-orange-main border text-orange-main'>
                    <IonSelect
                      className='text-black dark:text-white'
                      okText='Weiter'
                      cancelText='Abbrechen'
                      placeholder={catValue}
                      onIonChange={(cat) => setCatId(cat.detail.value)}>
                      {categories.map((cat) => (
                        <IonSelectOption
                          key={cat.id}
                          value={cat.id}>
                          {cat.name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </div>
                  <div
                    className='w-44 h-6 p-4 flex items-center justify-between
              rounded-xl border-orange-main border text-orange-main'>
                    <IonSelect
                      className='text-black dark:text-white'
                      okText='Weiter'
                      cancelText='Abbrechen'
                      placeholder={subCatValue}
                      onIonChange={(subcat) =>
                        setSubcatId(subcat.detail.value)
                      }>
                      {subcategories.map((subcat) => (
                        <IonSelectOption
                          key={subcat.id}
                          value={subcat.id}>
                          {subcat.name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </div>
                </div>
                <div>
                  <p className='dark:text-white'>Name:</p>
                  <UIInput
                    customClassName='dark:bg-[#393939]  dark:text-white '
                    name='instrumentName'
                    placeholder='Mischmaschine'
                    required={'required'}
                    register={register}
                    validation={{required: true}}
                    errors={errors}
                  />
                  <LineColored error={errors.instrumentName} />
                </div>
                <div>
                  <p className='dark:text-white'>Geräte Bezeichnung:</p>
                  <UIInput
                    customClassName='dark:bg-[#393939]  dark:text-white '
                    name='name'
                    placeholder='Zweck'
                    required={'required'}
                    register={register}
                    validation={{required: true}}
                    errors={errors}
                  />
                  <LineColored error={errors.purpose} />
                </div>
                <div>
                  <p className='dark:text-white'>Kontakt:</p>
                  <UIInput
                    customClassName='dark:bg-[#393939]  dark:text-white '
                    name='email'
                    placeholder='beispiel@email.com'
                    required='required'
                    register={register}
                    validation={{required: true}}
                    errors={errors}
                  />
                  <LineColored error={errors.email} />
                </div>
                <div>
                  <p className='dark:text-white'>Baujahr:</p>
                  <UIInput
                    customClassName='dark:bg-[#393939]  dark:text-white '
                    name='productionYear'
                    type='number'
                    placeholder='Jahr'
                    register={register}
                    errors={errors}
                  />
                  <LineColored error={errors.productionYear} />
                </div>
                <div>
                  <p className='dark:text-white'>Telefonnummer:</p>
                  <UIInput
                    customClassName='dark:bg-[#393939]  dark:text-white '
                    name='phoneNumber'
                    type='tel'
                    placeholder='0660 1234567'
                    minLength={9}
                    maxLength={16}
                    register={register}
                    validation={{required: true}}
                    errors={errors}
                  />
                  <LineColored error={errors.phoneNumber} />
                </div>
                <div className='text-yellow-accent text-center font-bold mb-5 clear-both'>
                  {apiResultMessage}
                </div>
              </div>
            </div>
            <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
              <div className='text-orange-main text-sm'>Adresse</div>
              <div className='bg-orange-main w-full h-px'></div>
            </div>
            <div className='mx-2 mt-2'>
              <BFormAddressField
                control={control}
                fieldName='Address'
                key={Math.random()}
                placeholder={addressPlaceholder}
                latLng={{lat: +instrument.lat, lng: +instrument.lng}}
              />
            </div>
            <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
              <div className='text-orange-main text-sm'>Beschreibung</div>
              <div className='bg-orange-main w-full h-px'></div>
            </div>
            <IonTextarea
              rows={4}
              className='ipnut text-sm h-[100px] dark:bg-[#393939]  dark:text-white '
              {...register('description')}
              placeholder='230 V Mischmaschine mit 140 Liter Fassungsvermögen
          in sehr guten Zustand inkl 10m Anschlusskabel'
              value={instrument?.description}
              name='description'
            />
            <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
              <div className='text-orange-main text-sm'>
                Datum und Statistik
              </div>
              <div className='bg-orange-main w-[1400px] h-px'></div>
            </div>
            <div className='flex text-sm place-content-center dark:text-gray-300 h-10'>
              <div className='flex pr-11 ml-1'>
                <p className='dark:text-gray-300 pr-2'>Verfügbar bis:</p>
                <p className='dark:text-gray-300 pr-4'>
                  {instrument.issuedPromotedUntil
                    ? new Date(
                        instrument.issuedPromotedUntil,
                      ).toLocaleDateString()
                    : 'Nicht eingelöst'}
                </p>
                <p className='dark:text-gray-300 pr-2'>Befördert zu:</p>
                <p className='dark:text-gray-300'>
                  {instrument.mapPromotedUntil
                    ? new Date(instrument.mapPromotedUntil).toLocaleDateString()
                    : 'Nicht eingelöst'}
                </p>
              </div>
              <div className='flex place-content-end mr-1'>
                <IonIcon
                  icon={star}
                  className='text-yellow-400 h-4 w-5 dark:text-[#F0A249] pr-1'
                />
                <p className='pr-2 text-md'>
                  {instrument.countFavourite ? instrument.countFavourite : 0}
                </p>
                <IonIcon
                  icon={eyeOutline}
                  className='h-5 w-5 pr-1'
                />
                <p>
                  {instrument.visitors}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='flex flex-col py-5 justify-end text-white'>
        <UIYellowButton
          type='submit'
          text='Weiter'
        />
      </div>
    </form>
  );
}

export default FormEditInstrument;
