import React from 'react';
import UIInput from 'components/UIInputs/UIInput';
interface UIToggleProps {
  title: string;
  state: boolean;
  setState: any;
  register: any;
  name?:any;
}
const UIToggleButton: React.FC<UIToggleProps> = ({
  setState,
  state,
  title,
  register,
}) => {
  const switchToggle = () => {
    setState((state: boolean) => !state);
  };
  return (
    <div className='flex-col items-start pr-20 pt-1'>
      <p className='flex flex-nowrap'>{title}</p>
      <div
        className='w-14 h-6 my-1 bg-white border-[1px]
              border-yellow-accent rounded-3xl dark:bg-[#393939] '
      >
        <button className='ml-1 relative ' onClick={switchToggle}>
          {state ? (
            <div className='flex items-start mt-0.5 ml-0.5 dark:bg-[#393939] '>
              <div className='pr-1 text-yellow-accent text-xs mt-[2px] dark:text-white'>ON</div>
              <div className='w-4 h-4 bg-yellow-accent rounded-full ml-1 mt-0.5
              dark:bg-[#F0A249]'></div>
              <UIInput
                customClassName='top-0 w-0 h-0 absolute '
                type='checkbox'
                placeholder=''
                register={register}
                defaultValue={true}
                name='availability'
              />
            </div>
          ) : (
            <div className='flex items-center ml-r mt-0.5 dark:bg-[#393939] '>
              <div className='w-4 h-4 bg-yellow-accent rounded-full mr-1'></div>
              <div className='pr-1 text-yellow-accent text-xs dark:text-white'>OFF</div>
            </div>
          )}
        </button>
      </div>
    </div>
  );
};
export default UIToggleButton;
