import {BFormFieldType} from 'forms/Common/BForm/BFormField';
import {isMailRegex} from 'utils/regexCombinations';

export const formRecoveryPassword: BFormFieldType[] = [
  {
    fieldName: 'email',
    fieldType: 'text',
    placeHolder: 'E-mail',
    validation: {
      required: true,
      pattern: {
        value: isMailRegex,
      },
    },
  },
];
