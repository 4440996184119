import React, {useEffect, useState} from 'react';
import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar, useIonRouter} from '@ionic/react';
import {useParams} from 'react-router';
import {accountService} from 'api/account/account.service';
import {Instrument} from 'interfaces/Instruments/Instrument.interface';
import InstrumentsList from 'pages/Instruments/InstrumentsList/InstrumentsList';
import UILogo from 'components/UILogo/UILogo';
import UITopTextBar from 'components/UILabels/UITopTextBar';
import UIButtonBack from 'components/UIButtons/UIButtonBack';
import UIButtonAdd from 'components/UIButtons/UIButtonAdd';
import UITopBar from 'components/UITopBar/UITopBar';
import ProductsListBuyPage from 'pages/Products/ProductsList/ProductsList';
import {useRecoilValue} from 'recoil';
import {refreshCounterState} from 'states/Common/CommonState';
import {Product} from 'interfaces/Products/product.interface';

const AccountInstrumentsListPage = () => {
  const loan = 'Mietgeräte';
  const buy = 'Verkaufen';

  const [activeTab, setActiveTab] = useState(loan);
  const [instruments, setInstruments] = useState<Instrument[]>();
  const [products, setProducts] = useState<Product[]>();
  const refresh = useRecoilValue(refreshCounterState);
  const {listType}: any = useParams();

  const router = useIonRouter();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userRes = await accountService.getCurrentUser();
        setInstruments(userRes.data.mine.instruments);
        setProducts(userRes.data.mine.products);
      } catch {}
    };
    fetchData();
  }, [refresh]);

  const handleItemClick = (item: Instrument | Product) => {
    if (listType === 'preview') {
      router.push(
        activeTab === loan
          ? `/instrument/${item.category.name}/${item.subcategory.name}/${item.id}`
          : `/product/${item.category.name}/${item.subcategory.name}/${item.id}`
      );
      return;
    }
    router.push(
      activeTab === loan ? `/promote/instruments/${item.id}` : `/promote/products/${item.id}`
    );
  };

  const handleAddClicked = () => {
    if (activeTab === loan) {
      router.push('/instruments-add');
    }
    if (activeTab === buy) {
      router.push('/products-add');
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
          <IonButtons slot='end'>
            <UIButtonAdd onClick={handleAddClicked} />
          </IonButtons>
        </IonToolbar>
        <UITopTextBar>Meine Verleihgeräte</UITopTextBar>
      </IonHeader>
      <IonContent>
        <UITopBar
          firstTab={loan}
          secondTab={buy}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {activeTab === loan && instruments && (
          <InstrumentsList
            instruments={instruments}
            isOwnInstruments={true}
            onInstrumentClick={handleItemClick}
          />
        )}
        {activeTab === buy && products && (
          <ProductsListBuyPage
            products={products}
            isOwnInstruments={true}
            onProductClick={handleItemClick}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default AccountInstrumentsListPage;
