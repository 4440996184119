import React, {Dispatch, SetStateAction} from 'react';
import placeholderImage from 'assets/placeholder-image.png';
import {useSetRecoilState} from 'recoil';
import {photoState} from 'states/Common/CommonState';

type photoHelperProps = {
  photoLink: {photo: string};
  customClassName?: string;
  type?: string;
  setIsPopUpVisible: Dispatch<SetStateAction<boolean>>;
};

export const UIClickImage: React.FC<photoHelperProps> = ({
  customClassName,
  photoLink,
  type,
  setIsPopUpVisible,
}) => {
  const setPhoto = useSetRecoilState(photoState);

  const url = photoLink.photo;
  const handleClick = () => {
    setPhoto(url);
    setIsPopUpVisible(true);
  };

  return (
    <div>
      <div onClick={handleClick}>
        <img
          alt='img'
          src={photoLink ? url : placeholderImage}
          className={`
            ${customClassName ? customClassName : 'h-44 w-36 my-12 mb-3 rounded-xl'} centered
          `}
        />
      </div>
    </div>
  );
};
