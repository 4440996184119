import {FavoriteInstrument} from 'interfaces/Instruments/Instrument.interface';
import {FavoriteJob} from 'interfaces/Jobs/Jobs.interface';
import {FavoriteProduct} from 'interfaces/Products/product.interface';
import {FavoriteService} from 'interfaces/Services/service.interface';

export interface User {
  firstName?: string;
  email?: string;
  lastName?: string;
  favourites?: {
    products: FavoriteProduct[];
    jobs?: FavoriteJob[];
    services: FavoriteService[];
    instruments: FavoriteInstrument[];
  };
  street?: string;
  city?: string;
  zipCode?: string;
  houseNumber?: string;
  phoneNumber?: string;
  birthDate?: string;
  jobTitle?: string;
  gender?: string;
  profilePicture?: string | any;
  id?: number;
  mine?: any;
  accountType?: 'PRIVATE' | 'COMPANY';
  areEmailNotificationsEnabled?: boolean;
}
export interface ShortUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: number;
}

export const UserDataDefaultValues = {
  name: '',
  lastName: '',
  street: '',
  city: '',
  zipCode: '',
  phone: '',
  birthDate: undefined,
  jobTitle: '',
  gender: '',
  profilePicture: undefined,
};
