import React, {useEffect, useState} from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import {funnel, chevronForward} from 'ionicons/icons';
import UILogo from 'components/UILogo/UILogo';
import UITopTextBar from 'components/UILabels/UITopTextBar';
import LoadingIndicator from 'components/UILoading/UILoadingIndicator';
import {Instrument} from 'interfaces/Instruments/Instrument.interface';
import UIButtonBack from 'components/UIButtons/UIButtonBack';
import UIButtonSearch from 'components/UIButtons/UIButtonSearch';
import sorting from 'assets/sorting.svg';
import {useParams} from 'react-router';
import UIFilter from 'components/UIFilter/UIFilter';
import {distanceCalculator} from 'utils/distanceCalculator';
import {instrumentsService} from 'api/instruments/instruments.service';
import InstrumentsList from './InstrumentsList';
import {geolocationState, refreshCounterState} from 'states/Common/CommonState';
import {useRecoilState, useRecoilValue} from 'recoil';
import {
  defaultDistanceMax,
  defaultDistanceMin,
  defaultPriceMax,
  defaultPriceMin,
} from 'utils/constants';
import UIResponsivePadding from 'components/UIResponsive/UIResponsivePadding';

const InstrumentsListPage = () => {
  const [originalInstruments, setOriginalInstruments] = useState<Instrument[]>();
  const [instruments, setInstruments] = useState<Instrument[]>();
  const {subCatId, catName, subCatName}: any = useParams();
  const [isFilterShown, setIsFilterShown] = useState(false);
  const [priceMin, setPriceMin] = useState(defaultPriceMin);
  const [priceMax, setPriceMax] = useState(defaultPriceMax);
  const [distanceMin, setDistanceMin] = useState(defaultDistanceMin);
  const [distanceMax, setDistanceMax] = useState(defaultDistanceMax);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [shouldRefreshData, setRefreshCount] = useRecoilState(refreshCounterState);
  const geolocation = useRecoilValue(geolocationState);
  const router = useIonRouter();

  useEffect(() => {
    setIsLoading(true);
    instrumentsService
      .getInstruments(subCatId)
      .then((response) => {
        response.data.results.forEach((instrument: Instrument) => {
          instrument.distance = distanceCalculator(instrument.lat, instrument.lng, geolocation);
        });
        setOriginalInstruments(response.data.results);
        setInstruments(
          response.data.results.sort((a: Instrument, b: Instrument) =>
            a.distance! > b.distance! ? 1 : -1
          )
        );
      })
      .finally(() => setIsLoading(false));
  }, [shouldRefreshData]);

  const handleFilter = () => {
    let tempData = originalInstruments;

    tempData = tempData?.filter((item) => {
      return (
        item.pricePerDay >= priceMin &&
        item.pricePerDay <= priceMax &&
        item.distance! >= distanceMin &&
        item.distance! <= distanceMax
      );
    });

    setInstruments(tempData);
  };

  const handleSort = (sortValue: string) => {
    getOrderBy(sortValue);
    // instrumentsService.getSortedInstruments(sortedBy!).then((response) => {
    // });
  };
  // will be handled by API
  const getOrderBy = (sortValue: string) => {
    switch (sortValue) {
      case 'Datum aufsteigend':
        const sorted0 = [
          ...instruments!.sort((a, b) =>
            new Date(a.dateAdded!).getTime() < new Date(b.dateAdded!).getTime() ? 1 : -1
          ),
        ];
        setInstruments(sorted0);
        return 'dateAdded';
      case 'Datum absteigend':
        const sorted01 = [
          ...instruments!.sort((a, b) =>
            new Date(a.dateAdded!).getTime() > new Date(b.dateAdded!).getTime() ? 1 : -1
          ),
        ];
        setInstruments(sorted01);
        return '-dateAdded';
      case 'Datum aufsteigend':
        const sorted = instruments!.sort((a, b) => (a.dateAdded > b.dateAdded ? 1 : -1));
        setInstruments(sorted);
        return 'dateAdded';
      case 'Datum absteigend':
        const sorted2 = instruments!.sort((a, b) => (a.dateAdded < b.dateAdded ? 1 : -1));
        setInstruments(sorted2);
        return '-dateAdded';
      case 'Preis aufsteigend':
        const sorted3 = [...instruments!.sort((a, b) => (a.pricePerDay > b.pricePerDay ? 1 : -1))];
        setInstruments(sorted3);
        return 'salary';
      case 'Preis absteigend':
        const sorted4 = [...instruments!.sort((a, b) => (a.pricePerDay < b.pricePerDay ? 1 : -1))];
        setInstruments(sorted4);
        return '-salary';
      case 'Entfernung aufsteigend':
        const sorted5 = [...instruments!.sort((a, b) => (a.distance! > b.distance! ? 1 : -1))];
        setInstruments(sorted5);
        return 'distance';
      case 'Entfernung absteigend':
        const sorted6 = [...instruments!.sort((a, b) => (a.distance! < b.distance! ? 1 : -1))];
        setInstruments(sorted6);
        return '-distance';
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
          <IonButtons slot='end'>
            <UIButtonSearch
              itemsType='instruments'
              category={catName === 'Werkzeug' ? '2' : '3'}
            />
          </IonButtons>
        </IonToolbar>
        <UITopTextBar
          leftComponent={
            <button
              title='button'
              onClick={() => setIsFilterShown(!isFilterShown)}
            >
              <IonIcon
                className='mt-1 ml-3 text-white'
                icon={funnel}
              />
            </button>
          }
          customTextClassName='absolute mt-3 left-10 transform'
          rightComponent={
            <div className='flex w-7/8 mr-2'>
              <IonSelect
                okText='Weiter'
                cancelText='Abbrechen'
                onIonChange={(e) => handleSort(e.detail.value)}
                interface='popover'
                placeholder='Sortieren'
                className='text-xs text-transparent w-8 -mr-8 z-10'
              >
                <IonSelectOption className='text-black'>Datum aufsteigend</IonSelectOption>
                <IonSelectOption className='text-black'>Datum absteigend</IonSelectOption>
                <IonSelectOption className='text-black'>Preis aufsteigend</IonSelectOption>
                <IonSelectOption className='text-black'>Preis absteigend</IonSelectOption>
                <IonSelectOption className='text-black'>Entfernung aufsteigend</IonSelectOption>
                <IonSelectOption className='text-black'>Entfernung absteigend</IonSelectOption>
              </IonSelect>
              <img
                alt='img'
                className='my-2 mr-3'
                src={sorting}
              />
            </div>
          }
        >
          <div className='text-white text-xs flex w-80 ml-2'>
            <p className='font-thin text-[11px]'>{catName} mieten</p>
            <IonIcon
              icon={chevronForward}
              className='mt-0.5 mx-1'
            />
            <p>{subCatName}</p>
          </div>
        </UITopTextBar>
      </IonHeader>
      {isFilterShown && (
        <UIFilter
          onFilter={handleFilter}
          setPriceMin={setPriceMin}
          setPriceMax={setPriceMax}
          setDistanceMin={setDistanceMin}
          setDistanceMax={setDistanceMax}
        />
      )}
      <IonContent>
        <UIResponsivePadding>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            instruments && (
              <InstrumentsList
                instruments={instruments}
                imageType='instruments'
                onInstrumentClick={(instrument: Instrument) => {
                  router.push(`/instrument/${catName}/${subCatName}/${instrument.id}`);
                  setRefreshCount((refreshCounter) => refreshCounter + 1);
                }}
              />
            )
          )}
        </UIResponsivePadding>
      </IonContent>
    </IonPage>
  );
};

export default InstrumentsListPage;
