import {IonIcon} from '@ionic/react';
import IconEuro from 'assets/IconEuro.svg';
// import RatingStars from 'pages/Common/RatingStars/RatingStars';
import React from 'react';
import {UIImage} from 'components/UIImages/UIImage';
import {FavoriteJob} from 'interfaces/Jobs/Jobs.interface';

type Props = {
  field: FavoriteJob;
  onOfferClick: Function;
};

export const FavoritesJobsListItem: React.FC<Props> = ({
  field,
  onOfferClick,
}) => {
  return (
    <div
      key={field.job.name! + field.job.id}
      onClick={() => onOfferClick(field.job.id!)}
    >
      <div className='rounded-xl border border-yellow-accent mx-6 my-3 bg-white drop-shadow-md'>
        <div className='h-8 bg-yellow-accent rounded-t-lg w-full flex justify-between '>
          <div className='flex justify-end w-full mr-3 text-white'>
            <IonIcon icon={IconEuro} className='h-3.5 mt-2' />
            <p className='pl-0.5 text-sm mt-1.5'>{field.job.salary} / h</p>
          </div>
        </div>
        <div className='flex ml-4 mb-3 font-sans'>
          <UIImage
            photoLink={field.job.photos.length > 0 && field.job.photos[0].photo}
             customClassName='mr-3 h-20 w-20 mt-3 mb-2 absolute'
            type='services'
          />
          <div className='text-gray-500 w-full ml-20'>
            <p className='text-gray-900 pl-2 text-md font-semibold mt-2'>
              {field.job.name}
            </p>
            <p className='pl-2 text-sm font-thin'>{field.job.location}, </p>
            {/* <div className='ml-2 mt-1.5 float-left'>
              <RatingStars />
            </div>
            <p className='text-xs float-left font-thin mt-1.5 mb-2 ml-2'>
              2 Bewertungen
            </p> */}
            <p className='text-xs clear-both font-thin ml-2 truncate'>
              {field.job.description}
            </p>
            <p className='text-xs mt-1 font-thin flex justify-end mr-3'>
              {field.createdAt.substring(0, 10)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
