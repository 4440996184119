import React from 'react';
import './UILoadingIndicator.css';

const UILoadingIndicator = () => {
  return (
    <div
      className={
        'fixed ' +
        'inset-0 ' +
        'bg-opacity-50 ' +
        'overflow-y-auto ' +
        'flex ' +
        'z-30 ' +
        'items-center ' +
        'justify-center ' +
        'h-full ' +
        'w-full'
      }
    >
      <div className='mt-3 text-center'>
        <div
          className={
            'mx-auto ' +
            'flex ' +
            'items-center ' +
            'justify-center ' +
            'h-24 w-24 ' +
            'rounded-full'
          }
        >
          <div className='loader'></div>
        </div>
      </div>
    </div>
  );
};

export default UILoadingIndicator;
