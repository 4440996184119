import React from 'react';
import {getCategoryIcon} from 'utils/categoryIconProvider';

interface Props {
  onClick?: any;
  name: string;
  id: number;
  alt: string;
}

const UIOrangeCategoryBox: React.FC<Props> = ({onClick, name, id, alt}) => {
  return (
    <div className='w-1/2 p-2' onClick={onClick}>
      <div className='border-2 border-orange-main rounded-xl shadow-lg shadow-orange-main'>
        <div className='h-[100px] flex justify-center items-center bg-orange-main rounded-t-xl
        dark:bg-[#F0A249]'>
          <img src={getCategoryIcon(id)} alt={alt} className='w-15 mt-4' />
        </div>
        <div
          className='h-16 rounded-b-xl flex items-center justify-center
            text-grey text-center text-sm font-bold dark:text-white'
        >
          {name}
        </div>
      </div>
    </div>
  );
};

export default UIOrangeCategoryBox;
