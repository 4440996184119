import client from 'api/common/client';
import {InstrumentRequest} from 'interfaces/Instruments/Instrument.interface';
import {createFormData} from 'utils/formDataHelper';

const getInstruments = async (subCatId: number) => {
  const response = await client.get(`/instruments?subcategory=${subCatId}`);
  return response;
};

const getAll = async () => {
  const response = await client.get('/instruments');
  return response;
};

const getCategories = () => {
  return client.get('products/categories');
};

const getSubcategories = (categoryId: string | number) => {
  if (categoryId == 1) return client.get(`instruments/subcategories`);
  return client.get(`products/subcategories`);
};

const search = async (q: string, category: string) => {
  const response = await client.get(`/instruments?q=${q}&category=${category}/`);
  return response.data.instruments;
};

const getDetails = async (instrumentId: number) => {
  const response = await client.get(`/instruments/${instrumentId}/`);
  return response;
};

const getSortedInstruments = async (sortedBy: string) => {
  const response = await client.get(`/instruments?orderBy=${sortedBy}/`);
  return response;
};

const addInstrument = async (data: InstrumentRequest) => {
  const formData = createFormData(data);
  const response = await client.post('/instruments/', formData);
  return response;
};

const updateInstrument = async (instrument: any, instrumentId: any) => {
  const formData = createFormData(instrument);
  const response = client.patch(`instruments/${instrumentId}`, formData);
  return response;
};

const rateInstrument = async (rate: object, instrumentId: number) => {
  const formData = createFormData(rate);
  const response = await client.post(`/instruments/${instrumentId}/rate`, formData);
  return response;
};

const commentInstrument = async (comment: object, instrumentId: number) => {
  const formData = createFormData(comment);
  const response = await client.post(`/instruments/${instrumentId}/comments/`, formData);
  return response;
};

const updatePhotoInstrument = async (data: any, instrumentId: number) => {
  const fd = new FormData();
  fd.append('photos', data);

  return client.patch(`/instruments/${instrumentId}/`, fd);
};

const deleteInstrument = async (id: number) => {
  const response = await client.delete(`/instruments/${id}`, {
    data: {id},
  });
  return response;
};

const addFavoriteInstrument = async (id: number) => {
  const response = await client.post(`/favourites/instrument/${id}`);
  return response;
};

const deleteFavoriteInstrument = async (id: number) => {
  const response = await client.delete(`/favourites/instrument/${id}`, {
    data: id,
  });
  return response;
};

const getAvailablePromotions = async (id: number) => {
  const response = await client.get(`/payments/promotions/`);
  return response;
};

const initializeChat = async (instrumentId: string) => {
  const response = await client.post(`/instruments/${instrumentId}/chat/`);
  return response;
};

export const instrumentsService = {
  getInstruments,
  getAll,
  getDetails,
  getCategories,
  getSubcategories,
  addInstrument,
  search,
  getSortedInstruments,
  addFavoriteInstrument,
  deleteFavoriteInstrument,
  getAvailablePromotions,
  deleteInstrument,
  updateInstrument,
  rateInstrument,
  commentInstrument,
  updatePhotoInstrument,
  initializeChat,
};
