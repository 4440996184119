import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import UIButtonBack from 'components/UIButtons/UIButtonBack';
import UITopTextBar from 'components/UILabels/UITopTextBar';
import UILogo from 'components/UILogo/UILogo';
import JobOfferForm from 'forms/Jobs/FormJobOffer';
import React from 'react';
const JobAddPage: React.FC = () => (
  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot='start'>
          <UIButtonBack />
        </IonButtons>
        <UILogo />
      </IonToolbar>
      <UITopTextBar>Jobinserat erstellen</UITopTextBar>
    </IonHeader>
    <IonContent>
      <div className='m-3'>
        <JobOfferForm />
      </div>
    </IonContent>
  </IonPage>
);

export default JobAddPage;
