import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import UITopTextBar from 'components/UILabels/UITopTextBar';
import UILogo from 'components/UILogo/UILogo';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import UIButtonBack from 'components/UIButtons/UIButtonBack';
import LoadingIndicator from 'components/UILoading/UILoadingIndicator';
import UIButtonSearch from 'components/UIButtons/UIButtonSearch';
import UIOrangeCategoryBox from 'components/UIContainer/UIOrangeCategoryBox';
import {instrumentsService} from 'api/instruments/instruments.service';
import UIResponsivePadding from 'components/UIResponsive/UIResponsivePadding';
import {Subcategory} from 'interfaces/Instruments/Instrument.interface';

const InstrumentsSubcategoriesToolsListPage: React.FC = () => {
  const {option} = useParams<{option: string}>();
  const [subCategories, setCategories] = useState<Subcategory[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const router = useIonRouter();

  const variant = option == 'loan' ? 'mieten' : 'kaufen';

  useEffect(() => {
    setIsLoading(true);
    instrumentsService
      .getSubcategories('1')
      .then((response) => {
        setCategories(response.data.results);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <IonButtons slot='end'>
            <UIButtonSearch
              itemsType={option == 'loan' ? 'instruments' : 'products'}
              category='1'
            />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>Werkzeug {variant}</UITopTextBar>
      </IonHeader>
      <IonContent>
        <UIResponsivePadding>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <div>
              <div className='flex flex-wrap mb-10'>
                {subCategories
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((category) => (
                    <UIOrangeCategoryBox
                      name={category.name}
                      id={category.id}
                      key={category.id}
                      alt={''}
                      onClick={() => {
                        if (option === 'loan') {
                          router.push(
                            `/instruments/subcategories/${category.id}/Werkzeug/${category.name}`,
                          );
                        } else if (option === 'buy') {
                          router.push(
                            `/products/subcategories/${category.id}/Werkzeug/${category.name}`,
                          );
                        }
                      }}
                    />
                  ))}
              </div>
            </div>
          )}
        </UIResponsivePadding>
      </IonContent>
    </IonPage>
  );
};

export default InstrumentsSubcategoriesToolsListPage;
