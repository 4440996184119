import {
  IonBadge,
  IonIcon,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import AccountDatail from 'pages/Account/AccountDetail/AccountDetail';
import FavoritesPage from 'pages/Common/Favourites/FavoritesPage';
import MapPage from 'pages/Common/Map/MapPage';
import InstrumentsListPage from 'pages/Instruments/InstrumentsList/InstrumentsListPage';
import JobListPage from 'pages/Jobs/JobsList/JobsListPage';
import ChatsListPage from 'pages/Messages/Chat/ChatList/ChatsListPage';
import ForumList from 'pages/Messages/Forum/ForumList/ForumList';
import {ServicesListPage} from 'pages/Services/ServicesList/ServicesListPage';
import {ServiceDetailPage} from 'pages/Services/ServiceDetail/ServiceDetailPage';
import InstrumentAddPage from 'pages/Instruments/InstrumentAdd/InstrumentAddPage';
import {map, star, person, chatbubbles, home} from 'ionicons/icons';
import React, {useState} from 'react';
import {Redirect, Route, useHistory} from 'react-router';
import AccountEditPage from 'pages/Account/AccountEdit/AccountEdit';
import ServiceAddPage from 'pages/Services/ServiceAdd/ServiceAddPage';
import HomePage from 'pages/Common/Home/HomePage';
import ShopPage from 'pages/Common/Shop/ShopPage';
import PageCommunity from 'pages/Common/Community/CommunityPage';
import SearchPage from 'pages/Common/Search/SearchPage';
import ServicesAndJobsPage from 'pages/Common/Services&Jobs/Services&JobsPage';
import JobAddPage from 'pages/Jobs/JobAdd/JobAddPage';
import JobDetail from 'pages/Jobs/JobDetail/JobDetailPage';
import {ChatDetail} from 'pages/Messages/Chat/ChatDetail/ChatDetail';
import AccountServicesListPage from 'pages/Account/AccountServicesList/AccountServicesListPage';
import PromotionPaymentPage from 'pages/Promotions/PromotionPayment/PromotionPaymentPage';
import InstrumentsSubcategoriesMachinesListPage
from 'pages/Instruments/InstrumentsSubcategoriesList/InstrumentsSubcategoriesMachinesListPage';
import {InstrumentDetailPage} from 'pages/Instruments/InstrumentDetail/InstrumentDetailPage';
import AccountInstrumentsListPage from
'pages/Account/AccountInstrumentsList/AccountInstrumentsListPage';
import ProductsAddPage from 'pages/Products/addProducts/ProductsAddPage';
import {DetailProductsPage} from 'pages/Products/detailProducts/DetailProductsPage';
import ReportPage from 'pages/Common/Report/ReportPage';
import {PromotionsCostsListPage} from
'pages/Promotions/PromotionsCostsList/PromotionsCostsListPage';
import FormPost from 'forms/Forum/PostForm';
import DIYPage from 'pages/Messages/DIY/DIYList/DIYPage';
import DIYDetailPage from 'pages/Messages/DIY/DIYDetail/DIYDetailPage';
import PostForumDetailsPage from 'pages/Messages/Forum/ForumDetail/PostForumDetailsPage';
import PromotionsItemsListPage from 'pages/Promotions/PromotionsItemsList/PromotionsItemsListPage';
import ProductsListPage from 'pages/Products/ProductsList/ProductsListPage';
import {PurchasePage} from 'pages/Common/Purchase/PurchasePage';
import InstrumentsSubcategoriesToolsListPage from
'pages/Instruments/InstrumentsSubcategoriesList/InstrumentsSubcategoriesToolsListPage copy';
import {useRecoilValue} from 'recoil';
import {newMessagesState} from 'states/Messages/messageState';
import AccountPleaseSignInPage from 'pages/Account/AccountPleaseSignIn/AccountPleaseSignInPage';
import SearchMapPage from 'pages/Common/Search/SearchMapPage';
import PromotionsPaymentResultPage from
'pages/Promotions/PromotionPayment/PromotionsPaymentResultPage';
import EmailNotificationsEdit from 'pages/Account/EmailNotifications/EmailNotificationsEdit';

const UserRoutes: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>('home');
  const anyNewMessages = useRecoilValue(newMessagesState);
  const history = useHistory();

  if (history.location.pathname == '/home' && selectedTab !== 'home') {
    setSelectedTab('home');
  }

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route path='/chat'>
          <ChatsListPage />
        </Route>
        <Route path='/forum'>
          <ForumList />
        </Route>
        <Route path='/map'>
          <MapPage />
        </Route>
        <Route path='/favorites'>
          <FavoritesPage />
        </Route>
        <Route path='/profile'>
          <AccountDatail />
        </Route>
        <Route path='/profile-edit'>
          <AccountEditPage />
        </Route>
        <Route path='/instruments/categories/1/:option'>
          <InstrumentsSubcategoriesToolsListPage />
        </Route>
        <Route path='/instruments/categories/2/:option'>
          <InstrumentsSubcategoriesMachinesListPage />
        </Route>
        <Route path='/products/subcategories/:subCatId/:catName/:subCatName'>
          <ProductsListPage />
        </Route>
        <Route path='/instruments/subcategories/:subCatId/:catName/:subCatName'>
          <InstrumentsListPage />
        </Route>
        <Route path='/instruments-my/:listType'>
          <AccountInstrumentsListPage />
        </Route>
        <Route path='/instruments-add'>
          <InstrumentAddPage />
        </Route>
        <Route path='/products-add'>
          <ProductsAddPage />
        </Route>
        <Route path='/instrument/:catName/:subCatName/:instrumentId'>
          <InstrumentDetailPage />
        </Route>
        <Route path='/product/:catName/:subCatName/:instrumentId'>
          <DetailProductsPage />
        </Route>
        <Route path='/promotions'>
          <PromotionsItemsListPage />
        </Route>
        <Route path='/offers'>
          <ServicesListPage />
        </Route>
        <Route path='/offers-my'>
          <AccountServicesListPage />
        </Route>
        <Route path='/offers-add'>
          <ServiceAddPage />
        </Route>
        <Route path='/service/:offerId'>
          <ServiceDetailPage />
        </Route>
        <Route path='/search/:itemsType/:category'>
          <SearchPage />
        </Route>
        <Route path='/search-map'>
          <SearchMapPage />
        </Route>
        <Route path='/jobs'>
          <JobListPage />
        </Route>
        <Route path='/jobs-add'>
          <JobAddPage />
        </Route>
        <Route path='/job/:jobId'>
          <JobDetail />
        </Route>
        <Route path='/jobs-and-services'>
          <ServicesAndJobsPage />
        </Route>
        <Route path='/promote/:type/:id'>
          <PromotionsCostsListPage />
        </Route>
        <Route path='/purchase/:type/:id'>
          <PurchasePage />
        </Route>
        <Route path='/loan/instrument/:instrumentId'>
          <InstrumentDetailPage />
        </Route>
        <Route path='/shop'>
          <ShopPage />
        </Route>
        <Route path='/community'>
          <PageCommunity />
        </Route>
        <Route path='/forum'>
          <ForumList />
        </Route>
        <Route path='/forum-add'>
          <FormPost />
        </Route>
        <Route path='/forum-post/:id'>
          <PostForumDetailsPage />
        </Route>
        <Route path='/report/:reportedElement/:reportedElementId'>
          <ReportPage />
        </Route>
        <Route path='/message/:type/:id/:itemName/:conversationId'>
          <ChatDetail />
        </Route>
        <Route path='/diys'>
          <DIYPage />
        </Route>
        <Route path='/sign-in'>
          <AccountPleaseSignInPage />
        </Route>
        <Route path='/diy/:id'>
          <DIYDetailPage />
        </Route>
        <Route path={'/email-notifications'}>
          <EmailNotificationsEdit />
        </Route>
        <Route path='/payment-result/:paymentId'>
          <PromotionsPaymentResultPage />
        </Route>
        <Route path='/payment/:paymentId/:checkoutId'>
          <PromotionPaymentPage />
        </Route>
        <Route path='/' exact={true}>
          <Redirect to='/home' />
        </Route>
        <Route path='/login' render={() => <Redirect to='/home' />} exact />
        <Route path='/home'>
          <HomePage />
        </Route>
      </IonRouterOutlet>
      <IonTabBar
        onIonTabsWillChange={(e) => setSelectedTab(e.detail.tab)}
        className='bg-gray-500 lg:hidden dark:bg-[#5B6267]'
        slot='bottom'
      >
        <IonTabButton
          className='bg-gray-500 text-white dark:text-[#CED0D1]'
          tab='home'
          href='/home'
        >
          <IonIcon
            color={selectedTab === 'home' ? 'primary' : ''}
            icon={home}
          />
        </IonTabButton>
        <IonTabButton
          className='bg-gray-500 text-white dark:text-[#CED0D1]'
          tab='community'
          href='/community'
        >
          <IonIcon
            color={selectedTab === 'community' ? 'primary' : ''}
            icon={chatbubbles}
          />
          {anyNewMessages && <IonBadge className='bg-red-500'/>}
        </IonTabButton>
        <IonTabButton className='bg-gray-500 text-white dark:text-[#CED0D1]' tab='map' href='/map'>
          <IonIcon color={selectedTab === 'map' ? 'primary' : ''} icon={map} />
        </IonTabButton>
        <IonTabButton
          className='bg-gray-500 text-white dark:text-[#CED0D1]'
          tab='favorites'
          href='/favorites'
        >
          <IonIcon
            color={selectedTab === 'favorites' ? 'primary' : ''}
            icon={star}
          />
        </IonTabButton>
        <IonTabButton
          className='bg-gray-500 text-white dark:text-[#CED0D1]'
          tab='profile'
          href='/profile'
        >
          <IonIcon
            color={selectedTab === 'profile' ? 'primary' : ''}
            icon={person}
          />
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};
export default UserRoutes;
