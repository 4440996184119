import React from 'react';
import {Instrument} from 'interfaces/Instruments/Instrument.interface';
import MapMarker from './MapMarker';
import {Service} from 'interfaces/Services/service.interface';
import {Job} from 'interfaces/Jobs/Jobs.interface';
import {Product} from 'interfaces/Products/product.interface';

type MapPinesProps = {
  instruments?: Instrument[];
  services?: Service[];
  jobs?: Job[];
  products?: Product[];
  selectedPlaceHandler: Function;
  type: string;
};
const MapPines: React.FC<MapPinesProps> = ({
  products,
  instruments,
  jobs,
  services,
  selectedPlaceHandler,
  type,
}) => {
  return (
    <div>
      {instruments &&
        instruments.map((instrument) => (
          <div key={Math.random()}>
            {instrument.lat && (instrument.lat = parseFloat(instrument.lat))}
            {instrument.lng && (instrument.lng = parseFloat(instrument.lng))}
            <MapMarker
              key={Math.random()}
              lat={instrument.lat}
              lng={instrument.lng}
              selectedPlaceHandler={selectedPlaceHandler}
              id={instrument.id}
              type={type}
              catName={instrument.category.name}
              subCatName={instrument.subcategory.name}
              item={instrument}
            />
          </div>
        ))}
      {products &&
        products.map((product) => (
          <div key={Math.random()}>
            {product.lat && (product.lat = parseFloat(product.lat))}
            {product.lng && (product.lng = parseFloat(product.lng))}
            <MapMarker
              key={Math.random()}
              lat={product.lat}
              lng={product.lng}
              selectedPlaceHandler={selectedPlaceHandler}
              id={product.id}
              type={type}
              catName={product.category.name}
              subCatName={product.subcategory.name}
              item={product}
            />
          </div>
        ))}
      {services &&
        services.map((service) => (
          <div key={Math.random()}>
            {service.lat && (service.lat = parseFloat(service.lat))}
            {service.lng && (service.lng = parseFloat(service.lng))}
            <MapMarker
              key={Math.random()}
              lat={service.lat}
              lng={service.lng}
              selectedPlaceHandler={selectedPlaceHandler}
              id={service.id}
              type={type}
              item={service}
            />
          </div>
        ))}
      {jobs &&
        jobs.map((job) => (
          <div key={Math.random()}>
            {job.lat && (job.lat = parseFloat(job.lat))}
            {job.lng && (job.lng = parseFloat(job.lng))}
            <MapMarker
              key={Math.random()}
              lat={job.lat}
              lng={job.lng}
              selectedPlaceHandler={selectedPlaceHandler}
              id={job.id}
              type={type}
              item={job}
            />
          </div>
        ))}
    </div>
  );
};

export default MapPines;
