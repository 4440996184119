import React from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import UIButtonBack from 'components/UIButtons/UIButtonBack';
import UILogo from 'components/UILogo/UILogo';
import SearchComponent from 'pages/Common/Search/SearchComponent';
import {useParams} from 'react-router';

export default function SearchPage() {
  const {itemsType, category}: any = useParams();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <SearchComponent itemsType={itemsType} category={category == 0 ? '' : category} />
      </IonContent>
    </IonPage>
  );
}
