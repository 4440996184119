import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {userService} from 'api/users/users.service';
import {UserDataDefaultValues, User} from 'interfaces/Users/user.interface';
import {dateHandler} from '../../../utils/dateHandler';
import AddPhoto from '../../../components/UIAddPhoto/UIAddPhoto';
import UIInput from '../../../components/UIInputs/UIInput';
import UIButton from 'components/UIButtons/UIYellowButton';
import {useSetRecoilState} from 'recoil';
import {accountState} from 'states/Account/accountState';
import {refreshCounterState} from 'states/Common/CommonState';
import {IonSelect, IonSelectOption} from '@ionic/react';

function ProfileEdit() {
  const setAccountState = useSetRecoilState(accountState);
  const setRefreshCounter = useSetRecoilState(refreshCounterState);

  const [message, setMessage] = useState('');
  const [userData, setUserData] = useState<User>(UserDataDefaultValues);
  const [isFill, setIsFill] = useState(userData.birthDate ? true : false);
  const [gender, setGender] = useState();

  const {handleSubmit, register, setValue, control} = useForm();

  useEffect(() => {
    userService.getUserData().then((response) => {
      setUserData({
        ...response.data,
        birthDate: dateHandler(response.data.birthDate, 'en-CA'),
        phoneNumber: response.data.phoneNumber.slice(3),
      });
      setGender(response.data.gender);
      setAccountState(response.data);
    });
  }, [message]);

  useEffect(() => {
    for (const [key, value] of Object.entries(userData)) {
      setValue(key, value);
    }
  }, [userData]);

  const onSubmit = (data: User) => {
    if (typeof(data.profilePicture) === 'string') delete data.profilePicture;

    data.phoneNumber = '+43' + data.phoneNumber;

    if (gender == '') {
      data.gender = gender;
    } else delete data.gender;

    userService.updateUser(data).then((res) => {
      setMessage('Erflog!');
      setUserData(res.config.data);
      setRefreshCounter((val) => val + 1);
    });
  };

  const translateGendersToGer = (gender: string) => {
    if (gender === 'MAN') {
      return 'Herr';
    }
    if (gender === 'WOMAN') {
      return 'Frau';
    }
    return 'Herr/Frau';
  };

  return (
    <form
      className='pb-3'
      onSubmit={handleSubmit(onSubmit)}>
      <div className='mx-4 bg-yellow-accent mt-3 pt-2 rounded-lg text-black mb-5
      dark:bg-[#F0A249] dark:text-white'>
        <div
          className='bg-yellow-slight border-2 border-yellow-accent rounded-b-lg
         dark:border-[#F0A249]'>
          <div className='flex flex-row dark:bg-[#393939]'>
            <div className='flex items-center mt-4 pl-6 dark:bg-[#393939]'>
              <AddPhoto
                control={control}
                name='profilePicture'
              />
            </div>
            <div className='flex flex-col space-y-4 mx-5 mt-4 '>
              <div
                className='w-full h-6 p-4 flex items-center justify-center
                rounded-xl border-orange-main border text-orange-main '>
                <IonSelect
                  okText='Weiter'
                  cancelText='Abbrechen'
                  className='text-black dark:text-white'
                  placeholder={translateGendersToGer(gender!)}
                  onIonChange={(e) => setGender(e.detail.value)}>
                  <IonSelectOption value={'MAN'}>Herr</IonSelectOption>
                  <IonSelectOption value={'WOMAN'}>Frau</IonSelectOption>
                </IonSelect>
              </div>
              <UIInput
                required={'required'}
                customClassName='border-b-2 border-gray-400 w-full bg-white pl-2 dark:bg-[#393939] '
                placeholder='Name'
                name='firstName'
                register={register}
              />
              <UIInput
                customClassName='border-b-2 border-gray-400 w-full bg-white pl-2 dark:bg-[#393939] '
                placeholder='Nachname'
                register={register}
                name='lastName'
              />
            </div>
          </div>
          <div className='flex flex-col space-y-4 dark:bg-[#393939]'>
            <div className='text-sm text-gray-500 mx-3 pt-4'>{userData.email}</div>
            <UIInput
              customClassName='border-b-2 border-gray-400 w-full bg-white pl-2 mt-2
              dark:bg-[#393939] '
              defaultValue={userData.street}
              placeholder='Straße'
              name='street'
              register={register}
            />
            <div className='flex min-w-fit'>
              <UIInput
                customClassName='border-b-2 border-gray-400 w-2/3 bg-white pl-2 dark:bg-[#393939] '
                defaultValue={userData.city}
                placeholder='Adresse'
                name='city'
                register={register}
              />
              <UIInput
                customClassName='border-b-2 border-gray-400 w-1/3 bg-white mr-6 dark:bg-[#393939] '
                defaultValue={userData.zipCode}
                placeholder='PLZ'
                name='zipCode'
                register={register}
              />
            </div>
            <div
              className={`flex border-b-2 border-gray-400 pl-2 ${
                userData.birthDate || isFill ? 'text' : 'text-gray-400'
              } dark:text-white`}>
              Geburtsdatum:
              <UIInput
                customClassName='w-full bg-white pl-10 dark:bg-[#393939] '
                type='date'
                defaultValue={userData.birthDate}
                placeholder='birthDate'
                min='1922-01-01'
                max='2079-12-31'
                name='birthDate'
                register={register}
                onChange={(_: any) => setIsFill(true)}
              />
            </div>
            <UIInput
              customClassName='border-b-2 border-gray-400 w-full bg-white pl-2 dark:bg-[#393939] '
              defaultValue={userData.phoneNumber}
              placeholder='0660 1234567'
              name='phoneNumber'
              register={register}
              isNumberPrefix={true}
            />
            <UIInput
              defaultValue={userData.jobTitle}
              customClassName='border-b-2 border-gray-400 w-full bg-white mb-7 pl-2
              dark:bg-[#393939] '
              placeholder='Optional Beruf (Jobsuche /Profil)'
              name='jobTitle'
              register={register}
            />
            <p className='text-yellow-accent text-center font-bold mb-5 dark:bg-[#393939] '>
              {message}
            </p>
          </div>
        </div>
      </div>
      <div className='flex drop-shadow-lg flex-col '>
        <UIButton text='Profil speichern' />
      </div>
      <hr className='h-0.5 mx-4 bg-gray-300 mt-5 dark:bg-gray-400' />
    </form>
  );
}

export default ProfileEdit;
