import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar} from '@ionic/react';
import UIButtonBack from 'components/UIButtons/UIButtonBack';
import UILogo from 'components/UILogo/UILogo';
import {postsService} from 'api/forum/forum.service';
import {BASE_URL} from 'utils/constants';
import {dateHandler} from 'utils/dateHandler';
import UITopTextBar from 'components/UILabels/UITopTextBar';
import CreateComment from 'components/UIComments/CreateComment';
import LineColored from 'forms/Instruments/LineColored';
import CommentBox from 'components/UIComments/CommentBox';
import {ForumPostInterface} from 'interfaces/Posts/ForumPost.interface';
import {useRecoilValue} from 'recoil';
import {refreshCounterState} from 'states/Common/CommonState';
import avatar from 'assets/avatar_placeholder.png';

const PostForumDetailsPage = () => {
  const {id: id} = useParams<{id: string}>();

  const shouldRefreshData = useRecoilValue(refreshCounterState);

  const [post, setPost] = useState<ForumPostInterface>();
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    postsService.getPost(id).then((res) => {
      setPost(res.data);
    });
  }, [shouldRefreshData]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>Forum</UITopTextBar>
      </IonHeader>
      <IonContent>
        {post && (
          <div>
            <div
              className='rounded-xl border
            border-yellow-accent mx-3 mt-2 mb-4 bg-white font-sans dark:bg-[#393939]'
            >
              <div
                className='h-8 bg-yellow-accent rounded-t-lg
          w-full flex justify-between text-xs px-3 dark:bg-[#F0A249]'
              >
                <div className='my-1 flex justify-around'>
                  {post.user.profilePicture ? (
                    <img
                      className='h-[30px] -mt-1 -ml-1 mr-1 rounded-lg'
                      src={`${BASE_URL}media/${post.user.profilePicture}`}
                    />
                  ) : (
                    <img
                      className='rounded-lg mr-1'
                      src={avatar}
                    />
                  )}
                  <b className='mt-1 text-white'>{post.user.firstName}</b>
                </div>
                <p className='mt-2 text-white'>{dateHandler(post.dateAdded)}</p>
              </div>
              <p className='text-black pl-2 text-sm pt-2 font-bold dark:text-white'>{post.title}</p>
              <p className='text-gray-500 pl-2 text-xs my-4 dark:text-white'>{post.description}</p>
            </div>
            <div className='mx-3'>
              <CreateComment
                count={count}
                setCount={setCount}
                id={post.id}
                type='post'
              />
            </div>
            <LineColored
              color='yellow'
              colorIntensity='accent'
            />
            {post.comments.map((comment) => (
              <CommentBox
                key={comment.id}
                comment={comment}
              />
            ))}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default PostForumDetailsPage;
