import React from 'react';
import {IonPage} from '@ionic/react';

import AppLoginBackground from 'pages/Account/AccountLogin/AccountLoginBackground';
import BForm from 'forms/Common/BForm/BForm';
import {formRecoveryPassword} from 'forms/Account/FormPassword/FormRecoveryPassword';

import logo from 'assets/logo.png';
import background from 'assets/background.png';

type Props = {
  isLoading: boolean;
  onSubmit: Function;
  errors: any;
};

const AccountRecoveryPasswordPage = ({isLoading, onSubmit, errors}: Props) => {
  return (
    <IonPage className='bg-gray-200'>
      <div className='lg:flex lg:justify-center'>
        <div className='h-screen bg-gray-200 lg:w-1/3'>
          <img
            src={background}
            className='absolute w-screen h-screen z-0 blur-sm lg:hidden'
          />
          <div className='flex justify-center h-2/6 flex-col'>
            <div className='h-1/6'></div>
            <div className='flex justify-center'>
              <img src={logo} className='w-64 h-28 z-20' />
            </div>
          </div>
          <AppLoginBackground>
            <div>
              {isLoading && <p>Loading...</p>}
              <p className='text-xl mt-4 mx-3 font-bold'>
                Passwort Zurücksetzen
              </p>
              <p className='text-sm text-gray-600 mx-3'>
                Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort
                zurückzusetzen
              </p>
              <div className='mx-3 mb-5'>
                <BForm
                  btnMargin={1}
                  btnText='Schicken'
                  fields={formRecoveryPassword}
                  preloadedData={{}}
                  submit={onSubmit}
                  formErrors={errors}
                />
              </div>
            </div>
          </AppLoginBackground>
        </div>
      </div>
    </IonPage>
  );
};

export default AccountRecoveryPasswordPage;
