import {Browser} from '@capacitor/browser';
import {isPlatform} from '@ionic/react';
import {
  applyPromoCode,
  deleteCart,
  getCart,
  promote,
} from 'api/promotions/promotions.service';
import UIYellowButton from 'components/UIButtons/UIYellowButton';
import {UIImage} from 'components/UIImages/UIImage';
import UILoadingIndicator from 'components/UILoading/UILoadingIndicator';
import LineColored from 'forms/Instruments/LineColored';
import {Instrument} from 'interfaces/Instruments/Instrument.interface';
import {Job} from 'interfaces/Jobs/Jobs.interface';
import {Product} from 'interfaces/Products/product.interface';
import {Service} from 'interfaces/Services/service.interface';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {useSetRecoilState} from 'recoil';
import {refreshCounterState} from 'states/Common/CommonState';
import {getService} from '../PromotionsCostsList/PromotionsCostsList';

type Props = {
  type: string;
};

const PromotionsCart: React.FC<Props> = ({type}) => {
  const [cartData, setCartData] = useState<any>();
  const [itemData, setItemData] = useState<Instrument | Service | Job | Product>();
  const [promoCode, setPromoCode] = useState<string>();
  const [responseText, setReponseText] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const setRefreshCounter = useSetRecoilState(refreshCounterState);
  const service = getService(type);
  const router = useHistory();

  useEffect(() => {
    setLoading(true);
    getCart()
      .then((response) => {
        setCartData(response.data);
        service.getDetails(response.data[type][0].id).then((res) => {
          setItemData(res.data);
        });
      })
      .finally(() => setLoading(false));
  }, [responseText]);

  const getNameBasedOnType = (object: any) => {
    if (object.name) {
      return object.name;
    };
    return object.name;
  };

  const handleDeleteCart = () => {
    deleteCart().then(() => {
      router.push('/');
    });
  };

  const handleUsePromoCode = () => {
    promoCode &&
      applyPromoCode(promoCode)
        .then((res) => {
          setReponseText('Aktionscode angewendet!');
        })
        .catch(() => setReponseText('Ungültiger Promo-Code!'));
  };

  const handlePaymentClick = async () => {
    setLoading(true);
    try {
      await promote(cartData.id).then((res) => {
        setRefreshCounter((prev) => prev + 1);
        const path = `/payment/${res.id}/${res.checkoutId}`;
        if (isPlatform('ios') || isPlatform('android')) {
          router.replace('/');
          Browser.open({url: `https://app.wubmal.com${path}`});
          return;
        }

        router.push(path);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='h-full'>
      {loading ? (
        <UILoadingIndicator />
      ) : cartData && itemData && cartData[type][0].id ? (
        <div className='border-t-4 border border-yellow-accent mx-4 my-2 rounded-lg'>
          <p className='text-center font-bold'>Zusammenfassung</p>
          <div className='flex mb-2 justify-center'>
            {itemData.photos && itemData.photos.length > 0 && (
              <UIImage
                customClassName='w-20 h-20 rounded-lg'
                photoLink={itemData.photos[0].photo}
                type={type}
              />
            )}
            <div className='text-black text-[17px] mt-7 ml-2'>
              <b>{getNameBasedOnType(itemData)}</b>
            </div>
          </div>
          <div className='mx-4 mt-2'>
            <LineColored color='yellow' colorIntensity='accent' />
          </div>
          <div className='mx-2 flex justify-between text-sm mt-2'>
            <p>Option zum Hinzufügen</p>
            <p className='font-bold'>
              {cartData[type][0].issuedPromotedPackage
                ? cartData[type][0].issuedPromotedPackage
                : '-'}
            </p>
          </div>
          <div className='mx-2 flex justify-between text-sm mt-2'>
            <p>Anzeigeoption hervorheben</p>
            <p className='font-bold'>
              {cartData[type][0].promotedPackage
                ? cartData[type][0].promotedPackage
                : '-'}
            </p>
          </div>
          {type !== 'products' && (
            <div className='mx-2 flex justify-between text-sm mt-2'>
              <p>Option zum Logo einstellen bie Maps</p>
              <p className='font-bold'>
                {cartData[type][0].mapPromotedPackage
                  ? cartData[type][0].mapPromotedPackage
                  : '-'}
              </p>
            </div>
          )}
          {type === ('services' || 'jobs') && (
            <div className='mx-2 flex justify-between text-sm mt-2'>
              <p>Option zum Benachrichtigungen</p>
              <p className='font-bold'>
                {cartData[type][0].notificationPromotedPackage
                  ? cartData[type][0].notificationPromotedPackage
                  : '-'}
              </p>
            </div>
          )}
          <div className='mx-4 mt-2'>
            <LineColored color='yellow' colorIntensity='accent' />
          </div>
          <div className='mx-2 flex justify-between text-sm mt-2'>
            <p>Nettopreis</p>
            <p className='font-bold'>{cartData.netAmount}</p>
          </div>
          <div className='mx-2 flex justify-between text-sm mt-2'>
            <p>MwSt.-Wert</p>
            <p className='font-bold'>{cartData.vatAmount}</p>
          </div>
          <div className='mx-2 flex justify-between text-sm mt-2'>
            <p>MwSt.-Prozentsatz</p>
            <p className='font-bold'>{cartData.vatRate}</p>
          </div>
          <div className='mx-2 flex justify-between text-sm mt-2'>
            <p>Bruttopreis</p>
            <p className='font-bold'>{cartData.grossAmount}</p>
          </div>
          <div className='mx-4 mt-2'>
            <LineColored color='yellow' colorIntensity='accent' />
          </div>
          <p className='font-bold text-sm ml-5 mb-2'>Haben Sie Promo-Codes?</p>
          <div className='flex justify-center'>
            <input
              onChange={(v) => setPromoCode(v.target.value)}
              className='rounded-lg p-1 border border-yellow-accent'
            />
            <UIYellowButton
              text='Anwenden'
              customClassName='px-3'
              onClick={handleUsePromoCode}
            />
          </div>
          {responseText && (
            <p
              className={`text-xs text-center mt-2 ${
                responseText[0] == 'A' ? 'text-green-500' : 'text-red-500'
              }`}
            >
              {responseText}
            </p>
          )}
          <div className='mx-4 mt-5'>
            <LineColored color='yellow' colorIntensity='accent' />
          </div>
          <div className='flex mt-20 mb-5'>
            <div className='w-1/2'>
              <UIYellowButton
                text='Einkaufswagen löschen'
                onClick={handleDeleteCart}
              />
            </div>
            <div className='w-1/2 self-center'>
              <UIYellowButton text='Zahlen' onClick={handlePaymentClick} />
            </div>
          </div>
        </div>
      ) : (
        <div className='text-center mt-3 mx-3'>
          Anscheinend haben Sie diesen Artikel nicht in Ihrem Einkaufswagen.
        </div>
      )}
    </div>
  );
};

export default PromotionsCart;
