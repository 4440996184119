import {CommentInterface} from 'interfaces/Comments/Comments.interface';
import React from 'react';
import {BASE_URL} from 'utils/constants';
import {dateHandler} from 'utils/dateHandler';
import avatarPlaceholder from 'assets/avatar_placeholder.png';

type Props = {
  comment: CommentInterface;
};

const CommentBox: React.FC<Props> = ({comment}) => {
  const date = dateHandler(comment.dateAdded, ' ', true);

  return (
    <div
      className='mx-5 rounded-lg border mt-2 border-t-[5px]
    border-yellow-accent text-gray-500 pl-2 pb-2 mb-5'
    >
      <div className='flex justify-between'>
        <div className='flex mt-2'>
          <img
            src={
              comment.user.profilePicture
                ? `${BASE_URL + comment.user.profilePicture}`
                : avatarPlaceholder
            }
            className='h-6 w-6 rounded-lg'
          />
          <p className='font-bold text-xs mt-1.5 ml-1 text-gray-800'>
            {comment.user.firstName}
          </p>
        </div>
        <p className='text-[11px] text-gray-700 mt-3 mr-1'>{date}</p>
      </div>
      <p className='text-gray-500 text-[11px] mt-1 font-sans ml-1 mr-2'>
        {comment.comment}
      </p>
    </div>
  );
};

export default CommentBox;
