import React from 'react';
import {IonIcon} from '@ionic/react';
import {Job} from 'interfaces/Jobs/Jobs.interface';
import {logoEuro} from 'ionicons/icons';
import {UIImage} from 'components/UIImages/UIImage';

export interface JobsProps {
  jobs: Job[];
  isOwnJob: boolean;
  isPromoted?: boolean;
  onDelete?: (id: any) => void;
  onJobClick: Function;
  onContackClick?: () => void;
  type?: string;
}

export const JobsList = ({
  jobs,
  isOwnJob,
  isPromoted,
  onJobClick,
  onDelete,
  onContackClick: onConctactClick,
  type,
}: JobsProps) => (
  <div className='my-5 space-y-5 z-10'>
    {jobs.map((job) => (
      <div
        key={job.name! + job.id}
        onClick={() => onJobClick(job.id!)}
        className='w-[90%] md:w-2/3 max-w-screen-md mx-auto'
      >
        <div
          className={`${
            job.promoted
              ? 'shadow-custom shadow-orange-promoted border rounded-3xl border-orange-promoted'
              : 'border rounded-3xl border-yellow-accent'
          } bg-white dark:bg-[#393939]  drop-shadow-md`}
        >
          <div>
            <UIImage
              type={type}
              photoLink={job.photos.length > 0 && job.photos[0].photo}
              customClassName='h-20 w-full rounded-t-3xl'
            />
            <div className='flex justify-end mr-3'>
              <div className='bg-yellow-accent text-white absolute p-2 top-16 rounded-2xl flex'>
                <IonIcon icon={logoEuro} className='text-sm' />
                <p className='text-xs'>{job.salary}</p>
              </div>
            </div>
          </div>
          <div>
            <div className='mt-2'>
              <p className='text-gray-600 text-md font-medium ml-5 dark:text-white'>
                {job.jobOffer}
              </p>
              <p className='text-gray-600 text-sm ml-7 dark:text-white'>
                {job.companyName}
              </p>
              <div className='flex justify-between'>
                <p className='text-gray-600 text-[12px] ml-7 mb-4 dark:text-gray-300'>
                  {job.location}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
);
