import {Storage} from '@capacitor/storage';
import {LoginRequest} from 'interfaces/Account/LoginRequest.interface';
import {BehaviorSubject} from 'rxjs';
import client from 'api/common/client';
import {removetMessagesFromStorage} from 'api/chat/chat.service';

const accessToken = new BehaviorSubject('');
const refreshToken = new BehaviorSubject('');
const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';

const login = async (data: LoginRequest) => {
  return client
    .post('/auth/login', data)
    .then((res) => {
      setAccessToken(res.data.access);
      setRefreshToken(res.data.refresh);
  });
};

const refreshAccessToken = async () => {
  await setRefreshTokenFromStorage();

  const data = {refresh: refreshToken.value};

  if (!data.refresh) {
    logout();
  }

  return client
      .post('/auth/refresh', data)
      .then((res) => {
        setAccessToken(res.data.access);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          console.log(err.response.status);
          logout();
        }
      });
};

const setAccessToken = (token: string) => {
  token && (
  Storage.set({key: ACCESS_TOKEN_KEY, value: token}),
  accessToken.next(token)
  );
};

const setRefreshToken = (token: string) => {
  token && (
  Storage.set({key: REFRESH_TOKEN_KEY, value: token}),
  refreshToken.next(token)
  );
};

const setAccessTokenFromStorage = async () => {
  const {value} = await Storage.get({key: ACCESS_TOKEN_KEY});

  if (value) {
    accessToken.next(value);
  }
};

const setRefreshTokenFromStorage = async () => {
  const {value} = await Storage.get({key: REFRESH_TOKEN_KEY});

  if (value) {
    refreshToken.next(value);
  }
};

const logout = () => {
  accessToken.next('');
  refreshToken.next('');
  Storage.remove({key: ACCESS_TOKEN_KEY});
  Storage.remove({key: REFRESH_TOKEN_KEY});
  removetMessagesFromStorage();
};

export const authService = {
  login,
  logout,
  refreshAccessToken,
  setAccessTokenFromStorage,
  setRefreshTokenFromStorage,
  currentToken: accessToken.asObservable(),
  get tokenValue() {
    return accessToken.value;
  },
};
