import {Instrument} from 'interfaces/Instruments/Instrument.interface';
import {Job} from 'interfaces/Jobs/Jobs.interface';
import {Product} from 'interfaces/Products/product.interface';
import {Service} from 'interfaces/Services/service.interface';

export const sameLocationItemsLister = (
  lat: string,
  lng: string,
  instruments?: Instrument[],
  products?: Product[],
  services?: Service[],
  jobs?: Job[],
) => {
  let length = 0;

  const sameLocationInstruments = instruments?.filter(
    (x) => x.lat === lat && x.lng === lng,
  );
  const sameLocationProducts = products?.filter(
    (x) => x.lat === lat && x.lng === lng,
  );
  const sameLocationServices = services?.filter(
    (x) => x.lat === lat && x.lng === lng,
  );
  const sameLocationJobs = jobs?.filter((x) => x.lat === lat && x.lng === lng);

  sameLocationInstruments && (length += sameLocationInstruments.length);
  sameLocationProducts && (length += sameLocationProducts.length);
  sameLocationServices && (length += sameLocationServices.length);
  sameLocationJobs && (length += sameLocationJobs.length);

  if (length <= 1) return;

  const itemsList: {
    Products: Product[];
    Instruments: Instrument[];
    Services: Service[];
    Jobs: Job[];
  } = {Products: [], Instruments: [], Services: [], Jobs: []};

  sameLocationInstruments && (itemsList.Instruments = sameLocationInstruments);
  sameLocationProducts && (itemsList.Products = sameLocationProducts);
  sameLocationServices && (itemsList.Services = sameLocationServices);
  sameLocationJobs && (itemsList.Jobs = sameLocationJobs);

  return itemsList;
};
