import React from 'react';
import UIListItem from 'components/UIListItems/UIListItem';
import {Product} from 'interfaces/Products/product.interface';

export interface ProductsProps {
  products: any;
  isOwnInstruments?: boolean;
  onProductClick: (product: any) => void;
  type?: string;
}

const ProductsListBuyPage = ({
  products,
  isOwnInstruments,
  onProductClick,
  type,
}: ProductsProps) => {
  return (
    <div className='my-5 space-y-5'>
      {products.map((product: Product) => (
        <UIListItem
          key={product.id}
          product={product}
          onItemClick={onProductClick}
          isOwn={isOwnInstruments}
          type={type}
        />
      ))}
    </div>
  );
};

export default ProductsListBuyPage;
