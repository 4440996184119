import React from 'react';

import IconEuro from 'assets/IconEuro.svg';
import {IonIcon} from '@ionic/react';
import UIOrangeButton from 'components/UIButtons/UIOrangeButton';

type Props = {
  setPriceMin: any;
  setPriceMax: any;
  setDistanceMin: any;
  setDistanceMax: any;
  onFilter: () => void;
};

const UIFilter = ({
  setPriceMin,
  setPriceMax,
  setDistanceMin,
  setDistanceMax,
  onFilter,
}: Props) => {
  return (
    <div className='flex flex-col px-10 pt-5 space-y-2 bg-white'>
      <div className='flex justify-between space-x-2'>
        <div className='text-orange-main'>Mindestpreis</div>
        <div className='flex'>
          <input
            type='number'
            defaultValue={0}
            onChange={(e) => setPriceMin(e.target.value)}
            className='w-20 bg-transparent text-black'
            placeholder='0'
          />
          <IonIcon icon={IconEuro} className='mt-0.5 text-gray-500' />
        </div>
      </div>
      <div className='flex justify-between space-x-2'>
        <div className='text-orange-main'>Höchstpreis</div>
        <div className='flex'>
          <input
            type='number'
            onChange={(e) => setPriceMax(e.target.value)}
            className='w-20 bg-transparent text-black'
            defaultValue={1000000}
          />
          <IonIcon icon={IconEuro} className='mt-0.5 text-gray-500' />
        </div>
      </div>
      <div className='flex justify-between space-x-2'>
        <div className='text-orange-main'>Mindestabstand</div>
        <div className='flex'>
          <input
            type='number'
            onChange={(e) => setDistanceMin(e.target.value)}
            className='w-20 bg-transparent text-black'
            defaultValue={0}
          />
          <div className='text-[12px] text-gray-500 mt-1'>km</div>
        </div>
      </div>
      <div className='flex justify-between space-x-2'>
        <div className='text-orange-main'>Höchstabstand</div>
        <div className='flex'>
          <input
            type='number'
            onChange={(e) => setDistanceMax(e.target.value)}
            className='w-20 bg-transparent text-black'
            defaultValue={100}
          />
          <div className='text-[12px] text-gray-500 mt-1'>km</div>
        </div>
      </div>
      <div>
      <UIOrangeButton type={'button'} onClick={onFilter} title={'Filter'}/>
      </div>
    </div>
  );
};

export default UIFilter;
