import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {IonIcon} from '@ionic/react';
import {Instrument} from 'interfaces/Instruments/Instrument.interface';
import {
  // call,
  // location,
  // mailOutline,
  flag,
  homeOutline,
  starOutline,
} from 'ionicons/icons';
import IconEuro from 'assets/IconEuro.svg';
import {useRecoilValue} from 'recoil';
import {accountState} from 'states/Account/accountState';
import {instrumentsService} from 'api/instruments/instruments.service';
// import {distanceCalculator} from 'utils/distanceCalculator';
import FavouritesButton from 'pages/Common/Favourites/FavouritesButton';
import UICalendar from 'components/UICalendar/UICalendar';
import greenCircle from 'assets/temp/greenCircle.png';
import redCircle from 'assets/temp/redCircle.png';
// import {geolocationState} from 'states/Common/CommonState';
import {UIClickImage} from 'components/UIImages/UIClickImage';
import {Link} from 'react-router-dom';
import UIButtonShare from 'components/UIButtons/UIButtonShare';
import {useIonRouter} from '@ionic/react';
import BMiniMap from 'forms/Common/places/BMiniMap';
import UICommentSpace from 'components/UICommentSpace/UICommentSpace';

interface Props {
  fields: Instrument;
  isDefaultFavorite: boolean;
  setDate: any;
  setIsPopUpVisible: Dispatch<SetStateAction<boolean>>;
  onClick?: any;
}

export const DetailsInstrument = ({
  fields,
  isDefaultFavorite,
  setDate,
  setIsPopUpVisible,
  onClick,
}: Props) => {
  const router = useIonRouter();
  const accountData = useRecoilValue<any>(accountState);
  const [isFavorite, setIsFavorite] = useState(isDefaultFavorite);

  useEffect(() => setIsFavorite(isDefaultFavorite), [isDefaultFavorite]);

  const favHandler = async (id: number) => {
    if (!isFavorite) {
      await instrumentsService.addFavoriteInstrument(id);
      return;
    }
    await instrumentsService.deleteFavoriteInstrument(id);
  };

  return (
    <div
      className='rounded-lg m-1 relative
      bg-yellow-slight drop-shadow-md dark:bg-[#393939] dark:border-[#F0A249]'
    >
          <UIClickImage
            customClassName='w-full h-52 rounded-3xl'
            type='instruments'
            photoLink={fields.photos.length > 0 && fields.photos[0]}
            setIsPopUpVisible={setIsPopUpVisible}
          />
            <div className='flex justify-end'>
            {fields.availability && (
              <img
                alt='greenCircle'
                src={greenCircle}
                className='h-4 mt-2 mr-3 absolute top-0'
              />
            )}
            {!fields.availability && (
              <img
                alt='redCircle'
                src={redCircle}
                className='h-4 mt-2 mr-3 absolute top-0'
              />
            )}
            </div>

        <div className='absolute w-full top-40'>
        <div className='bg-white rounded-t-3xl pl-4 border-t-2 border-orange-main'>
          <div className='flex justify-between'>
            <div>
          <p className='text-gray-600 text-lg font-bold mt-2 pt-5 dark:text-white'>
            {fields.instrumentName}
          </p>
          <p className='text-sm font-thin dark:text-white'>
            {fields.productionYear}
          </p>
          <p className='text-sm font-thin dark:text-white'>{fields.purpose}</p>
          <p className='text-sm font-thin dark:text-white'>
            {fields.owner.firstName + ' ' + fields.owner.lastName}
          </p>
          </div>

          <div className=''>
            <div className='pt-9 text-black space-x-2 flex items-center'>
              <Link to={`/report/instrument/${fields.id}`}>
                {' '}
                <IonIcon
                  icon={flag}
                  className='mt-2 h-6 w-6 dark:text-[#F0A249]'
                />
              </Link>
              {fields && accountData && accountData.id !== fields!.owner!.id ? (
                <FavouritesButton
                  favHandler={favHandler}
                  fields={fields}
                  isFavorite={isFavorite}
                  setIsFavorite={setIsFavorite}
                />
              ) : (
                <IonIcon
                  icon={starOutline}
                  className='mt-1 text-yellow-400 h-6 w-6'
                  onClick={() => {
                    router.push('/sign-in');
                  }}
                />
              )}
              <UIButtonShare />
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className='flex justify-end mr-4 absolute top-36 right-0'>
          <div className='flex flex-row space-x-4'>
            <div className='bg-yellow-accent text-white pr-2
             rounded-3xl flex'>
          <IonIcon icon={IconEuro} className='mt-3 ml-3 text-white' />
          <p className='mr-2 text-sm mt-3 text-white'>
            {fields.pricePerDay} / pro Tag
          </p>
          </div>
          <button className='bg-orange-rate rounded-3xl px-2 py-3 text-white text-xs'
          onClick={onClick}
          >
            {fields.avgRating.avgRating}
            </button>
            </div>
        </div>
        <div>
      <div className='mb-3 font-sans text-gray-500 dark:bg-[#393939] pt-14'>
      <p className='text-yellow-accent float-left text-sm mt-3 mr-1.5 ml-3 dark:text-[#F0A249]'>
        Kontakt
      </p>
      <hr className='bg-yellow-accent mr-3 mb-4 mt-7 dark:bg-[#F0A249]' />
      <div className='flex-col text-gray-500 ml-4 mr-8 dark:text-white'>
        {/* <div className='flex'>
          <IonIcon icon={call} className='mt-0.5' />
          <p className='text-sm ml-2 '>{fields.phoneNumber}</p>
        </div> */}
        <div className='flex mt-3'>
          <IonIcon icon={homeOutline} className='h-5 w-5' />
          <p className='text-sm ml-2'>{fields.location}</p>
        </div>
        {/* <div className='flex mt-3'>
        <IonIcon icon={mailOutline} className='h-5 w-5' />
        <p className='text-sm ml-2'>{fields.email}</p>
        </div> */}
      </div>
      <div className='mx-3'>
        <BMiniMap geolocation={{lat: +fields.lat, lng: +fields.lng}} />
      </div>
      <p className='text-yellow-accent float-left text-sm mt-3 mr-1.5 ml-3 dark:text-[#F0A249]'>
        Beschreibung
      </p>
      <hr className='bg-yellow-accent mr-3 mt-7 mb-4 dark:bg-[#F0A249]' />
      <div className='text-sm text-gray-400 ml-4 dark:text-white'>{fields.description}</div>
      <p className='text-yellow-accent float-left text-sm mt-3 mr-1.5 ml-3 dark:text-[#F0A249]'>
        Kommentare
      </p>
      <hr className='bg-yellow-accent mr-3 mt-7 mb-4 dark:bg-[#F0A249]' />
      <UICommentSpace
        comments={fields.comments}
        id={fields.id}
      />
      <p className='text-yellow-accent float-left text-sm mr-1.5 ml-3 dark:text-[#F0A249]'>
        Verleihtag auswählen
      </p>
      <hr className='bg-yellow-accent mr-3 mt-3 mb-5 dark:bg-[#F0A249]' />
      <UICalendar onDatesSet={setDate} />
    </div>
    </div>
    </div>
  );
};
