import {IonIcon} from '@ionic/react';
import IconEuro from 'assets/IconEuro.svg';
// import RatingStars from 'pages/Common/RatingStars/RatingStars';
import React from 'react';
import {UIImage} from 'components/UIImages/UIImage';
import {FavoriteService} from 'interfaces/Services/service.interface';
import {distanceCalculator} from 'utils/distanceCalculator';
import {location} from 'ionicons/icons';
import {useRecoilValue} from 'recoil';
import {geolocationState} from 'states/Common/CommonState';

type Props = {
  field: FavoriteService;
  onOfferClick: Function;
};

export const FavoritesOffersListItem: React.FC<Props> = ({field, onOfferClick}) => {
  const geolocation = useRecoilValue(geolocationState);
  return (
    <div
      key={field.service.name! + field.service.id}
      onClick={() => onOfferClick(field.service.id!)}
    >
      <div className='rounded-xl border border-yellow-accent mx-6 my-3 bg-white drop-shadow-md
      dark:bg-[#393939]'>
        <div className='h-8 bg-yellow-accent rounded-t-lg w-full flex justify-between
        dark:bg-[#F0A249]'>
          <div className='flex justify-between w-full mr-3 text-white'>
            <div className='flex'>
              <IonIcon
                icon={location}
                className='h-6 w-6 mt-1 ml-3'
              />
              <p className='text-sm mt-1.5'>
                {distanceCalculator(field.service.lat, field.service.lng, geolocation).toFixed() +
                  ' '}
                km
              </p>
            </div>
            <div className='flex'>
              <IonIcon
                icon={IconEuro}
                className='h-3.5 mt-2'
              />
              <p className='pl-0.5 text-sm mt-1.5'>{field.service.pricePerHour} / h</p>
            </div>
          </div>
        </div>
        <div className='flex ml-4 mb-3 font-sans w-full '>
          <div className='w-1/4'>
            <UIImage
              photoLink={field.service.photos > 0 && field.service.photos[0].photo}
              customClassName='mr-3 h-20 w-20 mt-3 mb-2'
              type='services'
            />
          </div>
          <div className='text-gray-500 w-8/12  dark:text-white'>
            <p className='text-gray-600 pl-2 text-md font-bold mt-1
            dark:text-white'>{field.service.name}</p>
            <p className='pl-1 text-sm font-thin'>
              {field.service.postCode} {field.service.location}, {field.service.street}
            </p>
            {/* <div className='ml-2 mt-1.5 float-left'>
              <RatingStars />
            </div>
            <p className='text-xs float-left font-thin mt-1.5 mb-2 ml-2'>
              2 Bewertungen
            </p> */}
            <p className='text-xs clear-both font-thin ml-2 truncate'>
              {field.service.description}
            </p>
            <p className='text-xs mt-1 font-thin flex justify-end mr-5'>
              {field.createdAt.substring(0, 10)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
