import React, {useState, useEffect} from 'react';
import {IonIcon, IonTextarea, useIonRouter} from '@ionic/react';
import AddPhotos from 'components/UIAddPhoto/UIAddPhotos';
import UIYellowButton from 'components/UIButtons/UIYellowButton';
import {useForm} from 'react-hook-form';
import {BFormAddressField} from 'forms/Common/places/BFormAddressField';
import UIInput from 'components/UIInputs/UIInput';
import {OrangeLine} from 'components/UIContainer/UIOrangeLine';
import {Service} from 'interfaces/Services/service.interface';
import {servicesService} from 'api/services/services.service';
import {eyeOutline, logoEuro, star} from 'ionicons/icons';
import LineColored from '../Instruments/LineColored';
import {useSetRecoilState} from 'recoil';
import {refreshCounterState} from 'states/Common/CommonState';

function ServiceEditForm({offerId}: any) {
  const setRefreshCount = useSetRecoilState(refreshCounterState);
  const [apiResultMessage, setApiResultMessage] = useState('');
  const [addressPlaceholder, setAddressPlaceholder] = useState<string>(
    'Wähle einen Adresse',
  );
  const [oldPhotos, setOldPhotos] = useState<{ photo: string; }[]>();
  const [service, setService] = useState<Service>();
  const router = useIonRouter();
  const {
    handleSubmit,
    register,
    setValue,
    formState: {errors},
    control,
  } = useForm();

  const inputStyles = 'w-full bg-white mb-2 text-sm text-gray-700';

  useEffect(() => {
    servicesService.getDetails(offerId).then((response) => {
      setService(response.data);

      setValue('mondayToFriday', response.data.mondayToFriday);
      setValue('saturdayToSunday', response.data.saturdayToSunday);
      setValue('pricePerHour', response.data.pricePerHour);
      setValue('name', response.data.name);
      setValue('industry', response.data.industry);
      setValue('email', response.data.email);
      setValue('phoneNumber', response.data.phoneNumber);
      setValue('description', response.data.description);
      setValue('lat', response.data.lat.toString());
      setValue('lng', response.data.lng.toString());
      setValue('postCode', response.data.postCode);
      setValue('Address', response.data.Address);
      setValue('location', response.data.location);

      setOldPhotos(response.data.photos);
      setAddressPlaceholder(response.data.location);
    });
  }, []);

  const onSubmit = async (data: Service) => {
    data.photos && (data.photos = data.photos[0].photo);
    if (typeof data.photos === 'undefined') delete data.photos;
    if (data.Address) {
      data.location = data.Address.label;
      data.postCode = data.Address.postCode;
      data.lat = data.Address.lat;
      data.lng = data.Address.lng;
      setAddressPlaceholder(data.location!);
    }

    servicesService
      .updateService(data, offerId)
      .then((res) => {
        setApiResultMessage('Erfolg!');
        setRefreshCount((v) => v + 1);
        if (!service!.issued) {
          router.push(`/purchase/services/${service!.id}`);
          setApiResultMessage('');
        }
      })
      .catch(() => {
        setApiResultMessage('Error!');
      });
  };

  return (
    <form className='h-full ' onSubmit={handleSubmit(onSubmit)}>
      <div className='border-2 border-orange-main bg-white rounded-md mb-5'>
        <div className='h-6 bg-yellow-accent'></div>
        <div className='flex my-4 mx-2'>
          <div className='flex-col mx-2 w-2/5 space-y-1 text-sm'>
            <div className='flex-col w-2/5 space-y-2 text-sm mb-16'>
              <AddPhotos
                control={control}
                name='photos'
                photos={oldPhotos}
                setPhotos={setOldPhotos}
                photosType={'services'}
              />
            </div>
            <div>
              Mo - Fr:
              <UIInput
                customClassName={inputStyles}
                name='mondayToFriday'
                placeholder='08:00 - 16:00'
                register={register}
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.mondayToFriday} />
            </div>
            <div>
              Sa - So:
              <UIInput
                customClassName={inputStyles}
                name='saturdayToSunday'
                placeholder='10:00 - 14:00'
                register={register}
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.saturdayToSunday} />
            </div>
            <div>
              Preis pro Stunde:
              <div className='flex'>
                <UIInput
                  name='pricePerHour'
                  type='number'
                  placeholder='80'
                  register={register}
                  validation={{required: true}}
                  errors={errors}
                />
                <IonIcon icon={logoEuro} className='h-4 w-[18px] mt-1' />
              </div>
              <LineColored error={errors.pricePerHour} />
            </div>
          </div>
          <div className='flex-col space-y-2 mx-2 w-3/5 text-sm'>
            <div>
              Firmenname:
              <UIInput
                customClassName={inputStyles}
                name='name'
                placeholder='Firma'
                required='required'
                register={register}
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.name} />
            </div>
            <div>
              Branche:
              <UIInput
                customClassName={inputStyles}
                name='industry'
                placeholder='Geschäft'
                required='required'
                register={register}
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.industry} />
            </div>
            <div>
              Email:
              <UIInput
                customClassName={inputStyles}
                name='email'
                type='text'
                placeholder='beispiel@email.com'
                register={register}
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.email} />
            </div>
            <div>
              Telefonnummer:
              <UIInput
                customClassName={inputStyles}
                name='phoneNumber'
                type='tel'
                placeholder='0660 1234567'
                minLength={9}
                maxLength={16}
                register={register}
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.phoneNumber} />
            </div>
          </div>
        </div>{' '}
        <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
          <div className='text-orange-main text-sm'>Adresse</div>
          <div className='bg-orange-main w-full h-px'></div>
        </div>
        <div className='mx-2 mt-2'>
          <BFormAddressField
            control={control}
            fieldName='Address'
            key={Math.random()}
            placeholder={addressPlaceholder}
            latLng={{lat: +service?.lat, lng: +service?.lng}}
          />
        </div>
        <OrangeLine name='Beschreibung' />
        <IonTextarea
          maxlength={1000}
          rows={4}
          className='mx-2 pr-2 h-[120px]'
          {...register('description')}
          placeholder='230 V Mischmaschine mit 140 Liter
            Fassungsvermögen in sehr guten Zustand inkl 10m Anschlusskabel'
        />
            <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
              <div className='text-orange-main text-sm'>Datum und Statistik</div>
              <div className='bg-orange-main w-[1400px] h-px'></div>
            </div>
            <div className='flex text-sm place-content-center dark:text-gray-300 h-10'>
              <div className='flex pr-11 ml-1'>
                <p className='dark:text-gray-300 pr-2'>Verfügbar bis:</p>
                <p className='dark:text-gray-300 pr-4'>
                  {service?.issuedPromotedUntil
              ? new Date(service.issuedPromotedUntil).toLocaleDateString()
              : 'Nicht eingelöst'}
              asdasda
                </p>
                <p className='dark:text-gray-300 pr-2'>Befördert zu:</p>
                <p className='dark:text-gray-300'>
                  {service?.mapPromotedUntil
              ? new Date(service.mapPromotedUntil).toLocaleDateString()
              : 'Nicht eingelöst'}
              essa
                </p>
              </div>
              <div className='flex place-content-end mr-1'>
              <IonIcon
                icon={star}
                className='text-yellow-400 h-4 w-5 dark:text-[#F0A249] pr-1'
              />
              <p className='pr-2 text-md'>
                {service?.countFavourite ? service?.countFavourite : 0}
              </p>
              <IonIcon
                  icon={eyeOutline}
                  className='h-5 w-5 pr-1'
                />
                <p>
                  {service?.visitors}
                </p>
              </div>
            </div>
      </div>
      <p className='text-yellow-accent text-center font-bold mb-5'>
        {apiResultMessage}
      </p>
      <div className='flex flex-col mb-5 justify-end text-white'>
        <UIYellowButton type='submit' text='Weiter' />
      </div>
    </form>
  );
}

export default ServiceEditForm;
