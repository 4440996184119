import React from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import UIButtonBack from 'components/UIButtons/UIButtonBack';
import UILogo from 'components/UILogo/UILogo';
import SearchMapComponent from './SerachMapComponent';

export default function SearchMapPage() {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <SearchMapComponent />
      </IonContent>
    </IonPage>
  );
}
