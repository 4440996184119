import {IonContent, IonGrid, IonPage, isPlatform, useIonRouter} from '@ionic/react';
import UILogo from 'components/UILogo/UILogo';
import React, {useEffect, useState} from 'react';
import HomeMenuButton from './HomeMenuButton';
import tools from 'assets/Home/tools.jpg';
import shop from 'assets/Home/shop.jpg';
import instruments from 'assets/Home/instruments.jpg';
import machine from 'assets/Home/machinee.png';
import {accountService} from 'api/account/account.service';
import {useSetRecoilState} from 'recoil';
import {accountState} from 'states/Account/accountState';
import {Geolocation} from '@ionic-native/geolocation';
import {geolocationState, refreshCounterState} from 'states/Common/CommonState';
import {getAllMessages, getMessagesFromStorage} from 'api/chat/chat.service';
import {newMessagesState} from 'states/Messages/messageState';
import {authService} from 'api/account/auth.service';
import UIResponsivePadding from 'components/UIResponsive/UIResponsivePadding';
import {userService} from 'api/users/users.service';
import {instrumentsService} from 'api/instruments/instruments.service';
import {productsService} from 'api/products/products.service';

const HomePage: React.FC = () => {
  const router = useIonRouter();
  const setRefreshCount = useSetRecoilState(refreshCounterState);
  const setAccountData = useSetRecoilState(accountState);
  const setGeolocation = useSetRecoilState(geolocationState);
  const setNewMessagesState = useSetRecoilState(newMessagesState);
  const [instrumentsOffers, setInstrumentsOffers] = useState(Number);
  const [productsOffers, setProductsOffers] = useState(Number);


  const fetchCurrentUser = async () => {
    const res = await accountService.getCurrentUser();
    setAccountData(res.data);
  };

  const fetchMessages = async () => {
    const res = await getAllMessages();
    const oldMessages = await getMessagesFromStorage();
    const messages = JSON.stringify(res.data);
    setNewMessagesState(oldMessages !== messages);
  };

  useEffect(() => {
    instrumentsService.getAll().then((response) => {
      setInstrumentsOffers(response.data.results.length);
    });
    productsService.getAll().then((response) => {
      setProductsOffers(response.data.results.length);
    });
  }, []);

  useEffect(() => {
    const fetch = async () => {
      await authService.refreshAccessToken();
      await fetchMessages();
      await fetchCurrentUser();
    };

      userService.getUserData().then((response) => {
        setAccountData(response.data);
      });

    const getGeolocation = async () => {
      const position = await Geolocation.getCurrentPosition();
      setGeolocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    };

    fetch();
    setRefreshCount((v) => v + 1);
    getGeolocation();
  }, []);

  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.documentElement.classList.add('dark');
    }
  }, []);

  const top = isPlatform('ios') ? 'top-[55px]' : 'top-[10px]';

  return (
    <IonPage>
      <IonContent>
        <UILogo customClassName={`absolute ${top} flex justify-center w-full`} />
        <UIResponsivePadding>
          <IonGrid
            className='mt-24 dark:bg-[#474747] xl:w-2/3 w-full h-full mb-12'
          >
                <HomeMenuButton
                  src={tools}
                  alt='tools'
                  name='Werkzeug mieten'
                  numberOfItems={instrumentsOffers}
                  onClick={() => router.push('/instruments/categories/1/loan')}
                />
                <HomeMenuButton
                  src={machine}
                  alt='vehicles'
                  name='Baumaschinen mieten'
                  numberOfItems={productsOffers}
                  onClick={() => router.push('/instruments/categories/2/loan')}
                />
                <HomeMenuButton
                  src={shop}
                  alt='shop'
                  name='WUB Shop'
                  numberOfItems={instrumentsOffers + productsOffers}
                  onClick={() => router.push('/shop')}
                />
                <HomeMenuButton
                  src={instruments}
                  alt='settings'
                  name='WUB einstellen'
                  isEnable={true}
                  onClick={() => router.push('/instruments-add')}
                />
          </IonGrid>
        </UIResponsivePadding>
      </IonContent>
    </IonPage>
  );
};

export default HomePage;
