import client from 'api/common/client';
import {getPlayerIdFromLocalStorage} from 'routes/oneSignalInit';
import {createFormData} from 'utils/formDataHelper';

const updateUser = async (userData: any) => {
  const data = createFormData(userData);
  return client.patch('users/current-user/', data);
};

const updateUserPhoto = async (data: any) => {
  const fd = new FormData();
  fd.append('image', data);

  return client.patch('users/current-user/', fd);
};

const getUserData = () => {
  return client.get('users/current-user/');
};

const updateUserPlayerId = async () => {
  const {data: userData} = await getUserData();
  const playerId = await getPlayerIdFromLocalStorage();

  if (playerId == null || userData == null) {
    return;
  }

  await userService.updateUser({
    ...userData,
    playerId: playerId,
  });
};

export const userService = {
  updateUser,
  updateUserPhoto,
  getUserData,
  updateUserPlayerId,
};
