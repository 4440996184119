/* eslint-disable */
import {IonIcon, IonSelect, IonSelectOption, IonTextarea} from '@ionic/react';
import UIInput from 'components/UIInputs/UIInput';
import AddPhotos from 'components/UIAddPhoto/UIAddPhotos';
import UIYellowButton from 'components/UIButtons/UIYellowButton';
import {OrangeLine} from 'components/UIContainer/UIOrangeLine';
import {BFormAddressField} from 'forms/Common/places/BFormAddressField';
import {callOutline, mailOpenOutline} from 'ionicons/icons';
import React, {useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {jobsService} from 'api/jobs/jobs.service';
import LineColored from '../Instruments/LineColored';
import {Job, JobType, jobTypeTranslated} from 'interfaces/Jobs/Jobs.interface';
import {useHistory} from 'react-router';
import {accountService} from 'api/account/account.service';

const JobOfferForm: React.FC = () => {
  const [apiResultMessage, setApiResultMessage] = useState<string>('');
  const [jobTypeIdx, setJobTypeIdx] = useState<number>();
  const [jobId, setJobId] = useState<number>();
  const [address, setAddress] = useState<any>();
  const inputRef = useRef<any>();

  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: {errors},
  } = useForm();

  const history = useHistory();
  const inputStyles = 'w-full bg-white text-sm text-gray-700 break-all dark:bg-[#393939] dark:text-white ';

  useEffect(() => {
    accountService.getCurrentUser().then((response) => {
      setValue('email', response.data.email);
      setValue('phoneNumber', response.data.phone);
    });
  }, []);

  const onSubmit = async (data: Job) => {
    data.jobType = jobTypeTranslated(jobTypeIdx!);

    if (address) {
      data.location = address.label;
      data.postCode = address.postCode;
      data.lat = address.lat.toString();
      data.lng = address.lng.toString();
    } else if (data.address) {
      data.location = data.address.label;
      data.postCode = data.address.postCode;
      data.lat = data.address.lat;
      data.lng = data.address.lng;
      setAddress(data.address);
    }

    const method = jobId ? jobsService.updateJob(data, jobId.toString()) : jobsService.addJob(data);

    setApiResultMessage('Ladung...');

    method
      .then((res) => {
        setApiResultMessage('');
        setJobId(res.data!.id!);
        setAddress(data.address);
        history.push(`/purchase/jobs/${res.data!.id! ? res.data.id : jobId}`);
      })
      .catch((error) => {
        setApiResultMessage('Error! ' + error.response.data.message[0]);
      });
  };

  const handleInputChange = (e: any) => {
    if (e == null) {
      return null;
    }

    setJobTypeIdx(e.detail.value);
  };

  return (
    <form
      className='h-full mb-3 font-sans font-semibold'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='border-2 border-orange-main bg-white rounded-md mb-5 dark:bg-[#393939] '>
        <div className='bg-orange-main w-full h-8 dark:bg-[#F0A249] ' />
        <div className='flex my-4 dark:bg-[#393939]'>
          <div className='flex-col w-auto space-y-2 ml-2 text-sm'>
            <AddPhotos
              control={control}
              name='images'
            />
          </div>
          <div className='flex-col w-3/5 space-y-2 mx-4 text-sm dark:bg-[#393939] dark:text-white '>
            Firmenname:{' '}
            <UIInput
              required='required'
              customClassName={inputStyles}
              placeholder='Dein Firmenname'
              register={register}
              name='name'
            />
            <LineColored colorIntensity={700} />
            Stellenanzeige:{' '}
            <UIInput
              required='required'
              customClassName={inputStyles}
              placeholder='Bauarbeiter'
              register={register}
              name='jobOffer'
            />
            <LineColored colorIntensity={700} />
            Berufsart:{' '}
            <UIInput
              required='required'
              customClassName={inputStyles}
              placeholder='z.B Metalltechnikerin'
              register={register}
              name='position'
            />
            <LineColored colorIntensity={700} />
            Bezahlung:{' '}
            <div className='flex'>
              <UIInput
                customClassName='w-full bg-white text-sm  dark:bg-[#393939] dark:text-white'
                placeholder='2.400,00 €/Monat'
                register={register}
                name='salary'
                validation={{required: true}}
                errors={errors}
                type='number'
              />
              <LineColored error={errors.salary}></LineColored>
            </div>
            <LineColored
              colorIntensity={700}
              error={errors.price}
            />
            <div className='mt-8 w-full'>
              <p>Arbeitszeit:</p>
              <IonSelect
                okText='Weiter'
                cancelText='Abbrechen'
                placeholder='Wählen...'
                onIonChange={(e) => handleInputChange(e)}
                ref={inputRef}
              >
                {JobType.map((job: any) => (
                  <IonSelectOption
                    key={job.id}
                    value={job.value}
                  >
                    {job.label}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </div>
          </div>
        </div>
        <OrangeLine name='Kontakt' />
        <div className='w-full m-3 text-sm space-y-2'>
          <div className='flex space-x-1'>
            <IonIcon icon={callOutline} />
            <UIInput
              customClassName='bg-transparent dark:bg-[#393939] dark:text-white '
              type='tel'
              name='phoneNumber'
              // value={user?.phone}
              register={register}
              placeholder='0660 1234567'
              validation={{required: true}}
              minLength={9}
              maxLength={16}
              errors={errors}
            />
          </div>
          <div className='flex space-x-1 '>
            <IonIcon icon={mailOpenOutline} />
            <UIInput
              customClassName='bg-transparent dark:bg-[#393939] dark:text-white '
              type='email'
              name='email'
              // value={user?.email}
              register={register}
              placeholder='beispiel@email.com'
              validation={{required: true}}
              required='required'
              errors={errors}
            />
          </div>
        </div>
        <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
          <div className='text-orange-main text-sm'>Adresse</div>
          <div className='bg-orange-main w-full h-px'></div>
        </div>
        <div className='mx-2 mt-2'>
          <BFormAddressField
            placeholder={address?.label}
            control={control}
            fieldName='address'
            key={Math.random()}
          />
        </div>
        <OrangeLine name='Beschreibung' />
        <IonTextarea
          maxlength={1000}
          className='input m-2 break-words text-sm dark:text-white '
          placeholder='Beschreibe die Aufgaben, Anforderungen und Aussichten'
          {...register('description')}
        />
      </div>
      <div className='flex flex-col justify-end text-white'>
        <p className='text-yellow-accent text-center font-bold mb-5'>{apiResultMessage}</p>
        <UIYellowButton
          type='submit'
          text='Weiter'
        />
      </div>
    </form>
  );
};

export default JobOfferForm;
