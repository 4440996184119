import React from 'react';
import {Link} from 'react-router-dom';

interface Props {
  name: string;
  src: any;
  linkTo?: any;
  alt: string;
  disabled?: boolean;
  onClick?: () => void;
}

const UIOrangeSquareBox: React.FC<Props> = ({linkTo, name, src, alt, disabled, onClick}) => {
  return (
    <Link
      className='w-1/2 p-2'
      to={linkTo!}
    >
      <div
        className={`border-2 border-orange-main rounded-xl shadow-lg shadow-orange-main 
        dark:bg-[#393939] dark:border-[#F0A249]  ${
          disabled && 'opacity-50'
        }`}
        onClick={onClick}
      >
        <div className='h-[108px] flex justify-center items-center bg-orange-main rounded-t-xl
        dark:bg-[#F0A249]'>
          <img
            src={src}
            alt={alt}
            className='w-18 mt-6'
          />
        </div>
        <div
          className='h-16 rounded-b-xl flex items-center justify-center
            text-grey text-center text-sm font-bold dark:text-white'
        >
          {name}
        </div>
      </div>
    </Link>
  );
};

export default UIOrangeSquareBox;
