import client from 'api/common/client';
import {Storage} from '@capacitor/storage';

const MESSAGES_KEY = 'MESSAGES_KEY';

export const createChat = async (
  message: object,
  id: any,
  type: string,
) => {
  const response = await client.post(`/${type}/${id}/chat/`, message);
  return response;
};

export const postMessage = async (
  message: object,
  id: any,
  type: string,
  conversationId: string,
) => {
  const response = await client.post(`/${type}/${id}/chat/${conversationId}/`, message);
  return response;
};

export const postAttachemnt = async (id: any) => {
  const response = await client.post(`/chat/${id}/attachment`);
  return response;
};

export const getMessages = async (
  receiverid: any,
  type: string,
  conversationId: string,
) => {
  const response = await client.get(
    `/${type}/${receiverid}/chat/${conversationId}`,
  );
  return response;
};

export const getAllMessages = async () => {
  const response = await client.get(`/users/current-user/chats`);
  return response;
};

export const setMessagesInStorage = (messages: string) => {
  const mesString = JSON.stringify(messages);
  Storage.set({key: MESSAGES_KEY, value: mesString});
};

export const getMessagesFromStorage = async () => {
  const {value} = await Storage.get({key: MESSAGES_KEY});

  return value ? value : '';
};
export const removetMessagesFromStorage = async () => {
  await Storage.remove({key: MESSAGES_KEY});
};
