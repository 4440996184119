import React, {useEffect} from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import UILogo from 'components/UILogo/UILogo';
import {useParams} from 'react-router';
import UIButtonBack from 'components/UIButtons/UIButtonBack';
import {saveLastPayment} from 'utils/paymentHelper';

export const PromotionPaymentPage = () => {
  const {paymentId, checkoutId}: any = useParams();

  useEffect(() => {
    const langScript = document.createElement('script');

    langScript.innerHTML = 'var wpwlOptions = { locale: "de"}';

    document.body.appendChild(langScript);

    const script = document.createElement('script');

    script.src = `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
    script.async = true;

    document.body.appendChild(script);

    saveLastPayment(paymentId);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form
          action={
            `https://app.wubmal.com/payment-result/${paymentId}`
          }
          className='paymentWidgets'
          data-brands='VISA MASTER PAYPAL SOFORTUEBERWEISUNG'
        >
          <button type='submit' name='pay' className='wpwl-button-pay' />
        </form>
      </IonContent>
    </IonPage>
  );
};

export default PromotionPaymentPage;
