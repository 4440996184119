import React, {useEffect, useState} from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import UIButtonBack from 'components/UIButtons/UIButtonBack';
import UITopTextBar from 'components/UILabels/UITopTextBar';
import UILogo from 'components/UILogo/UILogo';
import {useParams} from 'react-router';
import LoadingIndicator from 'components/UILoading/UILoadingIndicator';
import {diysService} from 'api/diy/diy.service';
import {DIYInterface} from 'interfaces/DIY/DIY.interface';
import CommentBox from 'components/UIComments/CommentBox';
import CreateComment from 'components/UIComments/CreateComment';
import {BASE_URL} from 'utils/constants';

const DIYDetailPage = () => {
  const [data, setData] = useState<DIYInterface>();
  const [count, setCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {id: id} = useParams<{id: string}>();

  useEffect(() => {
    setIsLoading(true);
    diysService
      .getDIY(id)
      .then((res) => {
        setData(res.data);
      })
      .finally(() => setIsLoading(false));
  }, [count]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>{data && data.title}</UITopTextBar>
      </IonHeader>
      <IonContent>
        <div
          className='border border-gray-300 shadow-lg
         mt-1 mx-1.5 bg-gray-100 rounded-md text-gray-600 px-2 border-b-yellow-accent
         dark:bg-[#393939]'
        >
          <img
            src={`${BASE_URL}media/diys/${data?.image}`}
            className='w-full h-[200px] my-2 rounded-md'
          />
          <p className='font-sans font-bold dark:text-white '>{data?.title}</p>
          <p className='text-sm font-sans font-semibold mb-3 dark:text-white'>
            {data?.description}
          </p>
        </div>
        <div className='mx-3 mt-3'>
          <CreateComment count={count} setCount={setCount} id={id} type='diy' />
        </div>
        <p className='text-gray-700 font-bold text-sm pl-5 dark:text-white'>Bemerkungen</p>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          data &&
          data.comments.map((comment) => (
            <CommentBox key={comment.id} comment={comment} />
          ))
        )}
      </IonContent>
    </IonPage>
  );
};

export default DIYDetailPage;
