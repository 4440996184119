import {IonIcon, IonSelect, IonSelectOption, IonTextarea, useIonRouter} from '@ionic/react';
import UIInput from 'components/UIInputs/UIInput';
import AddPhotos from 'components/UIAddPhoto/UIAddPhotos';
import UIYellowButton from 'components/UIButtons/UIYellowButton';
import {OrangeLine} from 'components/UIContainer/UIOrangeLine';
import {BFormAddressField} from 'forms/Common/places/BFormAddressField';
import {Job, JobType, jobTypeTranslated} from 'interfaces/Jobs/Jobs.interface';
import {callOutline, eyeOutline, mailOpenOutline, star} from 'ionicons/icons';
import React, {useState, useEffect, useRef} from 'react';
import {useForm} from 'react-hook-form';
import {useParams} from 'react-router';
import {jobsService} from 'api/jobs/jobs.service';
import LineColored from '../Instruments/LineColored';
import {useSetRecoilState} from 'recoil';
import {refreshCounterState} from 'states/Common/CommonState';

const JobOfferEditForm: React.FC = () => {
  const setRefreshCount = useSetRecoilState(refreshCounterState);
  const {handleSubmit, register, setValue, control} = useForm();
  const {jobId: jobId} = useParams<{jobId: string}>();
  const [apiResultMessage, setApiResultMessage] = useState('');
  const [addressPlaceholder, setAddressPlaceholder] = useState<string>('Wähle einen Adresse');
  const [oldPhotos, setOldPhotos] = useState<{ photo: string; }[]>();
  const [jobTypeIdx, setJobTypeIdx] = useState<number>();
  const [job, setJob] = useState<Job>();
  const inputRef = useRef<any>();

  const router = useIonRouter();
  const inputStyles = 'w-full bg-white text-sm text-gray-700 break-all';

  useEffect(() => {
    jobsService.getDetails(+jobId).then((response) => {
      setJob(response.data);
      setValue('name', response.data.name);
      setValue('jobOffer', response.data.jobOffer);
      setValue('salary', response.data.salary);
      setValue('email', response.data.email);
      setValue('description', response.data.description);
      setValue('lat', response.data.lat.toString());
      setValue('lng', response.data.lng.toString());
      setValue('postCode', response.data.postCode);
      setValue('Address', response.data.Address);
      setValue('location', response.data.location);
      setValue('position', response.data.position);
      setValue('phoneNumber', response.data.phoneNumber);

      if (response.data.jobType === jobTypeTranslated(0)) {
        setJobTypeIdx(0);
      } else if (response.data.jobType === jobTypeTranslated(1)) {
        setJobTypeIdx(1);
      } else {
        setJobTypeIdx(2);
      }
      setOldPhotos(response.data.photos);
      setAddressPlaceholder(response.data.location);
    });
  }, []);

  const onSubmit = async (data: Job) => {
    data.jobType = jobTypeTranslated(jobTypeIdx!);
    data.photos && (data.photos = data.photos[0].photo);
    if (typeof(data.photos) === 'undefined') delete data.photos;

    if (data.address) {
      data.location = data.address.label;
      data.postCode = data.address.postCode;
      data.lat = data.address.lat;
      data.lng = data.address.lng;
      setAddressPlaceholder(data.location!);
    }

    jobsService
      .updateJob(data, jobId)
      .then((res) => {
        setApiResultMessage('Erfolg!');
        setRefreshCount((v) => v + 1);
        if (!job!.issued) {
          router.push(`/purchase/jobs/${job!.id}`);
          setApiResultMessage('');
        }
      })
      .catch(() => {
        setApiResultMessage('Error!');
      });
  };

  const handleInputChange = (e: any) => {
    if (e == null) {
      return null;
    }

    setJobTypeIdx(e.detail.value);
  };

  return (
    <form
      className='h-full mb-3 font-sans font-semibold'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='border-2 border-orange-main bg-white rounded-md mb-5'>
        <div className='bg-orange-main w-full h-8' />
        <div className='flex my-4'>
          <div className='flex-col w-auto space-y-2 ml-2 text-sm'>
            <AddPhotos
              photosType={'jobs'}
              control={control}
              photos={oldPhotos}
              setPhotos={setOldPhotos}
              name='images'
            />
          </div>
          <div className='flex-col w-3/5 space-y-2 mx-4 text-sm'>
            Firmenname:{' '}
            <UIInput
              required='required'
              customClassName={inputStyles}
              placeholder='Dein Firmenname'
              register={register}
              name='name'
            />
            <LineColored colorIntensity={700} />
            Stellenanzeige:{' '}
            <UIInput
              required='required'
              customClassName={inputStyles}
              placeholder='Bauarbeiter'
              register={register}
              name='jobOffer'
            />
            <LineColored colorIntensity={700} />
            Berufsart:{' '}
            <UIInput
              required='required'
              customClassName={inputStyles}
              placeholder='z.B Metalltechnikerin'
              register={register}
              name='position'
            />
            <LineColored colorIntensity={700} />
            Bezahlung:{' '}
            <div className='flex'>
              <UIInput
                required='required'
                customClassName='w-full bg-white text-sm text-gray-700'
                placeholder='2.400,00 €/Monat'
                register={register}
                name='salary'
              />
            </div>
            <LineColored colorIntensity={700} />
            <div className='mt-8 w-full'>
              <p>Arbeitszeit:</p>
              <IonSelect
                okText='Weiter'
                cancelText='Abbrechen'
                onIonChange={(e) => handleInputChange(e)}
                ref={inputRef}
                value={jobTypeIdx}
              >
                {JobType.map((job: any) => (
                  <IonSelectOption
                    key={job}
                    value={job.value}
                  >
                    {job.label}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </div>
          </div>
        </div>
        <OrangeLine name='Kontakt' />
        <div className='w-full m-3 text-sm'>
          <div className='w-screen'>
            <div className='flex space-x-1'>
              <IonIcon icon={callOutline} />
              <UIInput
                required='required'
                customClassName='bg-transparent w-3/4'
                placeholder='0660 1234567'
                register={register}
                name='phoneNumber'
                type='tel'
                maxLength={16}
                minLength={9}
              />
            </div>
            <div className='flex space-x-1'>
              <IonIcon icon={mailOpenOutline} />
              <UIInput
                required='required'
                customClassName='bg-transparent w-3/4'
                placeholder='beispiel@email.com'
                register={register}
                name='email'
              />
            </div>
          </div>
        </div>
        <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
          <div className='text-orange-main text-sm'>Adresse</div>
          <div className='bg-orange-main w-full h-px'></div>
        </div>
        <div className='mx-2 mt-2'>
          <BFormAddressField
            control={control}
            fieldName='Address'
            key={Math.random()}
            placeholder={addressPlaceholder}
            latLng={{lat: +job?.lat, lng: +job?.lng}}
          />
        </div>
        <OrangeLine name='Beschreibung' />
        <IonTextarea
          maxlength={1000}
          className='input m-2 break-words text-sm'
          placeholder='Beschreibe die Aufgaben, Anforderungen und Aussichten'
          {...register('description')}
          name='description'
        />
        <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
          <div className='text-orange-main text-sm'>Datum und Statistik</div>
          <div className='bg-orange-main w-[1400px] h-px'></div>
        </div>
        <div className='flex text-sm place-content-center text-black dark:text-gray-300 h-10'>
          <div className='flex pr-11 ml-1'>
            <p className='dark:text-gray-300 pr-2'>Verfügbar bis:</p>
            <p className='dark:text-gray-300 pr-4'>
            {job?.issuedPromotedUntil
              ? new Date(job?.issuedPromotedUntil).toLocaleDateString()
              : 'Nicht eingelöst'}
            </p>
            <p className='dark:text-gray-300 pr-2'>Befördert zu:</p>
            <p className='dark:text-gray-300'>
            {job?.mapPromotedUntil
              ? new Date(job?.mapPromotedUntil).toLocaleDateString()
              : 'Nicht eingelöst'}
            </p>
          </div>
            <div className='flex place-content-end mr-1'>
            <IonIcon
              icon={star}
              className='text-yellow-400 h-4 w-5 dark:text-[#F0A249] pr-1'
            />
            <p className='pr-2 text-md'>
              {job?.countFavourite ? job?.countFavourite : 0}
            </p>
            <IonIcon
                icon={eyeOutline}
                className='h-5 w-5 pr-1'
               />
            <p>
              {job?.visitors}
            </p>
          </div>
      </div>
      </div>
      <div className='flex flex-col justify-end text-white'>
        <p className='text-yellow-accent text-center font-bold mb-5'>{apiResultMessage}</p>
      </div>
      <div>
        <UIYellowButton
          type='submit'
          text='Weiter'
        />
      </div>
    </form>
  );
};

export default JobOfferEditForm;
