import React from 'react';
import {FavoriteJob} from 'interfaces/Jobs/Jobs.interface';
import {FavoritesJobsListItem} from './FavouritesJobsListItem';

export interface Props {
  fields: FavoriteJob[];
  onJobClick: (offerId: number) => void;
}

export const FavoritesJobsList: React.FC<Props> = ({fields, onJobClick}) => {
  return (
    <div>
      {fields.map((field) => (
        <FavoritesJobsListItem
          field={field}
          onOfferClick={onJobClick}
          key={field.job.id}
        />
      ))}
    </div>
  );
};
