import React, {useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import {Link} from 'react-router-dom';
import {IonProgressBar, IonPage} from '@ionic/react';
import {accountService} from 'api/account/account.service';
import BForm from 'forms/Common/BForm/BForm';
import {formRegisterSecStage} from 'forms/Account/FormRegister/FormRegisterSecStage';
import AppLoginBackground from 'pages/Account/AccountLogin/AccountLoginBackground';
import logo from 'assets/logo.png';
import background from 'assets/background.png';

type StateProps = {
  name: string;
  email: string;
  password: string;
  type: string;
};

const AccountRegister2Page = () => {
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const location = useLocation<StateProps>();

  if (!location.state) {
    return <div>An error occurred.</div>;
  }

  const onSubmit = async (data: any) => {
    data.phoneNumber = '+43' + data.phoneNumber;
    setIsLoading(true);
    data.firstName = location.state.name;
    data.email = location.state.email;
    data.password = location.state.password;

    accountService
      .register(data)
      .then(() => {
        history.push('/registerConfirmation', {email: data.email});
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <IonPage className='bg-gray-200'>
      <div className='lg:flex lg:justify-center dark:bg-[#474747] '>
        <div className='h-screen bg-gray-200 lg:w-1/3 dark:bg-[#474747] '>
          <img
            alt='background'
            src={background}
            className='absolute w-screen h-screen z-0 blur-sm lg:hidden'
          />
          <div className='flex justify-center h-1/5 flex-col'>
            <div className='h-1/6'></div>
            <div className='flex justify-center'>
              <img
                alt='logo'
                src={logo}
                className='w-44 h-20 z-20'
              />
            </div>
          </div>
          <AppLoginBackground>
            <div className='flex justify-center flex-col'>
              {isLoading && <p>Loading...</p>}
              <b className='text-xl mt-2 mx-3 dark:text-white'>Registrierung</b>
              <div className='mx-3 mt-2'>
                <IonProgressBar value={2 / 3} />
              </div>
              <div className='mx-3'>
                <BForm
                  btnMargin={1}
                  btnText='Registrieren'
                  fields={formRegisterSecStage}
                  preloadedData={{}}
                  submit={onSubmit}
                />
                <hr className='bg-yellow-accent' />
                <p className='text-xs text-center mt-3 dark:text-white'>
                Sie haben bereits ein Konto?</p>
                <Link
                  to='/login'
                  className='text-yellow-accent w-full'
                >
                  <div
                    className='bg-white mt-1 mb-4 text-sm
              text-center rounded-lg border border-yellow-accent p-2
              dark:text-white dark:bg-[#F0A249]'
                  >
                    <b>Zum Einloggen</b>
                  </div>
                </Link>
              </div>
            </div>
          </AppLoginBackground>
        </div>
      </div>
    </IonPage>
  );
};

export default AccountRegister2Page;
