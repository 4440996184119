import React, {useEffect, useState} from 'react';
import {IonContent, IonHeader, IonPage, IonToolbar, useIonRouter} from '@ionic/react';
import UILogo from 'components/UILogo/UILogo';
import UITopTextBar from 'components/UILabels/UITopTextBar';
import {FavoriteInstrument, Instrument} from 'interfaces/Instruments/Instrument.interface';
import {FavoriteJob} from 'interfaces/Jobs/Jobs.interface';
import {FavoriteService} from 'interfaces/Services/service.interface';
import {useRecoilValue} from 'recoil';
import {accountState} from 'states/Account/accountState';
import {FavoritesServicesList} from './FavoritesOffersList';
import FavoritesInstrumentList from './FavoritesInstrumentList';
import {FavoritesJobsList} from './FavouritesJobsList';
import {FavoriteProduct, Product} from 'interfaces/Products/product.interface';
import FavoritesProductList from './FavoritesProductList';

const FavoritesPage: React.FC = () => {
  const [offers, setOffers] = useState<FavoriteService[]>();
  const [instruments, setInstruments] = useState<FavoriteInstrument[]>();
  const [jobs, setJobs] = useState<FavoriteJob[]>();
  const [products, setProducts] = useState<FavoriteProduct[]>();
  const accountData = useRecoilValue(accountState);
  const router = useIonRouter();

  useEffect(() => {
    if (accountData) {
      setOffers(accountData!.favourites?.services);
      setInstruments(accountData!.favourites?.instruments);
      setJobs(accountData!.favourites?.jobs);
      setProducts(accountData!.favourites?.products);
    }
  }, [accountData]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>Favoriten</UITopTextBar>
      </IonHeader>
      <IonContent fullscreen>
        {offers || instruments || jobs || products === [] ? (
          ''
        ) : (
          <div className='p-1 dark:text-white'>Sie haben noch keine Lieblings Angebote</div>
        )}
        {offers && (
          <FavoritesServicesList
            fields={offers}
            onOfferClick={(offerId: any) => router.push(`/service/${offerId}`)}
          />
        )}
        {instruments && (
          <FavoritesInstrumentList
            fields={instruments}
            onInstrumentClick={(instrument: Instrument) =>
              router.push(
                `/instrument/${instrument.category.name}/ + 
                ${instrument.subcategory.name}/${instrument.id}`
              )
            }
          />
        )}
        {jobs && (
          <FavoritesJobsList
            fields={jobs}
            onJobClick={(jobId: any) => router.push(`/job/${jobId}`)}
          />
        )}
        {products && (
          <FavoritesProductList
            fields={products}
            onProductClick={(product: Product) =>
              router.push(
                `/product/${product.category.name}/ + 
                ${product.subcategory.name}/${product.id}`
              )
            }
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default FavoritesPage;
