import OneSignal from 'onesignal-cordova-plugin';
import {OpenedEvent} from 'onesignal-cordova-plugin/types/Notification';
import {Storage} from '@capacitor/storage';
import {BehaviorSubject} from 'rxjs';
import {DeviceState} from 'onesignal-cordova-plugin/types/Subscription';

const OPEN_NOTIFICATIONS_REDIRECT_KEY = 'OPEN_NOTIFICATIONS_REDIRECT_KEY';
const ONESIGNAL_PLAYER_ID_KEY = 'ONESIGNAL_PLAYER_ID';

type AdditionalData = {
  id: number;
  type: string;
  name?: string;
};

export async function getNotificationRedirect() {
  const {value} = await Storage.get({key: OPEN_NOTIFICATIONS_REDIRECT_KEY});
  return value;
}

export async function clearNotificationRedirect() {
  Storage.remove({key: OPEN_NOTIFICATIONS_REDIRECT_KEY});
}

export const notificationRedirect = new BehaviorSubject('');

export function oneSignalInit(): void {
  OneSignal.setAppId('f847b12c-0746-4a48-9044-8c5e5a253a63');

  OneSignal.setNotificationOpenedHandler(function(jsonData: OpenedEvent) {
    const additionalData = jsonData.notification.additionalData as AdditionalData;
    const redirectUrl = getRedirectUrl(additionalData);
    if (redirectUrl == null) {
      return;
    }

    Storage.set({key: OPEN_NOTIFICATIONS_REDIRECT_KEY, value: redirectUrl});
    notificationRedirect.next(redirectUrl);
  });

  OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {});

  setPlayerIdToLocalStorage();
}

function getRedirectUrl({id, type, name}: AdditionalData) {
  if (id == null || type == null) {
    return null;
  }
  if (type === 'SERVICE') {
    return `/service/${id}`;
  }
  if (type === 'MESSAGE' && name != null) {
    return `/message/${id}/${name}`;
  }
  return `/job/${id}`;
}

function setPlayerIdToLocalStorage() {
  OneSignal.getDeviceState((state: DeviceState) => {
    Storage.set({key: ONESIGNAL_PLAYER_ID_KEY, value: state.userId});
  });
}

export async function getPlayerIdFromLocalStorage(): Promise<string | null> {
  const {value: playerId} = await Storage.get({key: ONESIGNAL_PLAYER_ID_KEY});
  return playerId;
}
