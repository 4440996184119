import React from 'react';
import {IonIcon} from '@ionic/react';
import {Service} from 'interfaces/Services/service.interface';
import {UIImage} from 'components/UIImages/UIImage';
import {location, logoEuro} from 'ionicons/icons';

export interface offersProps {
  services: Service[];
  isOwnService: boolean;
  onServiceClick: Function;
  type?: string;
}

export const ServicesList = ({
  services,
  isOwnService,
  onServiceClick: onOfferClick,
  type,
}: offersProps) => (
  <div className='my-5 space-y-5'>
    {services.map((service) => (
      <div
        key={service.name! + service.id}
        onClick={() => onOfferClick(service.id!)}
        className='w-[90%] md:w-2/3 max-w-screen-md mx-auto'
      >
        <div
          className={`${
            service.promoted
              ? 'shadow-custom shadow-orange-promoted border rounded-3xl border-orange-promoted'
              : 'border rounded-3xl border-yellow-accent'
          } bg-white dark:bg-[#393939] drop-shadow-md`}
        >
          <div>
            <UIImage
              photoLink={service.photos.length > 0 && service.photos[0].photo}
              customClassName='h-20 w-full rounded-t-3xl'
              type='service'
            />
            <div className='flex justify-end mr-3'>
              <div className='bg-yellow-accent text-white absolute p-2 top-16 rounded-2xl flex'>
                <IonIcon icon={logoEuro} className='text-sm' />
                <p className='text-xs'>{service.pricePerHour + 'pro Stunde'}</p>
              </div>
            </div>
          </div>
          <div className='flex w-full ml-4 mb-3 font-sans '>
            <div className='w-1/4 lg:flex lg:justify-center '>
              <UIImage
                type={type}
                customClassName='w-20 h-20 mt-3 rounded-md'
                photoLink={service.photos.length > 0 && service.photos[0].photo}
              />
            </div>
            <div className='text-gray-500 w-3/4 pl-2 pr-6 '>
              <p className='text-gray-900 text-md font-semibold mt-2
              dark:text-white'>{service.name}</p>
              <p className='text-sm font-thin truncate'>
                {service.postCode !== 'undefined' ? service.postCode : ''} {service.location}
              </p>
              <p className='text-gray-600 text-sm ml-7 dark:text-white'>
                {service.industry}
              </p>
              <div className='flex justify-between'>
                <p className='text-gray-600 text-[12px] ml-7 mb-4 dark:text-gray-300'>
                  {service.location}
                </p>
                {isOwnService && service && service.distance && (
                  <div className='flex'>
                    <IonIcon
                      icon={location}
                      className='h-4 w-4 ml-3 text-white'
                    />
                    <div className='text-xs text-white mr-3'>
                      {service.distance.toFixed() + ' '}
                      km
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
);
