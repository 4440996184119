import React from 'react';
import {IonMenu, IonIcon} from '@ionic/react';
import {RouteComponentProps, withRouter} from 'react-router';
import {chatboxOutline, personOutline, homeOutline, mapOutline, starOutline} from 'ionicons/icons';
import {Link} from 'react-router-dom';
import UIYellowButton from 'components/UIButtons/UIYellowButton';
import {Browser} from '@capacitor/browser';

interface AppBurgerMenuProps extends RouteComponentProps {
  isUnathorized?: boolean;
}

const AppBurgerMenu: React.FC<AppBurgerMenuProps> = ({isUnathorized}) => {
  return (
    <IonMenu contentId='main' className='app-menu'>
      <div
        className='pt-20 pb-10 flex flex-col justify-between
      bg-yellow-accent h-screen text-xl w-full dark:bg-[#F0A249]'>
        <div>
          <Link to='/home' className='flex p-4 items-center hover:underline text-white'>
            <IonIcon icon={homeOutline} />
            <div className='pl-2'>Home</div>
          </Link>
          <Link
            to={isUnathorized ? '/sign-in' : '/community'}
            className='flex p-4 items-center hover:underline text-white'>
            <IonIcon icon={chatboxOutline} />
            <div className='pl-2'>Angebote</div>
          </Link>
          <Link to='/map' className='flex p-4 items-center hover:underline text-white'>
            <IonIcon icon={mapOutline} />
            <div className='pl-2'>Karte</div>
          </Link>
          <Link
            to={isUnathorized ? '/sign-in' : '/favorites'}
            className='flex p-4 items-center hover:underline text-white'>
            <IonIcon icon={starOutline} />
            <div className='pl-2'>Favoriten</div>
          </Link>
          <Link
            to={isUnathorized ? '/sign-in' : '/profile'}
            className='flex p-4 items-center hover:underline text-white'>
            <IonIcon icon={personOutline} />
            <div className='pl-2'>Profil</div>
          </Link>
        </div>
        <div>
          <UIYellowButton
            text='AGB`s'
            onClick={() => Browser.open({url: 'https://app.wubmal.com/agb.html'})}
          />
          <UIYellowButton
            text='Datenschutzerklärung'
            onClick={() =>
              Browser.open({
                url: 'https://app.wubmal.com/datenschutz.html',
              })
            }
          />
          <UIYellowButton
            text='Impressum'
            onClick={() =>
              Browser.open({
                url: 'https://app.wubmal.com/impressum.html',
              })
            }
          />
        </div>
      </div>
    </IonMenu>
  );
};

export default withRouter(AppBurgerMenu);
