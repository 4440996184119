import React, {useEffect, useState} from 'react';
import {IonIcon, IonTextarea, IonSelect, IonSelectOption, useIonRouter} from '@ionic/react';
import {useForm} from 'react-hook-form';
import {instrumentsService} from 'api/instruments/instruments.service';
import {BFormAddressField} from 'forms/Common/places/BFormAddressField';
import UIInput from 'components/UIInputs/UIInput';
import {
  InstrumentRequest,
  DefaultInstrumentRequestValues,
} from 'interfaces/Instruments/Instrument.interface';
import LineColored from '../Instruments/LineColored';
import UIYellowButton from 'components/UIButtons/UIYellowButton';
import AddPhotos from 'components/UIAddPhoto/UIAddPhotos';
import IconEuro from 'assets/IconEuro.svg';
import {productsService} from 'api/products/products.service';
import {useSetRecoilState} from 'recoil';
import {refreshCounterState} from 'states/Common/CommonState';
import {eyeOutline, star} from 'ionicons/icons';
// import {dateValidation} from 'utils/dateValidation';

function FormEditProduct({productId}: any) {
  const setRefreshCount = useSetRecoilState(refreshCounterState);
  const [categories, setCategories] = useState<any[]>([]);
  const [subcategories, setSubcategories] = useState<any[]>([]);
  const [subcatId, setSubcatId] = useState<number>();
  const [catId, setCatId] = useState<number>();
  const [catValue, setCatValue] = useState<string>('Kategorie');
  const [subCatValue, setSubCatValue] = useState<string>('Unterkategorie');
  const [catDefaultId, setCatDefaultId] = useState<number>();
  const [subCatDefaultId, setSubCatDefaultId] = useState<number>();
  const [addressPlaceholder, setAddressPlaceholder] = useState<string>('Wähle einen Adresse');
  const [oldPhotos, setOldPhotos] = useState<{ photo: string; }[]>();
  const [product, setProduct] = useState<InstrumentRequest>(DefaultInstrumentRequestValues);
  const router = useIonRouter();

  const {
    handleSubmit,
    register,
    setValue,
    formState: {errors},
    control,
  } = useForm();
  const [apiResultMessage, setApiResultMessage] = useState('');

  useEffect(() => {
    productsService.getDetails(productId).then((response) => {
      setProduct(response.data);
      setCatDefaultId(response.data.category.id);
      setSubCatDefaultId(response.data.subcategory.id);
      setCatValue(response.data.category.name);
      setSubCatValue(response.data.subcategory.name);

      setValue('weekHours', response.data.weekHours);
      setValue('weekendHours', response.data.weekendHours);
      setValue('price', response.data.price);
      setValue('category', response.data.category.id);
      setValue('subcategory', response.data.subcategory.id);
      setValue('instrumentName', response.data.instrumentName);
      setValue('name', response.data.name);
      setValue('purpose', response.data.name);
      setValue('productName', response.data.productName);
      setValue('email', response.data.email);
      setValue('productionYear', response.data.productionYear);
      setValue('phoneNumber', response.data.phoneNumber);
      setValue('description', response.data.description);
      setValue('lat', response.data.lat.toString());
      setValue('lng', response.data.lng.toString());
      setValue('postCode', response.data.postCode);
      setValue('Address', response.data.Address);
      setValue('location', response.data.location);
      setValue('availability', response.data.availability);

      setOldPhotos(response.data.photos);
      setAddressPlaceholder(response.data.location);
    });
  }, []);

  useEffect(() => {
    instrumentsService.getCategories().then((response) => {
      setCategories(response.data.results);
    });
  }, []);

  useEffect(() => {
    catId &&
      instrumentsService.getSubcategories(catId).then((response) => {
        setSubcategories(response.data.results);
      });
  }, [catId]);

  const onSubmit = (data: InstrumentRequest) => {
    data.category = catId ? catId : catDefaultId;
    data.subcategory = subcatId ? subcatId : subCatDefaultId;
    data.productionYear = +data!.productionYear!;

    data.photos && (data.photos = data.photos[0]);
    if (typeof(data.photos) === 'undefined') delete data.photos;

    if (data.Address) {
      data.location = data.Address.label;
      data.postCode = data.Address.postCode;
      data.lat = data.Address.lat;
      data.lng = data.Address.lng;
      setAddressPlaceholder(data.location!);
    }

    productsService
      .updateProduct(data, productId)
      .then((res) => {
        setApiResultMessage('Erfolg!');
        setRefreshCount((v) => v + 1);
        if (!product.issued) {
          router.push(`/purchase/products/${product.id}`);
          setApiResultMessage('');
        }
      })
      .catch((error) => {
        setApiResultMessage('Error! ' + error.response.data.message[0]);
      });
  };

  return (
    <form
      className='h-full pb-3 '
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='border-2 border-orange-main text-black bg-white rounded-md dark:bg-[#393939]'>
        <div className='h-6 bg-yellow-accent dark:bg-[#F0A249]'></div>
        <div className='flex my-4 bg-white dark:bg-[#393939]'>
          <div className='flex-col mx-2 w-2/5 space-y-2 text-sm'>
            <div className='flex-col w-2/5 space-y-2 text-sm mb-16'>
              <AddPhotos
                photosType={'products'}
                photos={oldPhotos}
                setPhotos={setOldPhotos}
                control={control}
                name='photos'
              />
            </div>
            <div>
              <p className='dark:text-white'>Preis:</p>
              <div className='flex'>
                <UIInput
                  customClassName='bg-white w-full text-sm dark:bg-[#393939]  dark:text-white  '
                  type='number'
                  placeholder='80'
                  name='price'
                  register={register}
                  validation={{required: true}}
                  errors={errors}
                />
                <IonIcon
                  icon={IconEuro}
                  className='mt-0.5 text-gray-500 dark:text-white'
                />
              </div>
              <LineColored error={errors.price} />
            </div>
            <div>
              <p className='dark:text-white'>Baujahr: </p>
              <UIInput
                customClassName='dark:bg-[#393939]  dark:text-white '
                name='productionYear'
                type='number'
                placeholder='Jahr'
                register={register}
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.productionYear} />
            </div>
          </div>
          <div className='flex-col space-y-2 mr-[15px] w-3/5 text-sm'>
            <div className='space-y-2 mb-2'>
              <div
                className='w-44 h-6 p-4 flex items-center justify-between
              rounded-xl border-orange-main border text-orange-main'
              >
                <IonSelect
                  className='text-black dark:text-white'
                  okText='Weiter'
                  cancelText='Abbrechen'
                  placeholder={catValue}
                  onIonChange={(cat) => setCatId(cat.detail.value)}
                  value={catId}
                >
                  {categories.map((cat) => (
                    <IonSelectOption
                      key={cat.id}
                      value={cat.id}
                    >
                      {cat.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </div>
              <div
                className='w-44 h-6 p-4 flex items-center justify-between
              rounded-xl border-orange-main border text-orange-main'
              >
                <IonSelect
                  okText='Weiter'
                  cancelText='Abbrechen'
                  className='text-black dark:text-white'
                  placeholder={subCatValue}
                  onIonChange={(cat) => setSubcatId(cat.detail.value)}
                  value={subcatId}
                >
                  {subcategories.map((subcat) => (
                    <IonSelectOption
                      key={subcat.id}
                      value={subcat.id}
                    >
                      {subcat.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </div>
            </div>
            <div>
              <p className='dark:text-white'>Name:</p>
              <UIInput
                customClassName='dark:bg-[#393939]  dark:text-white '
                name='productName'
                placeholder='Mischmaschine'
                required={'required'}
                register={register}
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.instrumentName} />
            </div>
            <div>
              <p className='dark:text-white'>Geräte Bezeichnung:</p>
              <UIInput
                customClassName='dark:bg-[#393939]  dark:text-white '
                name='purpose'
                placeholder='Zweck'
                required={'required'}
                register={register}
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.purpose} />
            </div>
            <div>
              <p className='dark:text-white'>Kontakt:</p>
              <UIInput
                customClassName='dark:bg-[#393939]  dark:text-white '
                required='required'
                placeholder='beispiel@email.com'
                register={register}
                name='email'
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.email} />
            </div>
            <div>
              <p className='dark:text-white'>Telefonnummer:</p>
              <UIInput
                customClassName='dark:bg-[#393939]  dark:text-white '
                type='tel'
                placeholder='0660 1234567'
                register={register}
                name='phoneNumber'
                validation={{required: true}}
                errors={errors}
                maxLength={16}
                minLength={9}
              />
              <LineColored error={errors.phoneNumber} />
            </div>
            <div className='text-yellow-accent text-center font-bold mb-5 clear-both'>
              {apiResultMessage}
            </div>
          </div>
        </div>
        <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
          <div className='text-orange-main text-sm'>Adresse</div>
          <div className='bg-orange-main w-full h-px'></div>
        </div>
        <div className='mx-2 mt-2'>
          <BFormAddressField
            control={control}
            fieldName='Address'
            key={Math.random()}
            placeholder={addressPlaceholder}
            latLng={{lat: +product.lat, lng: +product.lng}}
          />
        </div>
        <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
          <div className='text-orange-main text-sm'>Beschreibung</div>
          <div className='bg-orange-main w-4/5 h-px'></div>
        </div>
        <IonTextarea
          rows={4}
          className='ipnut mx-2 text-sm h-[100px] dark:text-white'
          {...register('description')}
          placeholder='230 V Mischmaschine mit 140 Liter Fassungsvermögen
          in sehr guten Zustand inkl 10m Anschlusskabel'
          value={product?.description}
          name='description'
        />
        <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
              <div className='text-orange-main text-sm'>Datum und Statistik</div>
              <div className='bg-orange-main w-[1400px] h-px'></div>
            </div>
            <div className='flex text-sm place-content-center dark:text-gray-300 h-10'>
              <div className='flex pr-11 ml-1'>
                <p className='dark:text-gray-300 pr-2'>Verfügbar bis:</p>
                <p className='dark:text-gray-300 pr-4'>
                  {product.issuedPromotedUntil
              ? new Date(product.issuedPromotedUntil).toLocaleDateString()
              : 'Nicht eingelöst'}
              asdasda
                </p>
                <p className='dark:text-gray-300 pr-2'>Befördert zu:</p>
                <p className='dark:text-gray-300'>
                  {product.mapPromotedUntil
              ? new Date(product.mapPromotedUntil).toLocaleDateString()
              : 'Nicht eingelöst'}
              essa
                </p>
              </div>
              <div className='flex place-content-end mr-1'>
              <IonIcon
                icon={star}
                className='text-yellow-400 h-4 w-5 dark:text-[#F0A249] pr-1'
              />
              <p className='pr-2 text-md'>
                {product.countFavourite ? product.countFavourite : 0}
              </p>
              <IonIcon
                  icon={eyeOutline}
                  className='h-5 w-5 pr-1'
                />
                <p>
                {product.visitors}
                </p>
              </div>
            </div>
      </div>
      {apiResultMessage !== 'Erfolg!' && (
        <div className='flex flex-col py-5 justify-end text-white'>
          <UIYellowButton
            type='submit'
            text='Weiter'
          />
        </div>
      )}
    </form>
  );
}

export default FormEditProduct;
