import React from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import UILogo from 'components/UILogo/UILogo';
import UITopTextBar from 'components/UILabels/UITopTextBar';
import UIButtonBack from 'components/UIButtons/UIButtonBack';

import {PromotionsCostsList} from
'pages/Promotions/PromotionsCostsList/PromotionsCostsList';

export const PurchasePage = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>Angebot erstellen</UITopTextBar>
      </IonHeader>
      <IonContent>
          <PromotionsCostsList
              prefix='Hinzufügen eines Angebots für'
              suffix='Tage'
            />
      </IonContent>
    </IonPage>
  );
};
