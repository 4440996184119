import React from 'react';

interface Props {
  text: string;
  onClick?: any;
  type?: 'button' | 'reset' | 'submit' | undefined;
  customClassName?: string;
}

const UIYellowButton: React.FC<Props> = ({text, onClick, type, customClassName}) => {
  return (
    <div
      onClick={onClick}
      className='border border-yellow-accent text-center
       bg-yellow-accent mx-4 py-2 rounded-xl text-sm dark:bg-[#F0A249] dark:border-[#F0A249]'
    >
      <button type={type} className={`h-full w-full text-white ${customClassName}`}>
        <b>{text}</b>
      </button>
    </div>
  );
};

export default UIYellowButton;
