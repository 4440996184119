import React from 'react';
import UILogo from 'components/UILogo/UILogo';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  useIonAlert,
  useIonRouter,
} from '@ionic/react';
import {Browser} from '@capacitor/browser';
import FormProfileEdit from 'forms/Account/FormProfile/FormProfile';
import UIYellowButton from 'components/UIButtons/UIYellowButton';
import UIButtonBack from 'components/UIButtons/UIButtonBack';
import UITopTextBar from 'components/UILabels/UITopTextBar';
import {authService} from 'api/account/auth.service';
import {useHistory} from 'react-router';
import {accountService} from 'api/account/account.service';

const AccountEditPage: React.FC = () => {
  const router = useIonRouter();
  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const handleDeleteUser = () => {
    presentAlert({
      header: 'Sind Sie sicher, dass Sie Ihr Konto löschen wollen?',
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Nicht',
          cssClass: 'alert-button-cancel',
        },
        {
          text: 'Ja',
          cssClass: 'alert-button-confirm',
          handler: async () => {
            try {
            await accountService.deleteCurrentUser();
            authService.logout();
            history.replace('/');
            } catch (error) {
              console.log(error);
            }
          },
        },
      ],
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>Profil Einstellungen</UITopTextBar>
      </IonHeader>
      <IonContent className='bg-white'>
        <div>
          <div className='flex justify-center'>
            <FormProfileEdit />
          </div>
          <div className='flex justify-center'>
            <div className='lg:w-1/3 w-full mb-5 space-y-3'>
              <UIYellowButton
                text='E-Mail Benachrichtigungen'
                onClick={() => router.push(`/email-notifications`)}
              />
              <UIYellowButton
                text='AGB`s'
                onClick={() =>
                  Browser.open({url: 'https://app.wubmal.com/agb.html'})
                }
              />
              <UIYellowButton
                text='Datenschutzerklärung'
                onClick={() =>
                  Browser.open({
                    url: 'https://app.wubmal.com/datenschutz.html',
                  })
                }
              />
              <UIYellowButton
                text='Impressum'
                onClick={() =>
                  Browser.open({
                    url: 'https://app.wubmal.com/impressum.html',
                  })
                }
              />
              <UIYellowButton
                text='Ausloggen'
                onClick={() => {
                  authService.logout();
                  history.replace('/');
                }}
              />
              <div className='pt-5'>
                <UIYellowButton
                  text='Konto löschen'
                  onClick={handleDeleteUser}
                />
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AccountEditPage;
