import React from 'react';

interface Props {
  children: any;
}

const UIResponsivePadding: React.FC<Props> = ({children}) => (
  <div className='md:px-40'>
    {children}
  </div>
);

export default UIResponsivePadding;
