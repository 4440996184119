import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {IonIcon} from '@ionic/react';
import {
  // call,
  flag,
  homeOutline,
  location,
  // mailOutline,
} from 'ionicons/icons';
import IconEuro from 'assets/IconEuro.svg';
// import RatingStars from 'pages/Common/RatingStars/RatingStars';
import {Service} from 'interfaces/Services/service.interface';
import {servicesService} from 'api/services/services.service';
import {OrangeLine} from 'components/UIContainer/UIOrangeLine';
import {distanceCalculator} from 'utils/distanceCalculator';
import FavouritesButton from 'pages/Common/Favourites/FavouritesButton';
import UICalendar from 'components/UICalendar/UICalendar';
import {geolocationState} from 'states/Common/CommonState';
import {useRecoilValue} from 'recoil';
import {UIClickImage} from 'components/UIImages/UIClickImage';
import {Link} from 'react-router-dom';
import UIButtonShare from 'components/UIButtons/UIButtonShare';
import BMiniMap from 'forms/Common/places/BMiniMap';

interface Props {
  fields: Service;
  defaultFavorite: boolean;
  setDate: any;
  setIsPopUpVisible: Dispatch<SetStateAction<boolean>>;
}

export const DetailsOffer: React.FC<Props> = ({
  fields,
  defaultFavorite,
  setDate,
  setIsPopUpVisible,
}) => {
  const [isFavorite, setIsFavorite] = useState(defaultFavorite);
  const geolocation = useRecoilValue(geolocationState);

  useEffect(() => setIsFavorite(defaultFavorite), [defaultFavorite]);

  const favHandler = (id: number) => {
    if (!isFavorite) {
      servicesService.addFavoriteService(id);
      return;
    }
    servicesService.deleteFavoriteService(id);
  };

  return (
    <div
      className='rounded-lg border border-yellow-accent mx-4 mt-3 mb-5
      bg-yellow-slight drop-shadow-md z-20 dark:bg-[#393939]'
    >
      <div className='h-9 bg-yellow-accent rounded-t-lg w-full flex justify-between
      dark:bg-[#F0A249]'>
        <div className='flex text-white'>
          <IonIcon
            icon={location}
            className='h-6 w-6 mt-1.5 ml-3'
          />
          <p className='text-sm mt-2'>
            {distanceCalculator(fields.lat, fields.lng, geolocation).toFixed() + ' '}
            km
          </p>
        </div>
        <div className='flex'>
          <IonIcon
            icon={IconEuro}
            className='h-4 mt-2.5 ml-3 text-white'
          />
          <p className='mr-2 text-sm mt-2 text-white'>{fields.pricePerHour} / h</p>
        </div>
      </div>
      <div className='flex font-sans text-gray-500'>
        <div className='w-2/5 ml-1 space-x-2 mr-2'>
          <UIClickImage
            customClassName='mx-4 h-28 w-28 my-3 rounded-md z-50'
            type='services'
            photoLink={fields.photos.length > 0 && fields.photos[0]}
            setIsPopUpVisible={setIsPopUpVisible}
          />
          <div className='flex space-x-1'>
            {fields.photos > 0 && fields.photos.map((image: any) => (
              <UIClickImage
                key={image}
                customClassName='h-8 w-8 my-3 rounded-md z-10'
                type='services'
                photoLink={image}
                setIsPopUpVisible={setIsPopUpVisible}
              />
            ))}
          </div>
        </div>
        <div className='w-3/5 ml-3 text-'>
          <p className='text-gray-600 text-lg font-bold mt-2
          dark:text-white'>{fields.name}</p>
          <p className='font-thin my-1 dark:text-white'>
            {fields.postCode}, {fields.street}
          </p>
          <b className='text-gray-600 font-semibold float-left dark:text-white'>
            Terminvereinbarung nach Absprache
          </b>
          <p className='float-left mt-2 dark:text-white'>Preis pro Stunde: </p>
          <p className='text-sm text-yellow-accent float-left ml-2 mt-2
          dark:text-white'>{fields.pricePerHour}</p>
          <IonIcon
            icon={IconEuro}
            className='text-yellow-accent text-xs mt-3 dark:text-white'
          />
          <div className='flex justify-end mt-2 mr-2'>
            {/* <div className='mt-1'>
              <RatingStars />
            </div> */}
            <div className='text-black space-x-2 flex mr-3 ml-5 '>
              <Link to={`/report/service/${fields.id}`}>
                {' '}
                <IonIcon
                  icon={flag}
                  className='mt-1 h-6 w-6'
                />
              </Link>
              <FavouritesButton
                favHandler={favHandler}
                fields={fields}
                isFavorite={isFavorite}
                setIsFavorite={setIsFavorite}
              />
              <UIButtonShare />
            </div>
          </div>
        </div>
      </div>
      <OrangeLine name='Kontakte' />
      <div className='text-gray-500 ml-4 mr-8 space-y-2 dark:text-white'>
        {/* <div className='flex'>
          <IonIcon icon={call} className='mt-0.5' />
          <p className='text-sm ml-2 '>{fields.phoneNumber}</p>
        </div> */}
        <div className='flex'>
          <IonIcon
            icon={homeOutline}
            className='h-5 w-5'
          />
          <p className='text-sm ml-2'>
            {fields.postCode} {fields.location}
          </p>
        </div>
        {/* <div className='flex text-gray-500'>
          <IonIcon icon={mailOutline} className='h-5 w-5 mr-3' />
          <p className='text-sm'>{fields.email}</p>
        </div> */}
      </div>
      <div className='mx-3'>
        <BMiniMap geolocation={{lat: +fields.lat, lng: +fields.lng}} />
      </div>
      <OrangeLine name='Verleihtag auswählen' />
      <UICalendar onDatesSet={setDate} />
      <OrangeLine name='Beschreibung' />
      <div className='text-gray-400 mt-2 text-sm flex justify-between mx-3'>
        <p className='mx-2 mb-12 text-gray-500 break-all dark:text-white'>{fields.description}</p>
      </div>
    </div>
  );
};
