import React, {useState} from 'react';
import {IonPage} from '@ionic/react';
import {Link} from 'react-router-dom';

import {authService} from 'api/account/auth.service';
import {userService} from 'api/users/users.service';
import AppLoginBackground from 'pages/Account/AccountLogin/AccountLoginBackground';
import {formLogin} from 'forms/Account/FormLogin/FormLogin';
import BForm from 'forms/Common/BForm/BForm';

import logo from 'assets/logo.png';
import background from 'assets/background.png';

const AccountLoginPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [shouldClearErrors, setClearErrors] = useState(true);
  const [errors, setErrors] = useState<any[]>([]);
  const [isPasswordForgotten, setisPasswordForgotten] = useState(false);

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      await authService.login(data);
      await userService.updateUserPlayerId();
    } catch (error) {
      console.log(error);
      setisPasswordForgotten(true);
      setErrors([
        {
          name: 'email',
          message: 'E-Mail oder Passwort ist ungültig',
        },
        {
          name: 'password',
          message: 'E-Mail oder Passwort ist ungültig',
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <IonPage className='bg-gray-200'>
      <div className='lg:flex lg:justify-center dark:bg-[#474747]'>
        <div className='h-screen bg-gray-200 lg:w-1/3 dark:bg-[#474747]'>
          <img
            alt='background'
            src={background}
            className='absolute w-screen h-screen z-0 blur-sm lg:hidden'
          />
          <div className='flex justify-center h-2/6 flex-col'>
            <div className='h-1/6' />
            <div className='flex justify-center'>
              <img
                alt='logo'
                src={logo}
                className='w-52 h-24 z-20'
              />
            </div>
          </div>
          <AppLoginBackground>
            <div>
              {isLoading && <p>Loading...</p>}
              <p className='text-xl mt-4 mx-3 font-bold dark:text-white'>Anmeldung</p>
              <div className='mx-3'>
                <BForm
                  btnMargin={1}
                  btnText='Anmelden'
                  fields={formLogin}
                  preloadedData={{}}
                  submit={onSubmit}
                  formErrors={errors}
                  shouldClearErrors={shouldClearErrors}
                />
              </div>
              {isPasswordForgotten && (
                <div>
                  <hr className='bg-yellow-accent mx-6 my-2' />
                  <p className='text-xs text-center'>
                    Hast du dein Passwort vergessen?
                  </p>
                  <Link
                    to='/passwordRecovery'
                    className='text-yellow-accent w-full'>
                    <p className='text-xs text-center mx-1'>
                      <u>
                        Überprüfen Sie, wie Sie den Zugriff <br />{' '}
                        wiederherstellen können
                      </u>
                    </p>
                  </Link>
                </div>
              )}
              <hr className='bg-yellow-accent mx-6 my-2' />
              <p className='text-xs text-center mt-4 dark:text-white'>
                Sie haben noch keinen Account?
              </p>
              <Link
                onClick={() => setClearErrors(!shouldClearErrors)}
                to='/register'
                className='text-yellow-accent w-full'>
                <div
                  className='bg-white mt-2 mb-6 mx-4 text-sm
            text-center rounded-lg border border-yellow-accent p-2
            dark:border-[#F0A249] dark:text-white dark:bg-[#F0A249]'>
                  <b>Registrieren</b>
                </div>
              </Link>
            </div>
          </AppLoginBackground>
        </div>
      </div>
    </IonPage>
  );
};

export default AccountLoginPage;
