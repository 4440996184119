import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import UITopTextBar from 'components/UILabels/UITopTextBar';
import UILogo from 'components/UILogo/UILogo';
import React from 'react';
import buyToolsImg from 'assets/buy_tools.svg';
import sellToolsImg from 'assets/sell_tools.svg';
import buyMachineryImg from 'assets/buy_machinery.svg';
import sellMachineryImg from 'assets/sell_machinery.svg';
import UIOrangeSquareBox from 'components/UIContainer/UIOrangeSquareBox';
import UIButtonBack from 'components/UIButtons/UIButtonBack';
import UIButtonSearch from 'components/UIButtons/UIButtonSearch';
import UIResponsivePadding from 'components/UIResponsive/UIResponsivePadding';

const ShopPage: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
          <IonButtons slot='end'>
            <UIButtonSearch itemsType='products' />
          </IonButtons>
        </IonToolbar>
        <UITopTextBar>KAUFEN/VERKAUFEN</UITopTextBar>
      </IonHeader>
      <IonContent>
        <UIResponsivePadding>
          <div className='flex flex-wrap mt-10'>
            <UIOrangeSquareBox
              name={'WERKZEUG KAUFEN'}
              src={buyToolsImg}
              alt={'BuyTools'}
              linkTo={'/instruments/categories/1/buy'}
            />
            <UIOrangeSquareBox
              name={'WERKZEUG VERKAUFEN'}
              src={sellToolsImg}
              alt={'SellTools'}
              linkTo={'/products-add'}
            />
            <UIOrangeSquareBox
              name={'BAUMASCHINE KAUFEN'}
              src={buyMachineryImg}
              alt={'BuyMachinery'}
              linkTo={'/instruments/categories/2/buy'}
            />
            <UIOrangeSquareBox
              name={'BAUMASCHINE VERKAUFEN'}
              src={sellMachineryImg}
              alt={'SellMachinery'}
              linkTo={'/products-add'}
            />
          </div>
        </UIResponsivePadding>
      </IonContent>
    </IonPage>
  );
};

export default ShopPage;
