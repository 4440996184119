import React from 'react';

type Props = {
  leftComponent?: any;
  children?: any;
  customClassName?: string;
  customTextClassName?: any;
  rightComponent?: any;
};

const UITopTextBar = ({
  children,
  leftComponent,
  customClassName,
  customTextClassName,
  rightComponent,
}: Props) => {
  return (
    <div
      className={
        customClassName
          ? customClassName
          : `w-full bg-gray-600 h-10 flex justify-between z-10 dark:bg-[#5B6267]`
      }
    >
      {leftComponent}
      <div
        className={
          customTextClassName
            ? customTextClassName
            : 'absolute left-1/2 transform -translate-x-1/2 mt-1.5'
        }
      >
        <b className='text-sm text-white'>{children}</b>
      </div>
      {rightComponent}
    </div>
  );
};

export default UITopTextBar;
