import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonIcon,
  IonButtons,
} from '@ionic/react';
import UILogo from 'components/UILogo/UILogo';
import React, {useState, useEffect, useRef} from 'react';
import {postMessage, getMessages, createChat} from 'api/chat/chat.service';
import {useParams} from 'react-router';
import {send} from 'ionicons/icons';
// import {attach} from 'ionicons/icons';
import UITopTextBar from 'components/UILabels/UITopTextBar';
import UIButtonBack from 'components/UIButtons/UIButtonBack';
import {useSetRecoilState, useRecoilValue, useResetRecoilState} from 'recoil';
import {datesTypeState, selectedDatesState} from 'states/Messages/messageState';
import UILoadingIndicator from 'components/UILoading/UILoadingIndicator';
import {refreshCounterState} from 'states/Common/CommonState';
import {dateHandler} from 'utils/dateHandler';
import {accountState} from 'states/Account/accountState';

export const ChatDetail = () => {
  const {type, id: receiverId, itemName, conversationId}: any = useParams();
  const resetDates = useResetRecoilState(selectedDatesState);
  const dates = useRecoilValue(selectedDatesState);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const setRefreshCounter = useSetRecoilState(refreshCounterState);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const datesType = useRecoilValue(datesTypeState);
  const [mesClass, setMesClass] = useState('opacity-0');
  const [chatId, setChatId] = useState(conversationId);
  const accountData = useRecoilValue(accountState);
  // const [time, setTime] = useState(Date.now());

  useEffect(() => {
    if (dates) sendDates();
      setIsLoading(true);
      fetchMessages(receiverId).finally(() => {
        const timer = setTimeout(() => {
          messagesEndRef.current?.scrollIntoView({behavior: 'auto'});
          setMesClass('');
        }, 500);
        setIsLoading(false);
        return () => clearTimeout(timer);
      });
  }, [chatId, refreshCounterState]);

  useEffect(() => {
    const interval = setInterval(() => fetchMessages(receiverId), 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const sendDates = async () => {
    let mes = '';
    if (dates?.length !== 0) {
      mes =
        datesType === 'instruments'
          ? 'ist das Gerät am '
          : 'hätten Sie Zeit von ';
      dates!.forEach((date) => {
        mes += `${new Date(date.name).toLocaleDateString()} `;
      });
      mes += datesType === 'instruments' ? 'verfügbar' : '';
    } else if (dates?.length == 0) {
      mes;
    }

    try {
      await postMessage({message: mes}, receiverId, type, chatId).then(() =>
        setRefreshCounter((prev) => prev + 1),
      );
      fetchMessages(receiverId);
    } catch (error) {
      console.log(error);
    }
    setMessage('');
    resetDates();
  };

  const fetchMessages = async (receiverId: any) => {
    try {
      const response = await getMessages(receiverId, type, chatId);
      setMessages(response.data.messages);
      messagesEndRef.current?.scrollIntoView({behavior: 'auto'});
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendMessage = async () => {
    if (chatId === '0') {
      try {
        await createChat({message}, receiverId, type).then((res) =>
          setChatId(res.data.id),
        );
        fetchMessages(receiverId);
        setRefreshCounter((prev) => prev + 1);
      } catch (error) {}
      setMessage('');
      return;
    }

    try {
      await postMessage({message}, receiverId, type, chatId);
      fetchMessages(receiverId);
      setRefreshCounter((prev) => prev + 1);
    } catch (error) {
      console.log(error);
    }
    setMessage('');
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>{itemName}</UITopTextBar>
      </IonHeader>
      <IonContent>
        {isLoading ? (
          <UILoadingIndicator />
        ) : (
          <div className='h-full'>
            <div
              className={`flex overflow-y-scroll h-full flex-col-reverse ${mesClass}`}
            >
              <div
                ref={messagesEndRef}
                className='bg-white h-16 flex items-center w-full
                justify-center pb-5 dark:bg-[#393939] dark:pt-5'
              >
                <input
                  className='bg-orange-200 text-[#393E46] h-8 w-3/4 px-2 rounded-lg'
                  type='text'
                  placeholder='Text…'
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  value={message}
                />
                <button
                  className='w-8 h-8 ml-1 rounded-xl bg-orange-400'
                  onClick={handleSendMessage}
                >
                  <IonIcon className='text-white' icon={send} />
                </button>
              </div>
              <div className='h-full' />
              {messages &&
                messages.map((data: any) => (
                  <>
                    {data.sender.id == accountData?.id ? (
                      <div key={data.id} className='flex items-end flex-col'>
                        <div
                          className='bg-orange-300 text-[#393E46] p-2 rounded-2xl
                         w-fit ml-3 mr-1 mt-3'
                        >
                          {data.message}
                        </div>
                        <p className='p-2 text-xs '>
                          {dateHandler(data.dateSent, '', true)}
                        </p>
                      </div>
                    ) : (
                      <div key={data.id} className='flex flex-col'>
                        <div className='bg-gray-300 rounded-2xl w-fit p-2 mr-3 ml-1 mt-3'>
                          {data.message}
                        </div>
                        <p className='p-2 text-xs '>
                          {dateHandler(data.dateSent, '', true)}
                        </p>
                      </div>
                    )}
                  </>
                ))}
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};
