import React from 'react';
import {UIImage} from 'components/UIImages/UIImage';
import {IonIcon, useIonRouter} from '@ionic/react';
import {location} from 'ionicons/icons';
import IconEuro from 'assets/IconEuro.svg';
import greenCircle from 'assets/temp/greenCircle.png';
import IconLocalization from 'assets/IconLocalization.svg';
import {distanceCalculator} from 'utils/distanceCalculator';
import {geolocationState} from 'states/Common/CommonState';
import {useRecoilValue} from 'recoil';

interface SearchResultProps {
  items: any[];
}
export const SearchMapResults: React.FC<SearchResultProps> = ({items}) => {
  const router = useIonRouter();
  const geolocation = useRecoilValue(geolocationState);
  items.forEach((item: any) => {
    item.distance = distanceCalculator(item.lat, item.lng, geolocation);
  });
  items.sort((a: any, b: any) => (a.distance! > b.distance! ? 1 : -1));
  const handleItemClicked = (item: any) => {
    if (item.pricePerDay) {
      router.push(
        `/instrument/${item.category.name}/${item.subcategory.name}/${item.id}`,
      );
      return;
    }
    if (item.pricePerHour) {
      router.push(`/service/${item.id}`);
      return;
    }
    if (item.salary) {
      router.push(`/job/${item.id}`);
      return;
    }
  };

  const getType = (item: any) => {
    if (item.pricePerDay) {
      return 'instruments';
    }
    if (item.pricePerHour) {
      return 'services';
    }
    if (item.salary) {
      return 'jobs';
    }
  };

  return (
    <div>
      {items &&
        items.map((item) => (
          <div key={item.id} onClick={() => handleItemClicked(item)}>
            <div
              className='rounded-xl border border-yellow-accent
            mx-6 my-3 bg-white drop-shadow-md'
            >
              <div className='h-8 bg-yellow-accent w-full rounded-t-lg flex justify-between'>
                <div className='flex'>
                  <IonIcon
                    icon={location}
                    className='h-6 w-6 mt-1 ml-3 text-white'
                  />
                  <p className='text-sm mt-1 text-white'>
                    {item.distance.toFixed() + ' '}
                    km
                  </p>
                </div>
                {item.availability ? (
                  <img
                    alt='image'
                    src={greenCircle}
                    className='h-4 mt-2 mr-3'
                  />
                ) : (
                  ''
                )}
              </div>
              <div className='flex ml-4 mb-3 font-sans'>
                <div className=' w-1/4 lg:flex lg:justify-center'>
                  <UIImage
                    photoLink={item.images[0]}
                    type={getType(item)}
                    customClassName='mr-3 h-20 w-20 mt-3 mb-2 rounded-md'
                  />
                </div>
                <div className='w-3/4'>
                  <p className='text-gray-600 pl-2 text-md font-bold mt-1'>
                    {item.instrumentName
                      ? item.instrumentName
                      : item.name}
                  </p>
                  <p className='text-gray-500 pl-2 text-sm break-all'>
                    {item.description}
                  </p>
                  <div className='flex'>
                    <IonIcon
                      icon={IconLocalization}
                      className='h-3 mt-2 ml-3'
                    />
                    <p className='text-gray-400 text-xs mt-1 pl-1'>
                      {item.location}
                    </p>
                  </div>
                  <div className='flex'>
                    <IonIcon icon={IconEuro} className='h-3 mt-2 ml-3' />
                    <p className='text-gray-400 pl-1 text-xs mt-1'>
                      {item.pricePerDay && (
                        <p>{item.pricePerDay} euro / pro Tag</p>
                      )}
                      {item.pricePerHour && (
                        <p>{item.pricePerHour} euro / pro Stunde</p>
                      )}
                      {item.salary && <p>{item.salary} brutto / Monat</p>}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
