import {atom} from 'recoil';
import {User} from 'interfaces/Users/user.interface';

export const accountState = atom<User | undefined>({
  key: 'userState',
  default: undefined,
});

export const refreshTokenAtom = atom({
  key: 'refresh',
  default: '',
});

export const accessTokenAtom = atom({
  key: 'access',
  default: '',
});
