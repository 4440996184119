import React from 'react';
import logo from 'assets/logo.png';
import {useIonRouter} from '@ionic/react';

const UILogo = ({customClassName}: {customClassName?: string}) => {
  const router = useIonRouter();
  return (
    <div
      onClick={() => router.push('/home')}
      className={customClassName ? customClassName : `w-full flex justify-center`}
    >
      <img
        alt='logo'
        src={logo}
        className='w-24 h-[40px] md:w-56 md:h-24 md:hover:scale-110
        transition duration-300 ease-in-out'
      />
    </div>
  );
};

export default UILogo;
