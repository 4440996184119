import client from 'api/common/client';
import {InstrumentRequest} from 'interfaces/Instruments/Instrument.interface';
import {createFormData} from 'utils/formDataHelper';

const getProducts = async (subCatId: number) => {
  const response = await client.get(`/products?subcategory=${subCatId}`);
  return response;
};

const addProducts = async (data: InstrumentRequest) => {
  const formData = createFormData(data);
  const response = await client.post('products', formData);
  return response;
};

const getDetails = async (instrumentId: number) => {
  const response = await client.get(`/products/${instrumentId}`);
  return response;
};

const addFavoriteProducts = async (id: number) => {
  const response = await client.post(`/favourites/product/${id}`);
  return response;
};

const deleteFavoriteProducts = async (id: number) => {
  const response = await client.delete(`/favourites/product/${id}`, {
    data: id,
  });
  return response;
};

const deleteProduct = async (id: number) => {
  const response = await client.delete(`/products/${id}`, {
    data: {id},
  });
  return response;
};

const getAll = async () => {
  const response = await client.get('/products/');
  return response;
};

const search = async (q: string, category: string) => {
  const response = await client.get(`/products?q=${q}&category=${category}`);
  return response.data;
};

const getAvailablePromotions = async (id: number) => {
  const response = await client.get(`/payments/promotions/`);
  return response;
};

const updateProduct = async (product: any, productId: any) => {
  const formData = createFormData(product);
  const response = client.patch(`products/${productId}/`, formData);
  return response;
};

const updatePhotoProduct = async (data: any) => {
  const fd = new FormData();
  fd.append('image', data);

  return client.patch('products', fd);
};

export const productsService = {
  updateProduct,
  updatePhotoProduct,
  getProducts,
  addProducts,
  getDetails,
  addFavoriteProducts,
  deleteFavoriteProducts,
  deleteProduct,
  getAll,
  search,
  getAvailablePromotions,
};
