import {isPlatform} from '@ionic/core';
import {Camera, CameraResultType, CameraSource} from '@capacitor/camera';
import addphoto from 'assets/add-photo.png';
import {UIImage} from 'components/UIImages/UIImage';
import React, {useRef} from 'react';
import {ONE_MB_IN_BYTES} from 'utils/constants';
import {base64ToFile, urlToFile} from 'utils/base64ToBlobConverter';
import {useIonActionSheet} from '@ionic/react';

interface AddPhotosInnerProps {
  onPhotoAdd: (...event: any[]) => void;
  addedPhots: File[] | any;
  availableExtensions?: string[];
  oldPhotos?: {photo: string}[];
  setOldPhotos?: any;
  photosType?: string;
}

const AddPhotosInner: React.FC<AddPhotosInnerProps> = ({
  onPhotoAdd,
  addedPhots: addedPhotos,
  oldPhotos,
  setOldPhotos,
  photosType,
  availableExtensions = ['image/jpg', 'image/jpeg', 'image/png'],
}) => {
  const imageInput = useRef<any>(null);
  const [present] = useIonActionSheet();

  const handleAndroidCamera = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      source: CameraSource.Camera,
      resultType: CameraResultType.Base64,
    });
    if (!image) return;
    const file = base64ToFile(image.base64String!);

    handleChange([file]);
  };

  const handleAndroidGallery = async () => {
    const images = await Camera.pickImages({
      quality: 90,
      limit: 4,
    });
    if (!images.photos) return;

    const files: File[] = [];

    for (let i = 0; i < images.photos.length; i++) {
      const file = await urlToFile(
        images.photos[i].webPath!,
        images.photos[i].format,
      );
      files.push(file);
    }

    handleChange([...files]);
  };

  const handleChange = (files: FileList | any) => {
    if (!files) {
      return;
    }

    let photos: any = [];

    for (let i = 0; i < files.length; i++) {
      const availableSizeInMb = 20;

      if (files[i].size > availableSizeInMb * ONE_MB_IN_BYTES) {
        // eslint-disable-next-line no-alert
        alert('Die Datei ist zu groß!');
        return;
      }

      photos = [...photos, files[i]];
      if (i == 3) {
        break;
      }
    }

    onPhotoAdd(photos);

    imageInput.current.value = null;

    setOldPhotos && setOldPhotos(undefined);
  };

  return (
    <>
      <input
        type='file'
        onChange={(event) => handleChange(event.currentTarget.files!)}
        ref={imageInput}
        className='filetype'
        style={{display: 'none'}}
        accept={availableExtensions.join(', ')}
        multiple
      />
      <div
        className='border border-yellow-accent rounded-xl h-[110px]
    w-[110px]'
      >
        <button
          className='h-full w-full'
          type='button'
          onClick={() =>
            isPlatform('android')
              ? present([
                  {
                    text: 'Fotomediathek',
                    handler: () => handleAndroidGallery(),
                  },
                  {
                    text: 'Foto aufnehmen',
                    handler: () => handleAndroidCamera(),
                  },
                ])
              : imageInput.current?.click()
          }
        >
          <div className='flex justify-center'>
            {!addedPhotos && !oldPhotos && (
              <img alt='addphoto' src={addphoto} className='h-8 w-9' />
            )}
          </div>
          {oldPhotos && oldPhotos.length > 0 && !addedPhotos && (
            <UIImage
              type={photosType}
              customClassName='h-[110px] w-[110px] rounded-xl'
              photoLink={oldPhotos[0].photo}
            />
          )}
          {addedPhotos && (
            <img
              alt='photoUrl'
              src={URL.createObjectURL(addedPhotos[0])}
              className='centered h-[110px] w-[110px] rounded-xl'
            />
          )}
        </button>
        <div className='flex space-x-0.5 -ml-1.5'>
          {oldPhotos &&
            !addedPhotos &&
            oldPhotos
              .filter((_: any, index: number) => index != 0)
              .map((photo: {photo: string}, index: number) => (
                <UIImage
                  type={photosType}
                  key={index}
                  customClassName='h-[40px] w-[40px] rounded-md'
                  photoLink={photo.photo}
                />
              ))}
          {!oldPhotos && (
            <>
              {addedPhotos ? (
                addedPhotos
                  .filter((_: any, index: number) => index != 0)
                  .map((photo: File, index: number) => (
                    <img
                      key={index}
                      alt='added photo'
                      src={URL.createObjectURL(photo)}
                      className='centered h-[35px] w-[35px] rounded-md'
                    />
                  ))
              ) : (
                <div className='flex space-x-1 text-xs bg-auto'>
                  <img
                    alt='next_photo'
                    src={addphoto}
                    width={35}
                    className='centered border border-yellow-accent
              rounded-xl'
                  />
                  <img
                    alt='next_photo'
                    src={addphoto}
                    width={35}
                    className='centered border border-yellow-accent
              rounded-xl'
                  />
                  <img
                    alt='next_photo'
                    src={addphoto}
                    width={35}
                    className='centered border border-yellow-accent
            rounded-xl'
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AddPhotosInner;
