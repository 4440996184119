import battery from 'assets/Tools/battery.svg';
import building from 'assets/Tools/building.svg';
import farm from 'assets/Tools/farm.svg';
import garden from 'assets/Tools/garden.svg';
import gasoline from 'assets/Tools/gasoline.svg';
import grinder from 'assets/Tools/grinder.svg';
import trail from 'assets/Machinery/trail.svg';
import excavator from 'assets/Machinery/excavator.svg';
import bridge from 'assets/Machinery/bridge.svg';
import crane from 'assets/Machinery/crane.svg';
import forkLift from 'assets/Machinery/forkLift.svg';
import crawler from 'assets/Machinery/crawler.svg';
import transporter from 'assets/Machinery/transporter.svg';
import landForest from 'assets/Machinery/landForest.svg';

export const getCategoryIcon = (id: number) => {
  switch (id) {
    case 1:
      return battery;
    case 2:
      return building;
    case 3:
      return gasoline;
    case 4:
      return garden;
    case 5:
      return farm;
    case 6:
      return grinder;
    case 7:
      return trail;
    case 8:
      return crawler;
    case 9:
      return excavator;
    case 10:
      return crane;
    case 11:
      return forkLift;
    case 12:
      return bridge;
    case 13:
      return transporter;
    case 14:
      return landForest;
  }
};
