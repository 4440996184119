import {BFormFieldType} from 'forms/Common/BForm/BFormField';

export const formRegisterConfirmation: BFormFieldType[] = [
  {
    fieldName: 'activationCode',
    fieldType: 'text',
    placeHolder: 'Code',
    validation: {
      required: true,
    },
  },
];
