import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import BFormField, {BFormFieldType} from './BFormField';
const BForm = ({
  preloadedData,
  fields,
  btnText,
  btnMargin,
  submit,
  formErrors,
  shouldClearErrors,
}: {
  preloadedData: any;
  fields: BFormFieldType[];
  btnText: string;
  submit: Function;
  btnMargin: number;
  formErrors?: any[];
  shouldClearErrors?: boolean;
}) => {
  const {
    register,
    handleSubmit,
    formState: {errors},
    setError,
    reset,
    clearErrors,
  } = useForm({defaultValues: preloadedData});
  const [checkedValue, setCheckedValue] = useState<string>();

  useEffect(() => {
    if (formErrors) {
      formErrors.forEach((error: any) => {
        setError(error.name, {type: 'manual', message: error.message});
      });
    }
  }, [formErrors]);

  useEffect(() => {
    clearErrors();
  }, [shouldClearErrors]);

  const onSubmit = (data: any) => {
    data.accountType = checkedValue;
    submit(data);
    if (!formErrors) {
      reset();
    }
  };

  if (btnMargin === 10) {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field) => (
          <BFormField
            key={field.fieldName}
            errors={errors}
            field={field}
            register={register}
            setCheckedValue={setCheckedValue}
          />
        ))}
        <button
          type='submit'
          className='text-white bg-yellow-accent mt-10 mb-3 py-2
        text-center rounded-lg w-full text-sm dark:bg-[#F0A249]'
        >
          <b>{btnText}</b>
        </button>
      </form>
    );
  }
  if (btnMargin === 2) {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field) => (
          <BFormField
            key={field.fieldName}
            errors={errors}
            field={field}
            register={register}
            setCheckedValue={setCheckedValue}
          />
        ))}
        <button
          type='submit'
          className='text-white bg-yellow-accent mt-2 mb-3 py-2
        text-center rounded-lg w-full text-sm'
        >
          <b>{btnText}</b>
        </button>
      </form>
    );
  } else {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field) => (
          <BFormField
            key={field.fieldName}
            errors={errors}
            field={field}
            register={register}
            setCheckedValue={setCheckedValue}
          />
        ))}
        <button
          type='submit'
          className='text-white bg-yellow-accent mt-5 mb-3 py-2
        text-center rounded-lg w-full text-sm dark:bg-[#F0A249]'
        >
          <b>{btnText}</b>
        </button>
      </form>
    );
  }
};

export default BForm;
