import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonToolbar,
  useIonRouter,
  useIonToast,
} from '@ionic/react';
import UIButtonBack from 'components/UIButtons/UIButtonBack';
import UIOrangeSquareBox from 'components/UIContainer/UIOrangeSquareBox';
import UITopTextBar from 'components/UILabels/UITopTextBar';
import React, {useEffect, useState} from 'react';
import searchService from 'assets/Community/Services&Jobs/searchService.svg';
import createService from 'assets/Community/Services&Jobs/createService.svg';
import searchJob from 'assets/Community/Services&Jobs/searchJob.svg';
import createJob from 'assets/Community/Services&Jobs/createJob.svg';
import UILogo from 'components/UILogo/UILogo';
import {useRecoilValue} from 'recoil';
import {accountState} from 'states/Account/accountState';
import UIResponsivePadding from 'components/UIResponsive/UIResponsivePadding';
import {authService} from 'api/account/auth.service';

const ServicesAndJobsPage: React.FC = () => {
  const accountData = useRecoilValue(accountState);
  const [token, setToken] = useState(authService.tokenValue);
  const [present] = useIonToast();
  const router = useIonRouter();

  useEffect(() => {
    authService.setAccessTokenFromStorage();
    authService.currentToken.subscribe(setToken);
  }, []);

  const isLoggedIn = token !== '';

  if (!isLoggedIn) {
    router.push('/home');
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>Dienstleistungen und Jobs</UITopTextBar>
      </IonHeader>
      <IonContent fullscreen>
        <UIResponsivePadding>
          <IonGrid fixed>
            <IonRow>
              <IonCol size='6'>
                <UIOrangeSquareBox
                  name='SUCHE DIENSTLEISTUNGEN'
                  src={searchService}
                  alt='search service'
                  linkTo='/offers'
                />
                <UIOrangeSquareBox
                  name='JOBSUCHE'
                  src={searchJob}
                  alt='search for jobs'
                  linkTo='/jobs'
                />
              </IonCol>
              <IonCol size='6'>
                <UIOrangeSquareBox
                  name='BIETE DIENSTLEISTUNGEN'
                  src={createService}
                  alt='offer service'
                  linkTo={accountData?.accountType === 'COMPANY' && '/offers-add'}
                  onClick={() =>
                    accountData?.accountType !== 'COMPANY' &&
                    present(
                      'um eine Anzeige erstellen zu können müssen Sie über ein Firmenkonto' +
                        ' verfügen dieses können Sie im Profil unter „Einstellungen“ auswählen',
                      3000
                    )
                  }
                />

                <UIOrangeSquareBox
                  name='JOBINSERAT ERSTELLEN'
                  src={createJob}
                  alt='create job offer'
                  linkTo={accountData?.accountType === 'COMPANY' && '/jobs-add'}
                  onClick={() =>
                    accountData?.accountType !== 'COMPANY' &&
                    present(
                      'um eine Anzeige erstellen zu können müssen Sie über ein Firmenkonto' +
                        ' verfügen dieses können Sie im Profil unter „Einstellungen“ auswählen',
                      3000
                    )
                  }
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </UIResponsivePadding>
      </IonContent>
    </IonPage>
  );
};

export default ServicesAndJobsPage;
