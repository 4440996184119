import React from 'react';
import {BASE_URL} from 'utils/constants';
import {dateHandler} from 'utils/dateHandler';
import {useIonRouter} from '@ionic/react';
import avatar from 'assets/avatar_placeholder.png';

export type PostForumFieldType = {
  title: string;
  name: string;
  photo: any;
  date: string;
  text: string;
};

const PostForumComponent = ({fields}: any) => {
  const router = useIonRouter();

  return (
    <div>
      {fields &&
        fields.map((field: any) => (
          <div
            key={field.id}
            className='rounded-xl border border-yellow-accent mx-3 my-2 bg-white font-sans
            dark:bg-[#393939]'
            onClick={() => router.push(`/forum-post/${field.id}`)}
          >
            <div
              className='h-8 bg-yellow-accent rounded-t-lg
          w-full flex justify-between text-xs px-3 dark:bg-[#F0A249]'
            >
              <div className=' my-1 flex justify-around'>
                {field.user.profilePicture ? (
                  <img
                    className='h-[30px] -mt-1 -ml-1 mr-1 rounded-lg'
                    src={`${BASE_URL}media/${field.user.profilePicture}`}
                  />
                ) : (
                  <img
                    className='rounded-lg mr-1'
                    src={avatar}
                  />
                )}
                <b className='mt-1 text-white '>{field.user.firstName}</b>
              </div>
              <p className='mt-2 text-white'>{dateHandler(field.dateAdded)}</p>
            </div>
            <p className='text-black pl-2 text-sm pt-2 font-bold dark:text-white'>{field.title}</p>
            <p className='text-gray-500 pl-2 text-xs my-4 dark:text-white'>{field.description}</p>
          </div>
        ))}
    </div>
  );
};

export default PostForumComponent;
