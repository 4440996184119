import React from 'react';
import {Share} from '@capacitor/share';
import {IonIcon, isPlatform} from '@ionic/react';
import {shareSocial} from 'ionicons/icons';
import {useLocation} from 'react-router';

const UIButtonShare: React.FC = () => {
  if (isPlatform('mobileweb')) return <></>;

  const siteUrl = 'https://app.wubmal.com';

  const currentLocation = useLocation();

  const path = siteUrl + currentLocation.pathname.replace(/ /g, '');

  return (
    <>
      {isPlatform('mobileweb') ? (
        <></>
      ) : (
        <IonIcon
          icon={shareSocial}
          className='h-6 w-6 dark:text-white'
          onClick={() => {
            Share.share({
              text: path,
              dialogTitle: 'Teilen',
            });
          }}
        />
      )}
    </>
  );
};

export default UIButtonShare;
