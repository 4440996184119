import {IonButton, IonTextarea, useIonRouter} from '@ionic/react';
import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar, useIonAlert} from '@ionic/react';
import UIInput from 'components/UIInputs/UIInput';
import UIYellowButton from 'components/UIButtons/UIYellowButton';
import {OrangeLine} from 'components/UIContainer/UIOrangeLine';
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {postsService} from 'api/forum/forum.service';
import UILogo from 'components/UILogo/UILogo';
import UIButtonBack from 'components/UIButtons/UIButtonBack';
import {BASE_URL} from 'utils/constants';
import {lastAddedPostState} from 'states/Messages/messageState';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {refreshCounterState} from 'states/Common/CommonState';
import {accountState} from 'states/Account/accountState';
import avatar from 'assets/avatar_placeholder.png';

const FormPost = () => {
  const setRefreshCount = useSetRecoilState(refreshCounterState);
  const setLastPost = useSetRecoilState(lastAddedPostState);
  const user = useRecoilValue(accountState);

  const [apiResultMessage, setApiResultMessage] = useState('');

  const {
    handleSubmit,
    register,
    formState: {errors},
  } = useForm();

  const [presentAlert] = useIonAlert();
  const router = useIonRouter();

  const onSubmit = async (data: any) => {
    postsService
      .addPost(data)
      .then((res) => {
        setLastPost(data.title);
        setApiResultMessage('Erfolg!');
        setRefreshCount((v) => v + 1);
      })
      .then(() => {
        presentAlert({
          message: 'Erfolg',
          buttons: ['OK'],
        });
        router.push('/forum');
      })
      .catch((error) => {
        setApiResultMessage('Error! ' + error.response.data.message[0]);
      });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {user && (
          <form
            className='h-full mb-3 font-sans font-semibold '
            onSubmit={handleSubmit(onSubmit)}
          >
            <div
              className='border-2 border-orange-main
             text-gray-500 bg-white rounded-md mb-5 mx-3 dark:bg-[#393939]'
            >
              <div className='bg-orange-main w-full h-8 flex
              dark:bg-[#F0A249] dark:border-[#F0A249]'>
                {user && user.profilePicture ? (
                  <img
                    className='h-[30px]  ml-2 rounded-lg'
                    src={`${BASE_URL}media/${user.profilePicture}`}
                  />
                ) : (
                  <img
                    className='h-7 w-7 pt-0.5 ml-2'
                    src={avatar}
                  />
                )}
                <p className='text-white text-sm font-sans mt-1 ml-2'>{user.firstName}</p>
              </div>
              <div className='flex my-4'></div>
              <div className='w-full m-3 text-sm'>
                <div className='w-full space-y-2'>
                  <div className='flex space-x-1 w-full'>
                    Titel:
                    <div className='flex w-full'>
                      <UIInput
                        customClassName='w-60 bg-white text-sm text-gray-700 pl-2
                        dark:text-white dark:bg-[#393939] '
                        name='title'
                        type='text'
                        placeholder='Titel...'
                        maxLength={30}
                        register={register}
                        validation={{required: true}}
                        errors={errors}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex items-center space-x-0.5 text-sm mt-2 mx-'></div>
              <OrangeLine name='Beschreibung' />
              <IonTextarea
                className='input m-2 break-words text-sm pr-4
                dark:text-white dark:bg-[#393939] '
                placeholder='Text...'
                rows={6}
                maxlength={2000}
                {...register('description')}
              />
            </div>
            <div className='flex flex-col justify-end text-white'>
              <p className='text-yellow-accent text-center font-bold mb-5'>{apiResultMessage}</p>
              <IonButton>
                <UIYellowButton text='Weiter' />
              </IonButton>
            </div>
          </form>
        )}
      </IonContent>
    </IonPage>
  );
};

export default FormPost;
