import React, {useEffect, useState} from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonToolbar,
} from '@ionic/react';
import {GoogleMap, withScriptjs, withGoogleMap} from 'react-google-maps';
import {servicesService} from 'api/services/services.service';
import {jobsService} from 'api/jobs/jobs.service';
import {Instrument} from 'interfaces/Instruments/Instrument.interface';
import {Service} from 'interfaces/Services/service.interface';
import {Job} from 'interfaces/Jobs/Jobs.interface';
import {Product} from 'interfaces/Products/product.interface';
import LoadingIndicator from 'components/UILoading/UILoadingIndicator';
import {instrumentsService} from 'api/instruments/instruments.service';
import {useHistory} from 'react-router';
import MapPines from './MapPines';
import UITopTextBar from 'components/UILabels/UITopTextBar';
import UIButtonSearch from 'components/UIButtons/UIButtonSearch';
import UILogo from 'components/UILogo/UILogo';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {accountState} from 'states/Account/accountState';
import {
  defaultGeolocationState,
  refreshCounterState,
} from 'states/Common/CommonState';
import {closeOutline, menuOutline} from 'ionicons/icons';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import {productsService} from 'api/products/products.service';
import {sameLocationItemsLister} from 'utils/sameLocationItemsLister';
import SameLocationItemsList from './SameLocationItemsList';

const MapPage: React.FC = () => {
  const accountData = useRecoilValue(accountState);
  const shouldRefreshData = useRecoilValue(refreshCounterState);
  const defaultLocation = useRecoilValue(defaultGeolocationState);
  const setDefaultGeolocation = useSetRecoilState(defaultGeolocationState);

  const [services, setServices] = useState<Service[]>();
  const [instruments, setInstruments] = useState<Instrument[]>();
  const [products, setProducts] = useState<Product[]>();
  const [jobs, setJobs] = useState<Job[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [displayOption, setDisplayOption] = useState('all');
  const [isItemsListPopUp, setIsItemsListPopUp] = useState(false);
  const [location, setLocation] = useState<string>('');
  const [sameLocationItemsList, setSameLocationItemsList] = useState<{
    Products: Product[];
    Instruments: Instrument[];
    Services: Service[];
    Jobs: Job[];
  }>();

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const instrumentsResponse = await instrumentsService.getAll();
        setInstruments(instrumentsResponse.data.results);
        const productsResponse = await productsService.getAll();
        setProducts(productsResponse.data.results);
        const servicesResponse = await servicesService.getAll();
        setServices(servicesResponse.data.results);
        const jobsResponse = await jobsService.getAll();
        setJobs(jobsResponse.data.results);
        setIsLoading(false);
      } catch (err) {}
    };

    // eslint-disable-next-line space-before-function-paren
    navigator.geolocation.getCurrentPosition(function (position) {
      setDefaultGeolocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });

    fetchData();
  }, [shouldRefreshData]);

  const Map = ({children}: {children: any}) => {
    return (
      <GoogleMap
        defaultOptions={{
          disableDefaultUI: true,
        }}
        defaultZoom={10}
        defaultCenter={defaultLocation}
      >
        {children}
      </GoogleMap>
    );
  };

  const WrappedMap = withScriptjs(withGoogleMap(Map));

  const selectedPlaceHandler = (
    id: string,
    type: string,
    lat: string,
    lng: string,
    cat?: string,
    subCat?: string,
  ) => {
    const itemList = sameLocationItemsLister(
      lat,
      lng,
      instruments,
      products,
      services,
      jobs,
    );
    if (itemList) {
      setIsItemsListPopUp(true);
      setSameLocationItemsList(itemList);
      return;
    }

    if (type === 'instrument' || type === 'product') {
      history.push(`/${type}/${cat}/${subCat}/${id}/`);
      return;
    }
    history.push(`/${type}/${id}`);
  };

  const handlePinesSort = (sortPinesValue: string) => {
    getOrderBy(sortPinesValue);
  };

  const getOrderBy = (sortPinesValue: string) => {
    switch (sortPinesValue) {
      case 'Alle':
        setDisplayOption('alle');
        return 'alle';
      case 'Werkzeug':
        setDisplayOption('werkzeug');
        return 'werkzeug';
      case 'Dienstleistungen':
        setDisplayOption('dienstleistungen');
        return 'dienstleistungen';
      case 'Jobs':
        setDisplayOption('jobs');
        return 'jobs';
      case 'Baumachinen':
        setDisplayOption('baumachinen');
        return 'baumachinen';
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <UILogo />
          <IonButtons slot='end'>
            {isItemsListPopUp ? (
              <IonIcon
                icon={closeOutline}
                className='text-2xl p-2 text-yellow-accent'
                onClick={() => {
                  setIsItemsListPopUp(false);
                  setSameLocationItemsList({Instruments: [], Products: [], Jobs: [], Services: []});
                }}
              />
            ) : (
              <UIButtonSearch itemsType='map' />
            )}
          </IonButtons>
        </IonToolbar>
        <UITopTextBar
          leftComponent={
            !isItemsListPopUp &&
            <div className='flex w-7/8 mr-2'>
              <IonSelect
                okText='Weiter'
                cancelText='Abbrechen'
                interface='popover'
                onIonChange={(e) => handlePinesSort(e.detail.value)}
                placeholder='Sortieren'
                className='text-xs text-transparent w-8 -mr-8 z-10'
              >
                <IonSelectOption className='text-black'>Alle</IonSelectOption>
                <IonSelectOption className='text-black'>
                  Werkzeug
                </IonSelectOption>
                <IonSelectOption className='text-black'>
                  Dienstleistungen
                </IonSelectOption>
                <IonSelectOption className='text-black'>Jobs</IonSelectOption>
                <IonSelectOption className='text-black'>
                  Baumachinen
                </IonSelectOption>
              </IonSelect>
              <IonIcon icon={menuOutline} className='h-5 w-5 pt-2' />
            </div>
          }
        >
          <p className='mt-1'>{isItemsListPopUp ? location : 'Karte'}</p>
        </UITopTextBar>
      </IonHeader>
      <IonContent>
        {isItemsListPopUp && sameLocationItemsList && (
          <SameLocationItemsList
            itemsList={sameLocationItemsList}
            setLocation={setLocation}
          />
        )}
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <WrappedMap
            containerElement={<div className='overflow-hidden h-full' />}
            mapElement={<div className='h-full' />}
            // eslint-disable-next-line max-len
            googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawind,places&key=AIzaSyDO-5cVZigsb3Qc_FTuQHMhCb7GaIzzmk8`}
            loadingElement={<div className='h-full' />}
          >
            <MarkerClusterer gridSize={30} averageCenter maxZoom={17}>
              {instruments &&
                (displayOption === 'werkzeug' || displayOption === 'alle') && (
                  <MapPines
                    instruments={instruments}
                    selectedPlaceHandler={selectedPlaceHandler}
                    type={'instrument'}
                  />
                )}
              {accountData &&
                services &&
                (displayOption === 'dienstleistungen' ||
                  displayOption === 'alle') && (
                  <MapPines
                    services={services}
                    selectedPlaceHandler={selectedPlaceHandler}
                    type={'service'}
                  />
                )}
              {accountData &&
                jobs &&
                (displayOption === 'jobs' || displayOption === 'alle') && (
                  <MapPines
                    jobs={jobs}
                    selectedPlaceHandler={selectedPlaceHandler}
                    type={'job'}
                  />
                )}
              {products &&
                (displayOption === 'Baumachinen' ||
                  displayOption === 'alle') && (
                  <MapPines
                    products={products}
                    selectedPlaceHandler={selectedPlaceHandler}
                    type={'product'}
                  />
                )}
            </MarkerClusterer>
          </WrappedMap>
        )}
      </IonContent>
    </IonPage>
  );
};
export default MapPage;
