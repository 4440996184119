import React, {useEffect, useState} from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import UILogo from 'components/UILogo/UILogo';
import {useParams} from 'react-router';
import {Instrument} from 'interfaces/Instruments/Instrument.interface';
import UIYellowButton from 'components/UIButtons/UIYellowButton';
import UIButtonBack from 'components/UIButtons/UIButtonBack';
import {useIonRouter} from '@ionic/react';
import UITopTextBar from 'components/UILabels/UITopTextBar';
import LoadingIndicator from 'components/UILoading/UILoadingIndicator';
import {useRecoilState, useRecoilValue} from 'recoil';
import {accountState} from 'states/Account/accountState';
import {chevronForward} from 'ionicons/icons';
import {productsService} from 'api/products/products.service';
import {DetailsProducts} from './DetailsProducts';
import FormEditProduct from 'forms/Products/EditFormProduct';
import {refreshCounterState} from 'states/Common/CommonState';
import {UIDeleteButton} from 'components/UIButtons/UIDeleteButton';
import UIPopUpPhoto from 'components/UIImages/UIPopUpPhoto';
import {User} from 'interfaces/Users/user.interface';

export const DetailProductsPage = () => {
  const router = useIonRouter();
  const [isFavorite, setIsFavorite] = useState(false);
  const [isOwn, setIsOwn] = useState(false);
  const {instrumentId, catName, subCatName}: any = useParams();
  const [product, setProduct] = useState<Instrument>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const accountData = useRecoilValue<User | any>(accountState);
  const [isPopUpVisible, setIsPopUpVisible] = useState<boolean>(false);
  const [photos, setPhotos] = useState([]);

  const [refreshCounter, setRefreshCounter] =
    useRecoilState(refreshCounterState);

  useEffect(() => {
    async function getOfferDetails() {
      try {
        setIsLoading(true);
        const response = await productsService.getDetails(+instrumentId);
        if (accountData) {
          setIsOwn(accountData && accountData.id == response.data.owner.id);
          const isFavourite = accountData.favourites.products.some(
            (dto: any) => dto.product.id == instrumentId,
          );
          setIsFavorite(isFavourite);
        }
        setProduct(response.data);
        setPhotos(response.data.photos);
        setIsPopUpVisible(false);
      } catch {
      } finally {
        setIsLoading(false);
      }
    }
    getOfferDetails();
  }, [refreshCounter]);

  const handleDelete = () => {
    productsService.deleteProduct(+product!.id!).then(() => {
      setRefreshCounter(refreshCounter + 1);
      router.goBack();
    });
  };
  return (
    <IonPage>
      {isPopUpVisible && (
        <UIPopUpPhoto
          type='products'
          photoLink={photos}
          onClosePopUp={() => {
            setIsPopUpVisible(false);
          }}
        />
      )}
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
          {isOwn && (
            <IonButtons slot='end'>
              <UIDeleteButton onDelete={handleDelete} />
            </IonButtons>
          )}
        </IonToolbar>
        <UITopTextBar
          leftComponent={
            <div className='text-white text-xs flex w-80 ml-2 items-center'>
              <div>
                <p className='w-fit  font-thin'>{catName} kaufen</p>
              </div>
              <IonIcon icon={chevronForward} className='h-5 w-5' />
              <p className=' font-thin'>{subCatName}</p>
              <IonIcon icon={chevronForward} className='h-5 w-5' />
              <p className='text-sm'>{product?.productName}</p>
            </div>
          }
        ></UITopTextBar>
      </IonHeader>
      <IonContent>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          product && (
            <div className='w-full h-full bg-white dark:bg-[#474747]'>
              <div className='pt-2 px-2 pb-5 space-y-5'>
                {!isOwn ? (
                  <>
                    <DetailsProducts
                      isDefaultFavorite={isFavorite}
                      fields={product}
                      setIsPopUpVisible={setIsPopUpVisible}
                    />
                    <UIYellowButton
                      text='Nachricht senden'
                      onClick={() => {
                        if (accountData) {
                          router.push(
                            `/message/products/${product.id}/${
                              product.name ? product.name : product.productsName
                            }/${product.existedChat ? product.existedChat : 0}`,
                          );
                        } else if (!accountData) {
                          router.push('/sign-in');
                        }
                      }}
                    />
                  </>
                ) : (
                  <FormEditProduct productId={product.id} />
                )}
              </div>
            </div>
          )
        )}
      </IonContent>
    </IonPage>
  );
};
