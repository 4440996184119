import React, {useEffect, useState} from 'react';
import {instrumentsService} from 'api/instruments/instruments.service';
import {useHistory, useParams} from 'react-router';
import {addToCart} from 'api/promotions/promotions.service';
import UIOrangeButton from '../../../components/UIButtons/UIOrangeButton';
import {UIImage} from 'components/UIImages/UIImage';
import {servicesService} from 'api/services/services.service';
import {jobsService} from 'api/jobs/jobs.service';
import {productsService} from 'api/products/products.service';
import UILoadingIndicator from 'components/UILoading/UILoadingIndicator';
import {
  IonItem,
  IonRadio,
  IonRadioGroup,
  isPlatform,
  useIonActionSheet,
} from '@ionic/react';
import {User} from 'interfaces/Users/user.interface';
import {userService} from 'api/users/users.service';

interface Props {
  promotionType?: 'list' | 'map' | 'push';
  prefix: string;
  suffix: string;
  setActiveTab?: Function;
  nextTab?: string;
}

export const getService = (type: string) => {
  switch (type) {
    case 'products':
      return productsService;
    case 'instruments':
      return instrumentsService;
    case 'jobs':
      return jobsService;
    case 'services':
      return servicesService;
    default:
      return instrumentsService;
  }
};

export const PromotionsCostsList = ({
  promotionType,
  prefix,
  suffix,
  setActiveTab,
  nextTab,
}: Props) => {
  const [avaliablePromotions, setAvaliablePromotions] = useState<any>();
  const [promotion, setPromotion] = useState<any>();
  const [name, setName] = useState([]);
  const [picture, setPicture] = useState();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {type, id} = useParams<{type: string; id: string}>();
  const router = useHistory();
  const [actionSheet] = useIonActionSheet();

  const fetchPromotions = async () => {
    const service = getService(type);
    try {
      setIsLoading(true);
      const account = await userService.getUserData();
      const itemResponse = await service.getDetails(+id);
      const categoryId = itemResponse.data.category?.id;

      setName(itemResponse.data.name);

      setPicture(
        itemResponse.data.photos.length > 0 &&
          itemResponse.data.photos[0].photo,
      );

      const response = await service.getAvailablePromotions(+id);
      const pricesList = getPricesList(account.data, response.data, categoryId);

      if (promotionType) {
        const field = account.data.accountType!.toLowerCase();
        setAvaliablePromotions(pricesList[field]);
        return;
      }
      setAvaliablePromotions(pricesList);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPricesList = (account: User, data: any, categoryId: number) => {
    const accType = account!.accountType!.toLowerCase();
    if (!promotionType) {
      if (categoryId) {
        return account!.mine[type].filter(
          (item: any) => item.category.id == categoryId && item.issuedPromoted,
        ).length == 0
          ? data.issuePromotions[accType]
          : data.issuePromotions[accType];
      }
      return account!.mine[type].length == 0
        ? data.issuePromotions[accType]
        : data.issuePromotions[accType];
    }

    switch (promotionType) {
      case 'list':
        return data.listPromotions;
      case 'map':
        return data.mapPromotions;
      case 'push':
        return data.pushPromotion;
    }
  };

  useEffect(() => {
    fetchPromotions();
  }, []);

  const handleAddToCart = async () => {
    let data: any;
    if (promotionType === 'list') data = {promotedPackage: promotion.id};
    if (promotionType === 'map') data = {mapPromotedPackage: promotion.id};
    if (promotionType === 'push') data = {notificationPromotedPackage: promotion.id};
    if (!promotionType) data = {issuedPromotedPackage: promotion.id};

    await addToCart(type, +id, data).then(() => {
      setActiveTab
        ? setActiveTab(nextTab)
        : router.push(`/promote/${type}/${id}`);
    });
  };

  return (
    <div className='w-full pb-20'>
      <div
        className='items-center p-3 space-x-5 rounded-xl border border-t-8
          border-yellow-accent mx-6 my-3 bg-white drop-shadow-md dark:bg-[#393939]
          dark:border-[#F0A249]'
      >
        <div className='flex mb-2'>
          {picture && (
            <UIImage
              customClassName='w-20 h-20'
              photoLink={picture!}
              type={type}
            />
          )}
          <div className='text-black text-[17px] dark:text-white'>
            <b>{name}</b>
          </div>
        </div>
      </div>
      {isLoading ? (
        <UILoadingIndicator />
      ) : (
        avaliablePromotions && (
          <>
            <div
              className='rounded-xl border border-t-8
          border-yellow-accent mx-6 my-3 bg-white drop-shadow-md dark:border-[#F0A249]'
            >
              <IonRadioGroup
                value={promotion}
                onClick={() =>
                  isPlatform('ios') &&
                  actionSheet([
                    {
                      text: 'In den Warenkorb legen',
                      handler: () => handleAddToCart(),
                    },
                  ])
                }
                onIonChange={(e) => setPromotion(e!.detail.value)}
              >
                {avaliablePromotions.map((promotionItem: any) => {
                  return (
                    <IonItem
                      key={promotionItem.id}
                      className='space-x-1 dark:text-white'
                    >
                      <IonRadio slot='start' value={promotionItem} />
                      <div className='text-[12px]'>
                        {prefix} {promotionItem.duration} {suffix}
                      </div>
                      <div className='text-orange-400'>
                        €{promotionItem.price}
                      </div>
                    </IonItem>
                  );
                })}
              </IonRadioGroup>
            </div>
            <p className='text-[13px] ml-6 text-center'>
              Wenn Sie diese Aktion nicht zu Ihrem Einkaufswagen hinzufügen
              möchten, klicken Sie bitte unten, um fortzufahren
            </p>
            <div className='w-full flex justify-center'>
              <div className='mt-3 w-2/3 lg:w-1/4'>
                <div
                  className='rounded-md p-2 border-2 font-bold
                   border-yellow-accent text-yellow-accent text-center hover:bg-gray-200'
                  onClick={() =>
                    setActiveTab
                      ? setActiveTab(nextTab)
                      : router.push(`/promote/${type}/${id}`)
                  }
                >
                  Weiter
                </div>
              </div>
            </div>
            {promotion && !isPlatform('ios') && (
              <div className='fixed w-full bottom-2'>
                <UIOrangeButton
                  onClick={handleAddToCart}
                  type={'button'}
                  title='In den Warenkorb legen'
                />
              </div>
            )}
          </>
        )
      )}
    </div>
  );
};
