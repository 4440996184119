import React, {useState} from 'react';
import AccountNewPasswrodPage from './AccountNewPasswrodPage';
import AccountRecoveryCodePage from './AccountRecoveryCodePage';
import AccountRecoveryPasswordPage from './AccountRecoveryPasswordPage';
import {accountService} from 'api/account/account.service';

const AccountRecoveryPage = () => {
  const [submitMail, setSubmitMail] = useState(false);
  const [submitCode, setSubmitCode] = useState(false);
  const [submitPassword, setSubmitPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any[]>([]);
  const [email, setEmail] = useState<any>();
  const [token, setToken] = useState<any>();

  const onSubmitMail = async (data: any) => {
    setIsLoading(true);
    setEmail(data);
    try {
      await accountService.forgetPasswordMail(data);
    } catch (error) {
      console.log(error);
      setErrors([
        {
          name: 'email',
          message: 'E-Mail ungültig',
        },
      ]);
      return;
    } finally {
      setIsLoading(false);
    }
    setSubmitMail(true);
  };

  const onSubmitCode = async (token: any) => {
    setIsLoading(true);
    setToken(token);
    try {
      await accountService.checkCodeValidation({...token, ...email});
    } catch (error) {
      console.log(error);
      setErrors([
        {
          name: 'code',
          message: 'Code is invalid',
        },
      ]);
      return;
    } finally {
      setIsLoading(false);
    }
    setSubmitCode(true);
  };

  const onSubmitPassword = async (password: any) => {
    if (password.password !== password.confirmPassword) {
      setErrors([
        {
          name: 'confirmPassword',
          message: 'Passwörter stimmen nicht überein',
        },
      ]);
      return;
    }
    setIsLoading(true);
    try {
      delete password.confirmPassword;
      await accountService.newPasswordValidation({
        ...token,
        ...email,
        ...password,
      });
    } catch (error) {
      console.log(error);
      setErrors([
        {
          name: 'password',
          message: 'Password is invalid',
        },
      ]);
      return;
    } finally {
      setIsLoading(false);
    }
    setSubmitPassword(true);
  };

  return (
    <>
      {!submitMail && (
        <AccountRecoveryPasswordPage
          errors={errors}
          isLoading={isLoading}
          onSubmit={onSubmitMail}
        />
      )}

      {submitMail && !submitCode && (
        <AccountRecoveryCodePage
          errors={errors}
          isLoading={isLoading}
          onSubmit={onSubmitCode}
        />
      )}

      {submitCode && (
        <AccountNewPasswrodPage
          errors={errors}
          submitPassword={submitPassword}
          isLoading={isLoading}
          onSubmit={onSubmitPassword}
        />
      )}
    </>
  );
};

export default AccountRecoveryPage;
