import React, {useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import {IonPage} from '@ionic/react';

import {accountService} from 'api/account/account.service';
import {formRegisterConfirmation} from 'forms/Account/FormRegister/FormRegisterConfirmation';
import BForm from 'forms/Common/BForm/BForm';

import AppLoginBackground from 'pages/Account/AccountLogin/AccountLoginBackground';

import logo from 'assets/logo.png';
import background from 'assets/background.png';

type LocationProps = {
  email: string;
};

const AccountRegisterConfirmationPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any[]>([]);
  const location = useLocation<LocationProps>();
  const history = useHistory();

  const onSubmit = (data: any) => {
    setIsLoading(true);
    accountService
      .activateAccount(location.state.email, data.activationCode)
      .then(() => {
        history.replace('/login');
      })
      .catch(() => {
        setErrors([{name: 'activationCode', message: 'Code is invalid'}]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <IonPage className='bg-gray-200'>
      <div className='lg:flex lg:justify-center dark:bg-[#474747]'>
        <div className='h-screen bg-gray-200 lg:w-1/3 dark:bg-[#474747]'>
          <img
            alt='background'
            src={background}
            className='absolute w-screen h-screen z-0 blur-sm lg:hidden'
          />
          <div className='flex justify-center h-2/6 flex-col'>
            <div className='h-1/6'></div>
            <div className='flex justify-center'>
              <img alt='logo' src={logo} className='w-60 h-24 z-20' />
            </div>
          </div>
          <AppLoginBackground>
            <div>
              {isLoading && <p>Loading...</p>}
              <p className='text-xl mt-2 mx-3 font-bold dark:text-white'>Bestätigung</p>
              <p className='text-xs text-gray-600 mx-3 dark:text-white'>
                Geben Sie Ihren sechsstelligen Code ein
              </p>
              <div className='mx-3'>
                <BForm
                  btnMargin={2}
                  btnText='Weiter'
                  fields={formRegisterConfirmation}
                  preloadedData={{}}
                  submit={onSubmit}
                  formErrors={errors}
                />
              </div>
              <hr className='bg-yellow-accent mx-6 my-2' />
              <p className='text-xs text-center mt-4 dark:text-white'>
                Sie haben den Code nicht?
              </p>
              <div
                className='bg-white mt-2 mb-6 mx-4 text-sm text-yellow-accent
            text-center rounded-lg border border-yellow-accent p-2'
              >
                <b className='dark:border-[#F0A249] dark:text-[#F0A249]'>Erneut Senden</b>
              </div>
            </div>
          </AppLoginBackground>
        </div>
      </div>
    </IonPage>
  );
};

export default AccountRegisterConfirmationPage;
