import React from 'react';
import {FavoriteService} from 'interfaces/Services/service.interface';
import {FavoritesOffersListItem} from './FavoritesOffersListItem';

export interface Props {
  fields: FavoriteService[];
  onOfferClick: (offerId: number) => void;
}

export const FavoritesServicesList: React.FC<Props> = ({fields, onOfferClick}) => {
  return (
    <div>
      {fields.map((field) => (
        <FavoritesOffersListItem
          field={field}
          onOfferClick={onOfferClick}
          key={field.service.id}
        />
      ))}
    </div>
  );
};
