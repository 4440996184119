import React, {useEffect, useState} from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonToolbar,
} from '@ionic/react';
import UIButtonBack from 'components/UIButtons/UIButtonBack';
import UITopTextBar from 'components/UILabels/UITopTextBar';
import UILogo from 'components/UILogo/UILogo';
import {userService} from 'api/users/users.service';
import {User} from 'interfaces/Users/user.interface';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {accountState} from 'states/Account/accountState';
import {accountService} from 'api/account/account.service';
import {refreshCounterState} from 'states/Common/CommonState';

const EmailNotificationsEdit = () => {
  const [user, setUser] = useState<User>();
  const [selected, setSelected] = useState<boolean>();
  const setAccount = useSetRecoilState(accountState);
  const [refreshCounter, setRefreshCounter] = useRecoilState(refreshCounterState);

  useEffect(() => {
    if (user) {
      if (selected === true) {
        user!.areEmailNotificationsEnabled = true;
      } else user!.areEmailNotificationsEnabled = false;
      userService
        .updateUser({
          areEmailNotificationsEnabled: user.areEmailNotificationsEnabled,
        })
        .then(() => {
          setRefreshCounter(refreshCounter + 1);
          accountService.getCurrentUser().then((res) => {
            setAccount(res.data);
          });
        });
    }
  }, [selected]);

  useEffect(() => {
    userService.getUserData().then((res) => {
      setUser(res.data);
      if (res.data.areEmailNotificationsEnabled === true) {
        setSelected(true);
      } else setSelected(false);
    });
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar customClassName='w-full bg-gray-600 h-10 flex justify-center z-10'>
          E-Mail Benachrichtigungen
        </UITopTextBar>
      </IonHeader>
      <IonContent>
        <div className='text-black mx-4 font-sans'>
          <div className='border border-yellow-accent border-t-8 rounded-md mt-2 py-0.5'>
            <IonRadioGroup
              value={selected}
              onIonChange={(e: any) => setSelected(e.detail.value)}
            >
              <IonItem
                style={{color: 'black'}}
                lines='none'
              >
                <IonLabel>
                  <b className='font-bold text-black text-sm'>Aktiviert</b>
                </IonLabel>
                <IonRadio
                  slot='start'
                  value={true}
                />
              </IonItem>
              <hr className='bg-yellow-accent' />
              <IonItem
                style={{color: 'black'}}
                lines='none'
              >
                <IonLabel>
                  <b className='font-bold text-black text-sm'>Deaktiviert</b>
                </IonLabel>
                <IonRadio
                  slot='start'
                  value={false}
                />
              </IonItem>
            </IonRadioGroup>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default EmailNotificationsEdit;
