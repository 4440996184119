import React, {useEffect, useState} from 'react';
import {IonIcon, IonSelect, IonSelectOption, IonTextarea} from '@ionic/react';
import {useForm} from 'react-hook-form';
import {BFormAddressField} from 'forms/Common/places/BFormAddressField';
import {instrumentsService} from 'api/instruments/instruments.service';
import {
  Category,
  InstrumentRequest,
  Subcategory,
} from 'interfaces/Instruments/Instrument.interface';
import IconEuro from 'assets/IconEuro.svg';
import UIYellowButton from 'components/UIButtons/UIYellowButton';
import LineColored from './LineColored';
import UIInput from 'components/UIInputs/UIInput';
import UIToggleButton from 'components/UIButtons/UIToggleButton';
import AddPhotos from 'components/UIAddPhoto/UIAddPhotos';
import {AxiosError} from 'axios';
import {errorCategoryTranslator} from 'utils/translateHelper';
import {useHistory} from 'react-router';
import {authService} from 'api/account/auth.service';
import {userService} from 'api/users/users.service';

function InstrumentForm() {
  const [categories, setCategories] = useState<Category[]>([]);
  const [subcategories, setSubcategories] = useState<Subcategory[]>([]);
  const [subcatId, setSubcatId] = useState<string>('');
  const [catId, setCatId] = useState<string>('');
  const [prevState, setToggle] = useState<boolean>(true);
  const [apiResultMessage, setApiResultMessage] = useState('');
  const [instrumentId, setInstrumentId] = useState<any>();
  const [address, setAddress] = useState<any>();
  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: {errors},
    watch,
  } = useForm();

  const history = useHistory();

  useEffect(() => {
    !authService.tokenValue && watch(() => history.replace('/sign-in'));

    userService.getUserData().then((response) => {
      setValue('email', response.data.email);
      setValue('phoneNumber', response.data.phone);
    });

    instrumentsService.getCategories().then((response) => setCategories(response.data.results));
  }, []);

  useEffect(() => {
    instrumentsService.getSubcategories(catId).then((response) => {
      setSubcategories(response.data.results);
    });
  }, [catId]);

  const onSubmit = (data: InstrumentRequest) => {
    data.name = data.instrumentName;
    data.category = catId;
    data.subcategory = subcatId;
    data.pricePerDay = +data.pricePerDay;
    data.availability = prevState;
    data.productionYear = +data!.productionYear!;
    data.weekHours = '';
    data.weekendHours = '';

    if (data.Address) {
      data.location = data.Address.label;
      data.postCode = data.Address.postCode;
      data.lat = data.Address.lat;
      data.lng = data.Address.lng;
      setAddress(data.Address);
    } else if (address) {
      data.location = address.label;
      data.postCode = address.postCode;
      data.lat = address.lat.toString();
      data.lng = address.lng.toString();
    }

    const method = instrumentId
      ? instrumentsService.updateInstrument(data, instrumentId)
      : instrumentsService.addInstrument(data);

    setApiResultMessage('Ladung...');

    method
      .then((res) => {
        setApiResultMessage('');
        setInstrumentId(res.data.id);
        setAddress(data.Address);
        history.push(`/purchase/instruments/${res.data.id ? res.data.id : instrumentId}`);
      })
      .catch((error: AxiosError | any) => {
        setApiResultMessage('Achtung! ' + errorCategoryTranslator(error.response.data.message[0]));
      })
      .finally(() => {});
  };

  return (
    <form
      className='h-full pb-3 '
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='border-2 border-orange-main text-black bg-white rounded-md dark:bg-[#393939]'>
        <div className='h-6 bg-yellow-accent dark:bg-[#F0A249]'></div>
        <div className='flex my-4 bg-white dark:bg-[#393939] '>
          <div className='flex-col mx-2 w-2/5 space-y-2 text-sm'>
            <div className='flex-col w-2/5 space-y-2 text-sm mb-16 pt-2'>
              <AddPhotos
                control={control}
                name='photos'
              />
            </div>
            <div>
              <p>Preis pro Tag:</p>
              <div className='flex'>
                <UIInput
                  customClassName='bg-white w-full text-sm dark:bg-[#393939] dark:text-white '
                  name='pricePerDay'
                  type='number'
                  placeholder='80'
                  register={register}
                  validation={{required: true}}
                  errors={errors}
                />
                <IonIcon
                  icon={IconEuro}
                  className='mt-1 text-gray-500 h-4 w-4 lg:absolute lg:left-[160px]'
                />
              </div>
              <div className='lg:w-[150px] '>
                <LineColored error={errors.pricePerDay} />
              </div>
            </div>
            <UIToggleButton
              name='availability'
              title='Verfügbarkeit'
              state={prevState}
              setState={setToggle}
              register={register}
            />
          </div>
          <div className='flex-col space-y-2 mr-[15px] w-3/5 text-sm '>
            <div className='space-y-2 mb-2 '>
              <div
                className='w-44 h-6 p-4 flex items-center justify-between
              rounded-xl border-orange-main border text-orange-main '
              >
                <IonSelect
                  okText='Weiter'
                  cancelText='Abbrechen'
                  className='text-black dark:text-white'
                  placeholder='Kategorie'
                  onIonChange={(cat) => setCatId(cat.detail.value)}
                >
                  {categories.map((cat) => (
                    <IonSelectOption
                      key={cat.id}
                      value={cat.id}
                    >
                      {cat.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </div>
              <div
                className='w-44 h-6 p-4 flex items-center justify-between
              rounded-xl border-orange-main border text-orange-main'
              >
                <IonSelect
                  okText='Weiter'
                  cancelText='Abbrechen'
                  className='text-black dark:text-white'
                  placeholder='Unterkategorie'
                  onIonChange={(cat) => setSubcatId(cat.detail.value)}
                >
                  {subcategories.map((subcat) => (
                    <IonSelectOption
                      key={subcat.id}
                      value={subcat.id}
                    >
                      {subcat.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </div>
            </div>
            <div>
              <p className='dark:text-white'>Name:</p>
              <UIInput
                customClassName='dark:text-white dark:bg-[#393939] '
                name='instrumentName'
                placeholder='Mischmaschine'
                required={'required'}
                register={register}
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.instrumentName} />
            </div>
            <div>
              <p className='dark:text-white'>Geräte Bezeichnung:</p>
              <UIInput
                customClassName='dark:text-white dark:bg-[#393939] '
                name='purpose'
                placeholder='Zweck'
                required={'required'}
                register={register}
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.purpose} />
            </div>
            <div>
              <p className='dark:text-white'>Email:</p>
              <UIInput
                customClassName='dark:text-white dark:bg-[#393939] '
                name='email'
                placeholder='beispiel@email.com'
                required='required'
                register={register}
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.email} />
            </div>
            <div>
              <p className='dark:text-white'>Baujahr:</p>
              <UIInput
                customClassName='dark:text-white dark:bg-[#393939] '
                name='productionYear'
                type='number'
                placeholder='Jahr'
                register={register}
                errors={errors}
              />
              <LineColored error={errors.productionYear} />
            </div>
            <div>
              <p className='dark:text-white'>Telefonnummer:</p>
              <UIInput
                customClassName='dark:text-white dark:bg-[#393939] '
                name='phoneNumber'
                type='tel'
                placeholder='0660 1234567'
                minLength={9}
                maxLength={16}
                register={register}
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.phoneNumber} />
            </div>
            <div className='text-yellow-accent text-center font-bold mb-5 clear-both'>
              {apiResultMessage}
            </div>
          </div>
        </div>
        <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
          <div className='text-orange-main text-sm dark:text-[#F0A249] '>Adresse</div>
          <div className='bg-orange-main w-full h-px dark:bg-[#F0A249]'></div>
        </div>
        <div className='mx-2 mt-2'>
          <BFormAddressField
            placeholder={address?.label}
            control={control}
            fieldName='Address'
            key={Math.random()}
          />
        </div>
        <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
          <div className='text-orange-main text-sm dark:text-[#F0A249]'>Beschreibung</div>
          <div className='bg-orange-main w-4/5 h-px dark:bg-[#F0A249] '></div>
        </div>
        <IonTextarea
          rows={4}
          className='ipnut mx-2 text-sm h-[100px] dark:text-white'
          {...register('description')}
          placeholder='Bitte beschreibe dein Gerät'
        />
      </div>
      <div className='flex flex-col mt-2 justify-end text-white '>
        <UIYellowButton
          type='submit'
          text='Weiter'
        />
      </div>
    </form>
  );
}

export default InstrumentForm;
