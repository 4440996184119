/* eslint-disable*/
import React, {useEffect, useState} from 'react';
import UILogo from 'components/UILogo/UILogo';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import bagger from 'assets/BaggerIcon.svg';
import settings from 'assets/SettingProfileIcon.svg';
import noteIcon from 'assets/NoteIcon.svg';
import worker from 'assets/WorkerIcon.svg';
import UICategoryButton from 'components/UIButtons/UICategoryButton';
import UITopTextBar from 'components/UILabels/UITopTextBar';
import {UIImage} from 'components/UIImages/UIImage';
import avatarPlaceholder2 from 'assets/avatar_placeholder2.png';
import avatarPlaceholder3 from 'assets/avatar_placeholder3.png';
import {useRecoilValue} from 'recoil';
import {refreshCounterState} from 'states/Common/CommonState';
import {accountService} from 'api/account/account.service';
import {User} from 'interfaces/Users/user.interface';
import UILoadingIndicator from 'components/UILoading/UILoadingIndicator';
import UIResponsivePadding from 'components/UIResponsive/UIResponsivePadding';
import UIOrangeSquareBox from 'components/UIContainer/UIOrangeSquareBox';

const AccountDatail: React.FC = () => {
  const [user, setUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(true);
  const refreshCounter = useRecoilValue(refreshCounterState);
  const [present] = useIonToast();
  const [dark, setDark] = useState<boolean>(false); 

  useEffect(() => {
    accountService.getCurrentUser().then((response) => {
      setUser(response.data);
      setIsLoading(false);
    });
  }, [refreshCounter]);

  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.documentElement.classList.add('dark');
      setDark(true);
    }
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <UILogo customClassName='flex justify-center w-full dark:bg-[#474747]'/>
        </IonToolbar>
        <UITopTextBar>Profil</UITopTextBar>
      </IonHeader>
      <IonContent>
        <UIResponsivePadding>
          {isLoading ? (
            <UILoadingIndicator />
          ) : (
            <div className='min-h-full h-2/5 flex flex-col dark:bg-[#474747]'>
              <div className='flex justify-center dark:bg-[#474747]'>
                <div className='flex-col text-center'>
                  {user && user.profilePicture ? (
                    <UIImage photoLink={user.profilePicture} />
                  ) : (
                    <img
                      alt='Avatar'
                      width={180}
                      className='mt-1'
                      src={dark ? avatarPlaceholder3 : avatarPlaceholder2}
                    />
                  )}
                  <b className='text-gray-500 dark:text-white'>{user ? user.firstName : 'Your Name'}</b>
                </div>
              </div>
              <IonGrid fixed>
                <IonRow>
                  <IonCol>
                    <UIOrangeSquareBox
                      name='EINSTELLUNGEN'
                      src={settings}
                      alt={'settings'}
                      linkTo={'/profile-edit'}
                    />
                    <UIOrangeSquareBox
                      name='MEINE ANGEBOTE'
                      src={worker}
                      alt={'settings'}
                      disabled={user?.accountType === 'COMPANY' ? false : true}
                      linkTo={user?.accountType === 'COMPANY' && 'offers-my'}
                      onClick={() =>
                        user?.accountType !== 'COMPANY' &&
                        present(
                          'um eine Anzeige erstellen zu können müssen Sie über ein Firmenkonto' +
                            ' verfügen dieses können Sie im Profil unter „Einstellungen“ auswählen',
                          3000
                        )
                      }
                    />
                  </IonCol>
                  <IonCol>
                    <UIOrangeSquareBox
                      name='MEINE VERLEIHGERÄTE'
                      src={bagger}
                      alt={'settings'}
                      linkTo={'/instruments-my/preview'}
                    />
                    <UIOrangeSquareBox
                      name='MEINE WERBUNG'
                      src={noteIcon}
                      alt={'settings'}
                      linkTo={'/promotions'}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          )}
        </UIResponsivePadding>
      </IonContent>
    </IonPage>
  );
};

export default AccountDatail;
