import React, {useEffect, useState} from 'react';
import CalendarDay, {dayInterface} from './CalendarDay';

type Props = {
  onDatesSet: Function;
};

const UICalendar: React.FC<Props> = ({onDatesSet}) => {
  // const [displayNextMonth, setDisplayNextMonth] = useState(false);
  const [addedDays, setAddedDays] = useState<dayInterface[]>([]);

  useEffect(() => {
    onDatesSet(addedDays);
  }, [addedDays]);

  // eslint-disable-next-line no-array-constructor
  const weeksDisplayedOnTop = new Array();
  // eslint-disable-next-line no-array-constructor
  const weeksDisplayedOnBot = new Array();
  const date = new Date();
  const firstDayDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
  );
  // const firstDayNextMonth = new Date(date.getFullYear(), date.getMonth()+1, 0 );
  // const daysInNextMonth = new Date(date.getFullYear(), date.getMonth() + 2, 0).getDate();

  const addDays = (date: Date, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  function monthInWeeks(days: number) {
    for (let i = 1; i <= days; i++) {
      if ((i > 7 && i <= 14) || (i > 21 && i <= 28)) {
        weeksDisplayedOnBot.push(i);
      } else weeksDisplayedOnTop.push(i);
    }
  }

  // monthInWeeks(displayNextMonth ? daysInNextMonth : daysInMonth);
  monthInWeeks(14);

  return (
    <div className='mx-2 flex flex-wrap overflow-x-auto'>
      <div className='flex '>
        {weeksDisplayedOnTop.map((day) => (
          <CalendarDay
            key={day}
            // date={displayNextMonth ? addDays(firstDayNextMonth, day) : addDays(firstDay, day)}
            date={addDays(firstDayDate, day)}
            place={day === 15 || day === 29 ? 'ml-5' : ''}
            onClick={onDatesSet}
            addedDays={addedDays}
            setAddedDays={setAddedDays}
            id={day}
          />
        ))}
      </div>
      <div className='flex'>
        {weeksDisplayedOnBot.map((day) => (
          <CalendarDay
            key={day}
            date={addDays(firstDayDate, day)}
            place={day === 22 ? 'ml-5' : ''}
            onClick={onDatesSet}
            addedDays={addedDays}
            setAddedDays={setAddedDays}
            id={day}
          />
        ))}
      </div>
      {/* <button className='text-gray-500 mb-5 mt-2'
        onClick={() => setDisplayNextMonth(!displayNextMonth)}>
        {!displayNextMonth &&
          <div>
            <p className='float-left'>Next month</p>
            <IonIcon icon={chevronForwardOutline} />
          </div>
        }
        {displayNextMonth &&
          <div>
            <IonIcon icon={chevronBackOutline} className='float-left' />
            <p className='float-left'>Previous month</p>

          </div>
        }
      </button> */}
    </div>
  );
};

export default UICalendar;
