import React from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import UILogo from 'components/UILogo/UILogo';
import UIButtonBack from 'components/UIButtons/UIButtonBack';
import UITopTextBar from 'components/UILabels/UITopTextBar';
import MessagesList from 'forms/Messages/MessagesList';

const ChatsListPage = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <IonButtons slot='end'>
          </IonButtons>
          <UILogo />
        </IonToolbar>
        <UITopTextBar>Kontakte</UITopTextBar>
      </IonHeader>
      <IonContent>
        <div className='mb-10'>
          <MessagesList />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ChatsListPage;
