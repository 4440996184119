import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {IonIcon} from '@ionic/react';
import {OrangeLine} from 'components/UIContainer/UIOrangeLine';
import {Job} from 'interfaces/Jobs/Jobs.interface';
import {distanceCalculator} from 'utils/distanceCalculator';
import {
  // call,
  ellipse,
  flag,
  homeOutline,
  location,
  // mailOutline,
} from 'ionicons/icons';
import IconEuro from 'assets/IconEuro.svg';
import {geolocationState} from 'states/Common/CommonState';
import {useRecoilValue} from 'recoil';
import {jobsService} from 'api/jobs/jobs.service';
import FavouritesButton from 'pages/Common/Favourites/FavouritesButton';
import {Link} from 'react-router-dom';
import {UIClickImage} from 'components/UIImages/UIClickImage';
import UIButtonShare from 'components/UIButtons/UIButtonShare';
import BMiniMap from 'forms/Common/places/BMiniMap';

interface DetailsJobProps {
  fields: Job;
  defaultFavorite?: boolean;
  setIsPopUpVisible: Dispatch<SetStateAction<boolean>>;
}

const JobDetails: React.FC<DetailsJobProps> = ({
  fields,
  defaultFavorite,
  setIsPopUpVisible,
}) => {
  const geolocation = useRecoilValue(geolocationState);
  const [isFavorite, setIsFavorite] = useState(defaultFavorite);

  useEffect(() => setIsFavorite(defaultFavorite), [defaultFavorite]);

  const favHandler = (id: number) => {
    if (!isFavorite) {
      jobsService.addFavoriteJob(id);
      return;
    }
    jobsService.deleteFavoriteJob(id);
  };

  return (
    <div
      className='rounded-lg border border-yellow-accent mx-4 mt-3 mb-10
  bg-yellow-slight drop-shadow-md dark:bg-[#393939] '
    >
      <div className='h-9 bg-yellow-accent rounded-t-lg w-full flex justify-between text-white
      dark:bg-[#F0A249]'>
        <div className='flex mt-0.5 ml-2'>
          <IonIcon icon={location} className='h-5 w-5 mx-1 mt-1.5' />
          <p className='text-sm mt-1.5'>
            {distanceCalculator(fields.lat, fields.lng, geolocation).toFixed() +
              ' '}
            km
          </p>
        </div>
        <div className='flex'>
          <IonIcon icon={IconEuro} className='h-4 w-4 mx-1 mt-2' />
          <p className='mr-2 text-xs mt-2'>{fields.salary} / Monat</p>
        </div>
      </div>
      <div className='flex font-sans text-gray-500'>
        <div className='w-2/5 ml-1 space-x-2 mr-2'>
          <UIClickImage
            customClassName='mx-4 h-28 w-28 my-3 rounded-md'
            photoLink={fields.photos.length > 0 && fields.photos[0]}
            setIsPopUpVisible={setIsPopUpVisible}
            type='jobs'
          />
          <div className='flex space-x-1'>
            {fields.photos.length > 0 &&
              fields.photos.map(
                (image: any, index: number) =>
                  index !== 0 && (
                    <UIClickImage
                      key={image}
                      type='jobs'
                      customClassName='h-8 w-8 my-3 rounded-md'
                      photoLink={image.photo}
                      setIsPopUpVisible={setIsPopUpVisible}
                    />
                  ),
              )}
          </div>
        </div>
        <div className='w-3/5 ml-3 text-sm'>
          <p className='text-gray-600 text-lg font-bold mt-2'>
            {fields.jobOffer}
          </p>
          <p className='font-thin my-1'>{fields.name}</p>
          <p className='text-xs mt-1 mb-3 font-thin'>{fields.description}</p>
          <p className='text-sm text-yellow-accent'>
            Aktuell Verfugbar: <IonIcon icon={ellipse} />
          </p>
          <div className='flex mt-2 mr-2'>
            <div className='text-black space-x-2 flex'>
              <Link to={`/report/job/${fields.id}`}>
                {' '}
                <IonIcon icon={flag} className='mt-1 h-6 w-6' />
              </Link>
              <FavouritesButton
                favHandler={favHandler}
                fields={fields}
                isFavorite={isFavorite}
                setIsFavorite={setIsFavorite}
              />
              <UIButtonShare />
            </div>
          </div>
        </div>
      </div>
      <OrangeLine name='Kontakt' />
      <div className='flex-col text-gray-500 ml-4 mr-8 '>
        {/* <div>
          <div className='flex'>
            <IonIcon icon={call} className='mt-0.5' />
            <p className='text-sm ml-2 '>{fields.phoneNumber}</p>
          </div>
          <div className='flex mt-3'>
            <IonIcon icon={mailOutline} className='h-5 w-5 mr-3' />
            <p className='text-sm'>{fields.email}</p>
          </div>
        </div> */}
        <div className='flex mt-3'>
          <IonIcon icon={homeOutline} className='h-5 w-5 absolute dark:text-white' />
          <p className='text-sm ml-7 dark:text-white'>{fields.location}</p>
        </div>
      </div>
      <div className='mx-3'>
        <BMiniMap geolocation={{lat: +fields.lat, lng: +fields.lng}} />
      </div>
      <OrangeLine name='Beschreibung' />
      <div className='text-gray-400 text-sm flex justify-between mx-3'>
        <p className='mx-2 mt-2 mb-8 text-gray-500 break-words dark:text-white'>
          {fields.description}
        </p>
      </div>
    </div>
  );
};

export default JobDetails;
