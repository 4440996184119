import React, {useState, useEffect} from 'react';
import {getAllMessages, setMessagesInStorage} from 'api/chat/chat.service';
import {useIonRouter} from '@ionic/react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {refreshCounterState} from 'states/Common/CommonState';
import LoadingIndicator from 'components/UILoading/UILoadingIndicator';
import {UIImage} from 'components/UIImages/UIImage';
import {newMessagesState} from 'states/Messages/messageState';

interface messageUser {
  chatId: number;
  entityId: number;
  item: string;
  lastMessage: string;
  name: string;
  photo: string;
}

const MessagesList = () => {
  const [showFirstMessage, setShowFristMessage] = useState<messageUser[]>();
  const [loading, setLoading] = useState(false);
  const shouldRefreshData = useRecoilValue(refreshCounterState);
  const setNewMessagesState = useSetRecoilState(newMessagesState);
  const router = useIonRouter();
  const firstMessages = async () => {
    setLoading(true);
    try {
      const response = await getAllMessages();
      setShowFristMessage(response.data.chats);
      setMessagesInStorage(response.data);
      setNewMessagesState(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    firstMessages();
  }, [shouldRefreshData]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <div className='mt-2'>
      {showFirstMessage &&
        showFirstMessage.map((field: messageUser) => {
          return (
            <div key={field.chatId}>
              <button
                onClick={() =>
                  router.push(
                    `/message/${field.item.toLowerCase()}s/${field.entityId}/${
                      field.name
                    }/${field.chatId}`,
                  )
                }
              >
                <div className='mt-4 mx-4 flex justify-around'>
                  <UIImage
                    photoLink={field.photo}
                    type='messages'
                    customClassName='h-16 w-16 rounded-md'
                  />
                  <div className='ml-4 mt-1'>
                    <div className='flex flex-col items-start'>
                      <b className='mt-1 text-gray-600 dark:text-white'>
                        {field.name}
                      </b>
                      <div className='flex text-gray-500 text-xs pt-2'>
                        <div className='flex flex-col justify-center dark:text-white'>
                          {field.lastMessage}
                          {/* {getTime(field.dateSent)} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
              <hr className='bg-gray-400 mx-4 mt-4' />
            </div>
          );
        })}
    </div>
  );
};

export default MessagesList;
