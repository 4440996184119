import React, {useEffect, useState} from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import UILogo from 'components/UILogo/UILogo';
import {instrumentsService} from 'api/instruments/instruments.service';
import {useParams} from 'react-router';
import LoadingIndicator from 'components/UILoading/UILoadingIndicator';
import {Instrument} from 'interfaces/Instruments/Instrument.interface';
import {DetailsInstrument} from 'pages/Instruments/InstrumentDetail/InstrumentDetails';
import UIYellowButton from 'components/UIButtons/UIYellowButton';
import UIButtonBack from 'components/UIButtons/UIButtonBack';
import {useIonRouter} from '@ionic/react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {accountState} from 'states/Account/accountState';
import FormEditInstrument from 'forms/Instruments/EditFormInstrument';
import {datesTypeState, selectedDatesState} from 'states/Messages/messageState';
import {refreshCounterState} from 'states/Common/CommonState';
import {UIDeleteButton} from 'components/UIButtons/UIDeleteButton';
import {User} from 'interfaces/Users/user.interface';
import UIPopUpPhoto from 'components/UIImages/UIPopUpPhoto';
import UIRatePopUp from 'components/UIRatePopUp/UIRatePopUp';

export const InstrumentDetailPage = () => {
  const setDates = useSetRecoilState(selectedDatesState);
  const [date, setDate] = useState();
  const [isOwn, setIsOwn] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const {instrumentId}: any = useParams();
  const [instrument, setInstrument] = useState<Instrument>();
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPopUpVisible, setIsPopUpVisible] = useState<boolean>(false);
  const [showRatingPopUp, setShowRatingPopUp] = useState<boolean>(false);

  const setDatesType = useSetRecoilState(datesTypeState);
  const accountData = useRecoilValue<User | undefined>(accountState);
  const [refreshCounter, setRefreshCounter] =
    useRecoilState(refreshCounterState);
  const router = useIonRouter();

  useEffect(() => {
    async function getOfferDetails() {
      setIsLoading(true);
      try {
        const response = await instrumentsService.getDetails(+instrumentId);
        if (accountData) {
          setIsOwn(accountData.id! == response.data.owner.id);
          const isFavourite = accountData.favourites!.instruments.some(
            (dto: any) => dto.instrument.id == instrumentId,
          );
          setIsFavorite(isFavourite);
        }
        setInstrument(response.data);
        setPhotos(response.data.photos);
        setIsPopUpVisible(false);
      } catch {
      } finally {
        setIsLoading(false);
      }
    }
    getOfferDetails();
  }, [refreshCounter]);

  const handleDelete = () => {
    instrumentsService.deleteInstrument(+instrument!.id!).then(() => {
      setRefreshCounter(refreshCounter + 1);
      router.goBack();
    });
  };

  return (
    <IonPage>
      {isPopUpVisible && (
        <UIPopUpPhoto
          type='instruments'
          photoLink={photos}
          onClosePopUp={() => {
            setIsPopUpVisible(false);
          }}
        />
      )}
      <IonHeader>
      {showRatingPopUp && <UIRatePopUp
          closePopUp={() => setShowRatingPopUp(false)}
          id={instrument?.id}
        />}
        <IonToolbar>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
          {isOwn && (
            <IonButtons slot='end'>
              <UIDeleteButton onDelete={handleDelete} />
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          instrument && (
            <div className='w-full h-full bg-white dark:bg-[#474747]'>
              <div className='pt-2 px-2 pb-5 space-y-5'>
                {!isOwn ? (
                  <>
                    <DetailsInstrument
                      setDate={setDate}
                      isDefaultFavorite={isFavorite}
                      fields={instrument}
                      setIsPopUpVisible={setIsPopUpVisible}
                      onClick={() => {
                        setShowRatingPopUp(true);
                      }}
                    />
                    <UIYellowButton
                      text={`${instrument.avgRating.avgRating}`}
                      onClick={() => {
                        setShowRatingPopUp(true);
                      }}
                    />
                    <UIYellowButton
                      text='Nachricht senden'
                      onClick={() => {
                        setDates(date);
                        setDatesType('instruments');
                        if (accountData) {
                          router.push(
                            `/message/instruments/${instrument!.id}/${
                              instrument.name ? instrument.name : instrument.instrumentName
                            }/${instrument.existedChat ? instrument.existedChat : 0}`,
                          );
                        } else if (!accountData) {
                          router.push('/sign-in');
                        }
                      }}
                    />
                  </>
                ) : (
                  <FormEditInstrument instrumentId={instrumentId} />
                )}
              </div>
            </div>
          )
        )}
      </IonContent>
    </IonPage>
  );
};
