import React from 'react';

type Props = {
  children: JSX.Element;
};

const AppLoginBackground = ({children}: Props) => {
  return (
    <div>
      <div className='w-full h-full flex justify-center '>
        <div className='bg-yellow-accent w-5/6 rounded-lg drop-shadow-xl dark:bg-[#F0A249]'>
          <div
            className='text-black bg-white border-2 border-yellow-accent mt-2
          rounded-b-lg dark:bg-[#393939]'
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLoginBackground;
