import React, {useEffect, useState} from 'react';
import {IonIcon, IonTextarea} from '@ionic/react';
import AddPhotos from 'components/UIAddPhoto/UIAddPhotos';
import UIYellowButton from 'components/UIButtons/UIYellowButton';
import {useForm} from 'react-hook-form';
import {BFormAddressField} from 'forms/Common/places/BFormAddressField';
import UIInput from 'components/UIInputs/UIInput';
import {OrangeLine} from 'components/UIContainer/UIOrangeLine';
import {Service} from 'interfaces/Services/service.interface';
import {servicesService} from 'api/services/services.service';
import IconEuro from 'assets/IconEuro.svg';
import LineColored from '../Instruments/LineColored';
import {useHistory} from 'react-router';
import {userService} from 'api/users/users.service';

function FormOffer() {
  const [apiResultMessage, setApiResultMessage] = useState('');
  const [serviceId, setInstrumentId] = useState<number>();
  const [address, setAddress] = useState<any>();
  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: {errors},
  } = useForm();

  const history = useHistory();
  const iS = 'w-full bg-white mb-2 text-sm text-gray-700 dark:bg-[#393939] dark:text-white ';
  // iS-inputStyle

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userRes = await userService.getUserData();
        setValue('email', userRes.data.email);
        setValue('phoneNumber', userRes.data.phone);
      } catch {}
    };
    fetchData();
  }, []);

  const onSubmit = async (data: Service) => {
    data.pricePerHour = parseInt(data.pricePerHour);

    if (address) {
      data.location = address.label;
      data.postCode = address.postCode;
      data.lat = address.lat.toString();
      data.lng = address.lng.toString();
      data.street = address.street;
    } else if (data.Address) {
      data.location = data.Address.label;
      data.street = data.Address.street;
      data.postCode = data.Address.postCode;
      data.lat = data.Address.lat;
      data.lng = data.Address.lng;
      setAddress(data.Address);
    }

    const method = serviceId
      ? servicesService.updateService(data, serviceId)
      : servicesService.addService(data);

    setApiResultMessage('Ladung...');

    method
      .then((res) => {
        setApiResultMessage('');
        setInstrumentId(res.data.id);
        setAddress(data.Address);
        history.push(`/purchase/services/${res.data.id ? res.data.id : serviceId}`);
      })
      .catch((error) => {
        setApiResultMessage('Error! ' + error.response.data.message[0]);
      });
  };

  return (
    <form
      className='h-full pb-3 '
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='border-2 border-orange-main bg-white rounded-md mb-5 dark:bg-[#393939]'>
        <div className='h-6 bg-yellow-accent dark:bg-[#F0A249]'></div>
        <div className='flex my-4 mx-2'>
          <div className='flex-col mx-2 w-2/5 space-y-1 text-sm'>
            <div className='flex-col w-2/5 space-y-2 text-sm mb-16'>
              <AddPhotos
                control={control}
                name='images'
              />
            </div>
            <div className='dark:text-white'>
              Mo - Fr:
              <UIInput
                customClassName={iS}
                name='mondayToFriday'
                placeholder='10:00 - 18:00'
                register={register}
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.mondayToFriday} />
            </div>
            <div className='dark:text-white '>
              Sa - So:
              <UIInput
                customClassName={iS}
                name='saturdayToSunday'
                placeholder='10:00 - 14:00'
                register={register}
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.saturdayToSunday} />
            </div>
            <div className='dark:text-white'>
              Preis pro Stunde:
              <div className='flex text-gray-700'>
                <UIInput
                  customClassName={iS}
                  type='number'
                  placeholder='15'
                  name='pricePerHour'
                  register={register}
                  validation={{required: true}}
                  errors={errors}
                />
                <IonIcon
                  icon={IconEuro}
                  className='h-4 w-[18px] mt-1 text-gray-500 lg:absolute lg:left-[165px]
                  dark:text-white'
                />
              </div>
              <div className='lg:w-[150px]'>
                <LineColored error={errors.pricePerHour} />
              </div>
            </div>
          </div>
          <div className='flex-col space-y-2 mx-2 w-3/5 text-sm'>
            <div className='dark:text-white'>
              Firmenname:{' '}
              <UIInput
                required='required'
                placeholder='Firma'
                customClassName={iS}
                register={register}
                name='name'
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.name} />
            </div>
            <div className='dark:text-white'>
              Branche:{' '}
              <UIInput
                required='required'
                customClassName={iS}
                placeholder='Geschäft'
                register={register}
                name='industry'
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.industry} />
            </div>
            <div className='dark:text-white'>
              Email:{' '}
              <UIInput
                customClassName={iS}
                type='text'
                register={register}
                name='email'
                placeholder='beispiel@email.com'
                validation={{required: true}}
                errors={errors}
              />
              <LineColored error={errors.email} />
            </div>
            <div className='dark:text-white'>
              Telefonnummer{' '}
              <UIInput
                required='required'
                customClassName={iS}
                register={register}
                name='phoneNumber'
                type='tel'
                placeholder='0660 1234567'
                validation={{required: true}}
                errors={errors}
                maxLength={16}
                minLength={9}
              />
              <LineColored error={errors.phoneNumber} />
            </div>
          </div>
        </div>
        <div className='flex items-center space-x-0.5 text-sm mt-2 mx-2'>
          <div className='text-orange-main text-sm'>Adresse</div>
          <div className='bg-orange-main w-full h-px'></div>
        </div>
        <div className='mx-2 mt-2'>
          <BFormAddressField
            placeholder={address?.label}
            control={control}
            fieldName='Address'
            key={Math.random()}
          />
        </div>
        <OrangeLine name='Beschreibung' />
        <IonTextarea
          maxlength={1000}
          rows={4}
          className='mx-2 pr-2 h-[120px] dark:text-white'
          {...register('description')}
          placeholder='230 V Mischmaschine mit 140 Liter
            Fassungsvermögen in sehr guten Zustand inkl 10m Anschlusskabel'
        />
      </div>
      <p className='text-yellow-accent text-center font-bold mb-5'>{apiResultMessage}</p>
      <div className='flex flex-col justify-end text-white'>
        <UIYellowButton
          type='submit'
          text='Weiter'
        />
      </div>
    </form>
  );
}

export default FormOffer;
