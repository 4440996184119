import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import JobDetails from 'pages/Jobs/JobDetail/JobDetails';
import UIButtonBack from 'components/UIButtons/UIButtonBack';
import UIYellowButton from 'components/UIButtons/UIYellowButton';
import UITopTextBar from 'components/UILabels/UITopTextBar';
import LoadingIndicator from 'components/UILoading/UILoadingIndicator';
import UILogo from 'components/UILogo/UILogo';
import {Job} from 'interfaces/Jobs/Jobs.interface';
import React, {useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {accountState} from 'states/Account/accountState';
import {useParams} from 'react-router';
import {jobsService} from 'api/jobs/jobs.service';
import JobOfferEditForm from 'forms/Jobs/FormEditJobOffer';
import {refreshCounterState} from 'states/Common/CommonState';
import {UIDeleteButton} from 'components/UIButtons/UIDeleteButton';
import UIPopUpPhoto from 'components/UIImages/UIPopUpPhoto';
import {User} from 'interfaces/Users/user.interface';

const JobDetail = () => {
  const {jobId: jobId} = useParams<{jobId: string}>();
  const [isOwn, setIsOwn] = useState(false);
  const [job, setJob] = useState<Job>();
  const accountData = useRecoilValue<User | undefined>(accountState);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFavorite, setIsFavorite] = useState<boolean>(true);
  const [photos, setPhotos] = useState([]);
  const [isPopUpVisible, setIsPopUpVisible] = useState<boolean>(false);
  const router = useIonRouter();

  const [refreshCounter, setRefreshCounter] =
    useRecoilState(refreshCounterState);

  useEffect(() => {
    async function getJobDetails() {
      try {
        setIsLoading(true);
        const response = await jobsService.getDetails(+jobId);
        if (accountData) {
          setIsOwn(accountData!.id! == response.data.owner.id);
          const isFavourite = accountData.favourites!.jobs!.some(
            (dto: any) => dto.id == jobId,
          );
          setIsFavorite(isFavourite);
          setIsPopUpVisible(false);
        }
        setJob(response.data);
        setPhotos(response.data.photos);
      } catch {
      } finally {
        setIsLoading(false);
      }
    }
    getJobDetails();
  }, [refreshCounter]);

  const handleDelete = () => {
    jobsService.deleteJob(job!.id!).then(() => {
      setRefreshCounter(refreshCounter + 1);
      router.goBack();
    });
  };

  return (
    <IonPage>
      {isPopUpVisible && (
        <UIPopUpPhoto
          type='jobs'
          photoLink={photos}
          onClosePopUp={() => {
            setIsPopUpVisible(false);
          }}
        />
      )}
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
          {isOwn && (
            <IonButtons slot='end'>
              <UIDeleteButton onDelete={handleDelete} />
            </IonButtons>
          )}
        </IonToolbar>
        <UITopTextBar>Jobinserat</UITopTextBar>
      </IonHeader>
      <IonContent>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          job && (
            <div className='w-full h-full bg-white dark:bg-[#474747]'>
              <div className='pt-2 px-2 pb-5 space-y-5'>
                {!isOwn ? (
                  <JobDetails
                    defaultFavorite={isFavorite}
                    fields={job}
                    setIsPopUpVisible={setIsPopUpVisible}
                  />
                ) : (
                  <JobOfferEditForm />
                )}
                {!isOwn && (
                  <UIYellowButton
                    text='Jobanbieter kontaktieren'
                    onClick={() =>
                      router.push(
                        `/message/jobs/${job.id}/${
                          job.jobOffer ? job.jobOffer : job.name
                        }/${job.existedChat ? job.existedChat : 0}`,
                      )
                    }
                  />
                )}
              </div>
            </div>
          )
        )}
      </IonContent>
    </IonPage>
  );
};

export default JobDetail;
