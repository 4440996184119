// import UIRatingStars from 'components/UIRating/UIRatingStars';
import React, {useState} from 'react';
import Rating from 'react-rating';
import {star} from 'ionicons/icons';
import {IonIcon} from '@ionic/react';
import {instrumentsService} from 'api/instruments/instruments.service';

interface UIRatePopUpProps {
  closePopUp: any;
  id?: any;
}

const UIRatePopUp: React.FC<UIRatePopUpProps> = ({closePopUp, id}) => {
  const [userRate, setUserRate] = useState(Number);

  const rate = {
    rating: userRate,
  };
  const onAddRate = () => {
    instrumentsService.rateInstrument(rate, id);
    closePopUp(true);
  };
  return (
    <div>
      <div className='h-full w-screen fixed left-0 top-0 bg-black opacity-70'/>
      <div
        className='bg-white w-3/4 md:w-1/2 z-20 lg:w-2/5 fixed top-1/2
       left-1/2 text-white transform -translate-x-1/2 -translate-y-1/2 rounded-lg pt-1 px-2 pb-4
        flex flex-col items-center border-2 border-orange-main'
      >
        <div className='flex flex-col w-full'>
          <div className='flex justify-end'>
            <button
              className='text-2xl -mt-1 text-orange-main'
              onClick={closePopUp}
            >
              x
            </button>
          </div>
          <div className='flex w-full text-orange-main justify-center pt-2 space-x-6'>
            <div className=''>Ihre Bewertung:</div>
            <Rating
            start={0}
            stop={5}
            fractions={1}
            emptySymbol={<IonIcon icon={star} className='text-gray-400 text-xl'/>}
            fullSymbol={<IonIcon icon={star} className='text-orange-main text-xl'/>}
            initialRating={userRate}
            onClick={(value) => setUserRate(value)}
            />
          </div>
        </div>
        <button className='rounded-md mt-3 px-6 py-2 bg-orange-main' onClick={onAddRate}>
        Bewertung {userRate}
        </button>
      </div>
    </div>
  );
};

export default UIRatePopUp;
