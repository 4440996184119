import {BFormFieldType} from 'forms/Common/BForm/BFormField';

export const formRecoveryCode: BFormFieldType[] = [
  {
    fieldName: 'token',
    fieldType: 'number',
    placeHolder: 'Code',
    validation: {
      required: true,
    },
  },
];
