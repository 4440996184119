import React from 'react';
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
} from 'react-google-maps';
import localization from 'assets/gold localization.svg';

type Props = {
  geolocation: {lat: number, lng: number}
}

const BMiniMap: React.FC<Props> = ({geolocation}) => {
  const Map = ({children}: {children: any}) => {
    return (
      <GoogleMap
        defaultOptions={{
          disableDefaultUI: true,
        }}
        defaultZoom={10}
        defaultCenter={geolocation}
      >
        {children}
      </GoogleMap>
    );
  };

  const WrappedMap = withScriptjs(withGoogleMap(Map));

  return (
    <WrappedMap
    containerElement={<div className='h-64' />}
    mapElement={
      <div className='h-full border-2  border-yellow-accent mt-2 rounded-lg' />
    }
    // eslint-disable-next-line max-len
    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawind,places&key=AIzaSyDO-5cVZigsb3Qc_FTuQHMhCb7GaIzzmk8`}
    loadingElement={<div className='h-full' />}
  >
    {window.google && <Marker
      position={{
        lat: geolocation.lat,
        lng: geolocation.lng,
      }}
      icon={{
        url: localization,
        scaledSize: new window.google.maps.Size(32, 32),
      }}
    />}
  </WrappedMap>
  );
};

export default BMiniMap;
