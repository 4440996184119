import React, {useEffect} from 'react';
import {Instrument} from 'interfaces/Instruments/Instrument.interface';
import {Job} from 'interfaces/Jobs/Jobs.interface';
import {Product} from 'interfaces/Products/product.interface';
import {Service} from 'interfaces/Services/service.interface';
import InstrumentsList from 'pages/Instruments/InstrumentsList/InstrumentsList';
import ProductsListBuyPage from 'pages/Products/ProductsList/ProductsList';
import {useHistory} from 'react-router';
import {useSetRecoilState} from 'recoil';
import {refreshCounterState} from 'states/Common/CommonState';
import {ServicesList} from 'pages/Services/ServicesList/ServiceList';
import {JobsList} from 'pages/Jobs/JobsList/JobsList';

type Props = {
  itemsList: {
    Instruments: Instrument[];
    Products: Product[];
    Services: Service[];
    Jobs: Job[];
  };
  setLocation: Function;
};

const SameLocationItemsList: React.FC<Props> = ({itemsList, setLocation}) => {
  const setRefreshCount = useSetRecoilState(refreshCounterState);
  const router = useHistory();
  useEffect(() => {
    if (itemsList.Instruments.length > 0) setLocation(itemsList.Instruments[0].location);
    else if (itemsList.Products.length > 0) setLocation(itemsList.Products[0].location);
    else if (itemsList.Services.length > 0) setLocation(itemsList.Services[0].location!);
    else if (itemsList.Jobs.length > 0) setLocation(itemsList.Jobs[0].location!);
  }, [itemsList]);

  return (
          <div className='absolute z-20 bg-white dark:bg-[#393939] w-full h-full overflow-auto'>
          <InstrumentsList
            instruments={itemsList.Instruments}
            imageType='instruments'
            onInstrumentClick={(instrument: Instrument) => {
              router.push(`/instrument/${instrument.category.name}/
            ${instrument.subcategory.name}/${instrument.id}`);
              setRefreshCount((refreshCounter) => refreshCounter + 1);
            }}
          />
          <ProductsListBuyPage
            products={itemsList.Products}
            type='products'
            onProductClick={(product: Product) => {
              router.push(`/product/${product.category.name}/
              ${product.subcategory.name}/${product.id}`);
              setRefreshCount((refreshCounter) => refreshCounter + 1);
            }}
          />
          <ServicesList
            services={itemsList.Services}
            onServiceClick={(service: Service) => {
              router.push(`/service/${service.id}`);
              setRefreshCount((refreshCounter) => refreshCounter + 1);
            }}
            isOwnService={false}
          />
          <JobsList
            jobs={itemsList.Jobs}
            onJobClick={(job: Job) => {
              router.push(`/job/${job.id}`);
              setRefreshCount((refreshCounter) => refreshCounter + 1);
            }}
            isOwnJob={false}
          />
        </div>
  );
};

export default SameLocationItemsList;
