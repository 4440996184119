import client from 'api/common/client';
import {Service} from 'interfaces/Services/service.interface';
import {createFormData} from 'utils/formDataHelper';

const getAll = async () => {
  const response = await client.get('/services/');
  return response;
};

const addService = async (data: Service) => {
  const formData = createFormData(data);
  const response = await client.post('services/', formData);
  return response;
};

const deleteService = async (id: any) => {
  const response = await client.delete('services/' + id);
  return response;
};

const updateService = async (data: any, offerId: any) => {
  const formData = createFormData(data);
  const response = client.patch(`services/${offerId}/`, formData);
  return response;
};

const search = async (q: string) => {
  const response = await client.get(`/services?q=${q}`);
  return response.data;
};

const getDetails = async (offerId: number) => {
  const response = await client.get(`/services/${offerId}`);
  return response;
};

const addFavoriteService = async (id: number) => {
  const response = await client.post(`/favourites/service/${id}`);
  return response;
};

const deleteFavoriteService = async (id: number) => {
  const response = await client.delete(`/favourites/service/${id}`);
  return response;
};

const getSortedOffers = async (sortedBy: string) => {
  const response = await client.get(`/services?orderBy=${sortedBy}`);
  return response;
};

const getAvailablePromotions = async (id: number) => {
  const response = await client.get(`/payments/promotions/`);
  return response;
};

export const servicesService = {
  getAll,
  addService,
  deleteService,
  search,
  getDetails,
  addFavoriteService,
  deleteFavoriteService,
  getSortedOffers,
  updateService,
  getAvailablePromotions,
};
