import React from 'react';
import {Control, Controller} from 'react-hook-form';
import AddPhotoInner from './UIAddPhotoInner';

interface AddPhotoProps {
  control: Control;
  name: string;
}
const AddPhoto: React.FC<AddPhotoProps> = ({control, name}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({field: {onChange, value}}) => (
        <AddPhotoInner onChange={onChange} value={value} />
      )}
    />
  );
};

export default AddPhoto;
