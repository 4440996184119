import React, {useState} from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonToolbar,
  useIonRouter,
  useIonToast,
} from '@ionic/react';
import UIButtonBack from 'components/UIButtons/UIButtonBack';
import UILogo from 'components/UILogo/UILogo';
import {OrangeLine} from 'components/UIContainer/UIOrangeLine';
import UIYellowButton from 'components/UIButtons/UIYellowButton';
import {useParams} from 'react-router';
import {reportsService} from 'api/reports/reports.service';

export default function ReportPage() {
  const {reportedElement, reportedElementId}:any = useParams();
  const router = useIonRouter();
  const [reportType, setReportType] = useState();
  const [message, setMessage] = useState('');
  const [present] = useIonToast();

  const handleSubmit = async () => {
    try {
      await reportsService.send(
        {reportedElement, reportedElementId: +reportedElementId, reportType, message});
      present('Antrag gesendet', 1000);
    } catch (error) {
      present('Error!', 1000);
    } finally {
      router.goBack();
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <UIButtonBack />
          </IonButtons>
          <UILogo />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className='mx-6'>
          <b>Probleme melden</b>
        </div>
        <div
          className='rounded-xl border border-t-8
          border-yellow-accent mx-6 my-3 bg-[#FFFCF8] drop-shadow-md'
        >
          <div className='flex flex-col p-4'>
            <IonSelect
                      okText='Weiter'
                      cancelText='Abbrechen' className='bg-white'
              onIonChange={(e) => setReportType(e.detail.value)}
              placeholder='Grund' interface='popover'>
              <IonSelectOption value='1'>Anstößig/ Unserios</IonSelectOption>
              <IonSelectOption value='2'>Falsche Kategorie</IonSelectOption>
              <IonSelectOption value='3'>Unerwünschte Werbung/Spam</IonSelectOption>
            </IonSelect>
            <OrangeLine name=''/>
            <div className='mt-2'>Beschreibe das Problem</div>
            <IonTextarea
              rows={4}
              className='border-2 rounded-md border-grey-600 h-[120px] bg-white'
              onIonChange={(e) => setMessage(e.detail!.value!)}
            />
          </div>
        </div>
        {reportType && (
          <div className='mb-5 absolute bottom-2 w-full'>
            <UIYellowButton text='Senden' onClick={handleSubmit}/>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
}
