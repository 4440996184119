import React from 'react';
import {Link} from 'react-router-dom';
import {IonContent, IonPage} from '@ionic/react';

import AppSignInBackground from 'pages/Account/AccountPleaseSignIn/AccountSignInBackground';

import UIYellowButton from 'components/UIButtons/UIYellowButton';

import logo from 'assets/logo.png';
import background from 'assets/background.png';

const AccountPleaseSignInPage = () => {
  return (
    <IonPage>
      <IonContent>
        <div className='lg:flex lg:justify-center bg-gray-200 dark:bg-[#474747]'>
          <div className='h-screen lg:w-1/3'>
            <img
              alt='background'
              src={background}
              className='absolute w-screen h-screen z-0 blur-sm lg:hidden'
            />
            <div className='flex justify-center h-2/6 flex-col dark:bg-[#474747]'>
              <div className='h-1/6'></div>
              <div className='flex justify-center'>
                <img alt='logo' src={logo} className='w-52 h-24 z-20' />
              </div>
            </div>
            <AppSignInBackground>
              <div className='h-full flex  flex-col space-y-2 p-2 justify-center items-center
              dark:bg-[#393939]'>
                <h5 className='text-center dark:text-white'>
                  Diese Funktion ist nach dem Einloggen verfügbar
                </h5>
                <div className='w-2/3 text-center dark:bg-[#393939]'>
                  <Link to='/login'>
                    <UIYellowButton text='Anmeldung' />
                  </Link>
                  <p className='mt-3 dark:text-white'>oder</p>
                  <Link to='/register'>
                    <UIYellowButton text='Registrieren' />
                  </Link>
                </div>
              </div>
            </AppSignInBackground>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default AccountPleaseSignInPage;
