import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {IonIcon} from '@ionic/react';
import {Instrument} from 'interfaces/Instruments/Instrument.interface';
import {
  // call,
  flag,
  homeOutline,
  starOutline,
  // location,
  // mailOutline,
} from 'ionicons/icons';
import {useRecoilValue} from 'recoil';
import {accountState} from 'states/Account/accountState';
// import RatingStars from 'pages/Common/RatingStars/RatingStars';
import FavouritesButton from 'pages/Common/Favourites/FavouritesButton';
import {productsService} from 'api/products/products.service';
import {UIClickImage} from 'components/UIImages/UIClickImage';
import {User} from 'interfaces/Users/user.interface';
import {Link} from 'react-router-dom';
import UIButtonShare from 'components/UIButtons/UIButtonShare';
import {useIonRouter} from '@ionic/react';
import BMiniMap from 'forms/Common/places/BMiniMap';

interface Props {
  fields: Instrument;
  isDefaultFavorite: boolean;
  setIsPopUpVisible: Dispatch<SetStateAction<boolean>>;
}

export const DetailsProducts: React.FC<Props> = ({
  fields,
  isDefaultFavorite,
  setIsPopUpVisible,
}) => {
  const router = useIonRouter();
  const accountData = useRecoilValue<User | undefined>(accountState);
  const [isFavorite, setIsFavorite] = useState(isDefaultFavorite);

  useEffect(() => setIsFavorite(isDefaultFavorite), [isDefaultFavorite]);

  const favHandler = async (id: number) => {
    if (!isFavorite) {
      await productsService.addFavoriteProducts(id);
      return;
    }
    await productsService.deleteFavoriteProducts(id);
  };
  return (
    <div
      className='rounded-lg border border-yellow-accent mx-4 mt-3
      bg-yellow-slight drop-shadow-md dark:bg-[#393939]'
    >
      <div className='h-9 bg-yellow-accent rounded-t-lg w-full flex justify-between
      dark:bg-[#F0A249]'>
        <div className='flex'>
          {/* <IonIcon icon={location} className='h-6 w-6 mt-1.5 ml-3 text-white' />
          <p className='text-sm mt-2'></p> */}
        </div>
      </div>
      <div className='flex mb-3 font-sans ml-3'>
        <div className='flex mx-1 space-x-2 flex-col'>
          <div className='flex-col w-28'>
            <UIClickImage
              customClassName='mt-2'
              type='products'
              photoLink={fields.photos && fields.photos[0]}
              setIsPopUpVisible={setIsPopUpVisible}
            />
          </div>
          <div className='flex space-x-1'>
            {fields.photos && fields.photos.map(
              (image: any, index: number) =>
                index !== 0 && (
                  <UIClickImage
                    key={index}
                    customClassName='h-8 w-8 my-3 rounded-md z-10'
                    type='products'
                    photoLink={image}
                    setIsPopUpVisible={setIsPopUpVisible}
                  />
                )
            )}
          </div>
        </div>
        <div className='pl-2 w-full dark:text-white'>
          <p className='text-sm mt-2'>
            Name: <p>{fields.owner.firstName + ' ' + fields.owner.lastName}</p>
          </p>
          <p className='text-sm mt-2'>
            Name des Werkzeuges:
            <p>{fields.productName}</p>
          </p>
          <p className='text-sm mt-2'>
            Standort:<p>{fields.location}</p>
          </p>
          <p className='text-sm mt-2'>
            Bezahlung:<p>{fields.price} Euro</p>
          </p>

          {/* <div className='float-left'>
            <RatingStars />
          </div>
          <p className='text-xs float-left ml-1.5'>2 Bewertungen</p> */}
          <div className='flex justify-end pr-2'>
            <div className='pt-9 text-black space-x-2 flex'>
              <Link to={`/report/product/${fields.id}`}>
                {' '}
                <IonIcon
                  icon={flag}
                  className='mt-1 h-6 w-6 '
                />
              </Link>
              {fields && accountData && accountData.id !== fields!.owner!.id ? (
                <FavouritesButton
                  favHandler={favHandler}
                  fields={fields}
                  isFavorite={isFavorite}
                  setIsFavorite={setIsFavorite}
                />
              ) : (
                <IonIcon
                  icon={starOutline}
                  className='mt-1 text-yellow-400 h-6 w-6'
                  onClick={() => {
                    router.push('/sign-in');
                  }}
                />
              )}
              <UIButtonShare />
            </div>
          </div>
        </div>
      </div>
      <p className='text-yellow-accent float-left text-sm mr-1.5 ml-3 s'>Kontakt</p>
      <hr className='bg-yellow-accent mr-3 mb-4 mt-7' />
      <div className='flex text-gray-500 ml-4 mr-8 flex-col'>
        {/* <div className='flex'>
          <IonIcon icon={call} className='mt-0.5' />
          <p className='text-sm ml-2 '>
            {accountData &&
              (fields.phoneNumber)}
          </p>
        </div> */}
        <div className='flex mt-3 dark:text-white'>
          <IonIcon
            icon={homeOutline}
            className='h-5 w-5'
          />
          <p className='text-sm ml-2'>{fields.location}</p>
        </div>
        {/* <div className='flex mt-3'>
        <IonIcon icon={mailOutline} className='h-5 w-5' />
        <p className='text-sm ml-2'>{fields.email}</p>
      </div> */}
      </div>
      <div className='mx-3'>
        <BMiniMap geolocation={{lat: +fields.lat, lng: +fields.lng}} />
      </div>
      <p className='text-yellow-accent float-left text-sm mt-3 mr-1.5 ml-3'>Beschreibung</p>
      <hr className='bg-yellow-accent mr-3 mt-7 mb-4' />
      <div className='text-sm text-gray-400 ml-4 mb-5 dark:text-white'>{fields.description}</div>
    </div>
  );
};
