import client from '../common/client';
import {RegisterRequest} from 'interfaces/Account/RegisterRequest.interface';
import {CheckCodeRequest} from 'interfaces/Account/CheckCodeRequest.interface';
import {NewPasswordRequest} from 'interfaces/Account/NewPasswordRequest.interface';

type CheckEmailAvailabilityResponse = {
  available: boolean;
}

const checkEmailAvailability = async (email: string): Promise<boolean> => {
  const response = await client.
      post<CheckEmailAvailabilityResponse>('/auth/check-email-availability', {email});
  return response.data.available;
};

const register = async (data: RegisterRequest) => {
  return client.post('/auth/register', data);
};

const activateAccount = (email: string, activationCode: string) => {
  return client.post('/auth/activate-account', {email, activationCode});
};

const forgetPasswordMail = async (email: string) => {
  return client.post('/auth/initiate-password-reset', email);
};

const checkCodeValidation = async (data: CheckCodeRequest) => {
  return client.post('/auth/test-password-token', data);
};

const newPasswordValidation = async (data: NewPasswordRequest) => {
  return client.post('/auth/reset-password', data);
};

const getCurrentUser = () => {
  const response = client.get('/users/current-user/');
  return response;
};
const deleteCurrentUser = () => {
  const response = client.delete('/users/current-user/');
  return response;
};

export const accountService = {
  checkEmailAvailability,
  register,
  activateAccount,
  forgetPasswordMail,
  checkCodeValidation,
  newPasswordValidation,
  getCurrentUser,
  deleteCurrentUser,
};
