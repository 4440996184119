import React, {useState} from 'react';
import {useHistory} from 'react-router';
import {Link} from 'react-router-dom';
import {IonProgressBar, IonPage} from '@ionic/react';
import {accountService} from 'api/account/account.service';
import {formRegister} from 'forms/Account/FormRegister/FormRegister';
import BForm from 'forms/Common/BForm/BForm';
import AppLoginBackground from 'pages/Account/AccountLogin/AccountLoginBackground';
import logo from 'assets/logo.png';
import background from 'assets/background.png';

const AccountRegisterPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any[]>([]);

  const history = useHistory();

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    setErrors([]);
    const femail = data.email.trim();
    const available = await accountService.checkEmailAvailability(femail);
    setIsLoading(false);
    if (available) {
      history.push('/registerSecondStage', {
        email: femail,
        password: data.password,
        name: data.name,
      });
      return;
    }
    setErrors([{name: 'email', message: 'E-Mail-Adresse ist bereits vergeben'}]);
    throw new Error('E-Mail-Adresse ist bereits vergeben');
  };

  return (
    <IonPage className='bg-gray-200'>
      <div className='lg:flex lg:justify-center dark:bg-[#474747] '>
        <div className='h-screen bg-gray-200 lg:w-1/3 dark:bg-[#474747] '>
          <img
            alt='Background'
            src={background}
            className='absolute w-screen h-screen z-0 blur-sm lg:hidden'
          />
          <div className='flex justify-center h-1/4 flex-col'>
            <div className='flex justify-center'>
              <img
                alt='logo'
                src={logo}
                className='w-44 h-20 z-20'
              />
            </div>
          </div>
          <AppLoginBackground>
            <div className='flex justify-center flex-col'>
              {isLoading && <p>Loading...</p>}
              <b className='text-xl mt-2 mx-3 dark:text-white'>Registrierung</b>
              <div className='mx-3 mt-2'>
                <IonProgressBar
                  color='primary'
                  value={1 / 3}
                />
              </div>
              <div className='mx-3'>
                <BForm
                  btnMargin={10}
                  btnText='Weiter'
                  fields={formRegister}
                  preloadedData={{}}
                  submit={onSubmit}
                  formErrors={errors}
                />
                <hr className='bg-yellow-accent' />
                <p className='text-xs text-center mt-3
                dark:text-white'>Sie haben bereits ein Konto?</p>
                <Link
                  to='/login'
                  className='text-yellow-accent w-full'
                >
                  <div
                    className='bg-white mt-1 mb-4 text-sm
              text-center rounded-lg border border-yellow-accent p-2
              dark:text-white dark:bg-[#F0A249]'
                  >
                    <b>Zum Einloggen</b>
                  </div>
                </Link>
              </div>
            </div>
          </AppLoginBackground>
        </div>
      </div>
    </IonPage>
  );
};

export default AccountRegisterPage;
