import client from 'api/common/client';

const getAllPosts = async () => {
  const response = await client.get(`/posts/`);
  return response;
};

const addPost = async (data: any) => {
  const response = await client.post(`/posts/`, data);
  return response;
};

const addPostComment =async (id:string, data:any) => {
  const response = await client.post(`/posts/${id}/comments`, data);
  return response;
};

const getPost = async (id: string) => {
  const response = await client.get(`/posts/${id}`);
  return response;
};

export const postsService = {
  getPost,
  getAllPosts,
  addPost,
  addPostComment,
};
