import React from 'react';
import {Instrument} from 'interfaces/Instruments/Instrument.interface';
import {Product} from 'interfaces/Products/product.interface';
import {UIImage} from 'components/UIImages/UIImage';
import {IonIcon} from '@ionic/react';
import {location, logoEuro} from 'ionicons/icons';
import greenCircle from 'assets/temp/greenCircle.png';
import redCircle from 'assets/temp/redCircle.png';

interface Props {
  isOwn?: boolean;
  instrument?: Instrument;
  product?: Product;
  onItemClick: (item: Instrument | Product) => void;
  type?: string;
}

const UIListItem: React.FC<Props> = ({
  isOwn,
  instrument,
  product,
  onItemClick,
  type,
}) => {
  const item = instrument ? instrument! : product!;

  return (
    <div
      key={item.id}
      className='w-[90%] md:w-2/3 max-w-screen-md mx-auto'
      onClick={() => onItemClick(item)}
    >
      <div
        className={`${
          item.promoted
            ? 'shadow-custom shadow-orange-promoted border rounded-3xl border-orange-promoted'
            : 'border rounded-3xl border-yellow-accent '
        } bg-white dark:bg-[#393939] drop-shadow-md`}
      >
        <div>
          <UIImage
            photoLink={item.photos.length > 0 && item.photos[0].photo}
            customClassName='h-20 w-full rounded-t-3xl'
            type={type}
          />
          {instrument && (
            <div className='flex justify-end'>
              {item.availability ? (
                <img
                  alt='image'
                  src={greenCircle}
                  className='h-4 mt-2 mr-3 absolute top-0'
                />
              ) : (
                <img
                  alt='image'
                  src={redCircle}
                  className='h-4 mt-2 mr-3 absolute top-10'
                />
              )}
            </div>
          )}
          <div className='flex justify-end mr-3'>
            <div className='bg-yellow-accent text-white absolute p-2 top-16 rounded-2xl flex'>
              <IonIcon icon={logoEuro} className='text-sm' />
              <p className='text-xs'>
                {instrument
                  ? instrument.pricePerDay + '/ pro Tag'
                  : product?.price}
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className='mt-2'>
            <p className='text-gray-600 text-md font-medium ml-5 dark:text-white'>
              {instrument ? instrument.instrumentName : product?.productName}
            </p>
            <p className='text-gray-600 text-sm ml-7 dark:text-white'>
              {instrument ? instrument.name : product?.name}
            </p>
            <div className='flex justify-between'>
              <p className='text-gray-600 text-[12px] ml-7 mb-4 dark:text-gray-300'>
                {item.location}
              </p>
              {!isOwn && instrument && instrument.distance && (
                <div className='flex'>
                  <IonIcon
                    icon={location}
                    className='h-4 w-4 ml-3 text-white'
                  />
                  <div className='text-xs text-white mr-3'>
                    {instrument.distance.toFixed() + ' '}
                    km
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UIListItem;
